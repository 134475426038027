import {
    IFinancingRequestAcceptedStatusWithDetails,
    IInvoiceDetailsPaymentStatus,
    PaymentTypeCommingLing,
    PaymentTypeCommingLingRecup
} from "./InvoiceDetailsQuery";
import IInvoiceStatus from "../InvoiceStatus/IInvoiceStatus";
import { FinancingRequestedMessage, FinancingRequestRejectedMessage } from "../InvoiceStatus/InvoiceStatusMessages";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";
import VerticalStepperElement from "../../utils/VerticalStepperElement";

interface IActivityLog {
    description: string;
    date: Date;
    preferenceForLast?: boolean; // If set, put at the end in case of equality on date
}

const invoiceActivitiesSorted = (
    creationDateTime: string,
    paymentStatus: IInvoiceDetailsPaymentStatus,
    invoiceStatus: IInvoiceStatus<IFinancingRequestAcceptedStatusWithDetails> | null
): IActivityLog[] => {
    const activities: IActivityLog[] = [];

    activities.push({
        description: 'Facture ajoutée',
        date: serverDateDeserialization(creationDateTime)
    });

    paymentStatus.payments
        .forEach((p) =>
            activities.push({
                description: p.base.type === PaymentTypeCommingLing
                    ? `Déclaration de paiement direct de ${formatAmount(p.amount)}`
                    : p.base.type === PaymentTypeCommingLingRecup
                        ? `Prélèvement d'un montant de ${formatAmount(p.amount)} suite à une déclaration de paiement direct`
                        : p.isComplete
                            ? `Facture intégralement payée par votre client`
                            : `Facture partiellement payée par le client d'un montant de ${formatAmount(p.amount)}`,
                date: serverDateDeserialization(p.dateTime)
            })
        );

    if (!!invoiceStatus?.financingRequest) {
        activities.push({
            description: FinancingRequestedMessage,
            date: serverDateDeserialization(invoiceStatus.financingRequest.dateTime)
        });
        const financingRequest = invoiceStatus!.financingRequest;
        if (!!financingRequest.accepted) {
            const acceptedFinancingRequest = financingRequest.accepted;

            activities.push({
                description: 'Demande de financement acceptée',
                date: serverDateDeserialization(acceptedFinancingRequest.dateTime)
            });

            activities.push({
                description: `Financement de ${formatAmount(acceptedFinancingRequest.amount)} versé`,
                date: serverDateDeserialization(acceptedFinancingRequest.dateTime)
            });

            if (!!acceptedFinancingRequest.settledDateTime) {
                activities.push({
                    description: 'Dossier cloturé',
                    date: serverDateDeserialization(acceptedFinancingRequest.settledDateTime),
                    preferenceForLast: true
                });
            }
        } else {
            if (!!financingRequest.rejectedDate) {
                activities.push({
                    description: FinancingRequestRejectedMessage,
                    date: serverDateDeserialization(financingRequest.rejectedDate!)
                });
            }
            else {
                activities.push({
                    description: 'Vérifications en cours',
                    date: new Date()
                });
            }
        }
    }
    // else {
    //     if (!!invoiceStatus!.eligibility.notEligibleReason) {
    //         activities.push({
    //             description: `Facture non éligible : ${invoiceStatus!.eligibility.notEligibleReason}` ,
    //             date: serverDateDeserialization(creationDateTime)
    //         });
    //     }
    // }

    return activities.sort((a1, a2) => {
        const dateDiff = a2.date.getTime() - a1.date.getTime();
        if (dateDiff === 0) {
            // If equal, check preferenceForLast
            return (a2.preferenceForLast ? 1 : 0) - (a1.preferenceForLast ? 1 : 0);
        }
        return dateDiff;
    });
}

const InvoiceActivitiesSection = (
    {
        creationDateTime,
        invoiceStatus,
        paymentStatus
    } : {
        creationDateTime: string;
        invoiceStatus: IInvoiceStatus<IFinancingRequestAcceptedStatusWithDetails> | null;
        paymentStatus: IInvoiceDetailsPaymentStatus,
    }
) => {
    const invoiceActivities = invoiceActivitiesSorted(creationDateTime, paymentStatus, invoiceStatus);

    return (
        <ul>
            { invoiceActivities
                .map((activity, index) =>
                    <VerticalStepperElement key={`activity-${index}`}
                                            activityDate={activity.date}
                                            isLast={index === invoiceActivities.length - 1}
                                            lineOk={false}
                                            message={activity.description}
                                            status={index === 0} />

                )
            }
        </ul>
    );
}

export default InvoiceActivitiesSection;
