import './sidebarmenu.scss';

export interface ISideBarMenuItem {
    isActive: boolean;
    icon: any | null;
    label: string;
    action: () => void;
    visibleMobile: boolean;
}

const SideBarMenu = (
    {
        id,
        className,
        items
    }: {
        id?: string;
        className?: string;
        items: ISideBarMenuItem[];
    }
) =>
    <div id={id} className={`p-sbm-menu${!!className ? ` ${className}` : ''}`}>
        { items.map((item, itemIndex) => (
            <div key={`p-button-${itemIndex}`}
                 className={`p-button-wrapper p-padding-5${
                    item.isActive ? ' active' : ''}${item.visibleMobile ? '' : ' is-hidden-touch'}`}
                 onClick={item.action}
            >
                { !!item.icon
                    ? <div className='p-button-animated level'>
                        <div className='p-icon p-sbpm-icon'>
                            { item.icon }
                        </div>
                        <div className='is-uppercase p-margin-left-5 p-size-62'>
                            { item.label }
                        </div>
                    </div>
                    : <div className='is-uppercase p-size-62'>
                        { item.label }
                    </div>
                }
            </div>
        ))}
    </div>;

export default SideBarMenu;
