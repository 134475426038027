import {createBrowserRouter, Navigate, Outlet, RouteObject, useOutletContext} from "react-router-dom";
import MsalProviderWrapper from "./MsalProviderWrapper";
import GlobalClickWrapper from "./GlobalClickWrapper";
import TopBarWrapper from "./TopBarWrapper";
import ITopBarProps from "./TopBar/ITopBarProps";
import AuthUserWrapper from "./Auth/AuthUserWrapper";
import {
    DashboardDefaultRoute,
    DashboardInvoicesDefaultRoute,
    dashboardRoute,
    DashboardRoutePrefix
} from "./Pages/Dashboard/dashboardUrlsConsts";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import InvoiceDetailsPage from "./Pages/InvoiceDetails/InvoiceDetailsPage";
import InvoiceAddPage from "./Pages/InvoiceAdd/InvoiceAddPage";
import IAccessTokens from "./Auth/IAccessTokens";
import {ReactElement, useEffect} from "react";
import Loader from "factor-lib/Loader";
import {dashboardSections} from "./Pages/Dashboard/IDashboardSection";
import ICustomerType from "./ICustomerType";
import ErrorHandler from "./ErrorHandler";
import {AccountInfo} from "@azure/msal-browser";
import UseNavigateHookHack from "factor-lib/navigationHack/UseNavigateHookHack";
import PricingCheck from "./Pages/utils/PricingCheck";
import {InvoiceDetailsUrlMapping} from "./Pages/InvoiceDetails/invoiceDetailsUrl";
import {invoiceAddUrl} from "./Pages/InvoiceAdd/invoiceAddUrl";

interface IRouteDefinition {
    path: string;
    isAccessible: (customerO: ICustomerType | null) => boolean;
    component: (accessTokensO: IAccessTokens | null) => ReactElement;
    children?: IRouteDefinition[];
}

interface IRootRouteDefinition extends IRouteDefinition {
    titleO: string | null;
    goBackActionO: (() => void) | null;
}

const GoBackIfPossible: (() => void) | null = window.history.length > 0
    ? () => window.history.back()
    : null;

const rootPages: IRootRouteDefinition[] = [
    {
        path: DashboardRoutePrefix,
        titleO: null,
        goBackActionO: null,
        isAccessible: () => true,
        component: (accessTokenO: IAccessTokens | null) =>
            <DashboardPage accessTokenO={accessTokenO} />,
        children: dashboardSections.map((ds) => ({
            path: dashboardRoute(ds.routePageParam),
            component: ds.componentProvider,
            isAccessible: ds.isAccessible
            // no children for now
        }))
        // TODO : default redirect to 'invoices' ?
    },
    {
        path: InvoiceDetailsUrlMapping,
        titleO: 'Détail de la facture',
        goBackActionO: () => {
            if (window.history.length > 0) {
                window.history.back();
            } else {
                window.location.href = DashboardInvoicesDefaultRoute;
            }
        },
        isAccessible: () => true,
        component: (accessTokenO) =>
            <InvoiceDetailsPage accessTokenO={accessTokenO}/>,
    },
    {
        path: invoiceAddUrl,
        titleO: 'Ajout de nouvelle facture',
        goBackActionO: GoBackIfPossible,
        isAccessible: () => true,
        component: (accessTokenO) =>
            <InvoiceAddPage className='p-default-flex-item' accessTokenO={accessTokenO}/>
    }
];

const WithTopsBarPropsSetter = (
    {
        setTopBar,
        titleO,
        goBackActionO,
        children
    }: {
        setTopBar: (titleO: string | null, goBackActionO: (() => void) | null) => void;
        titleO: string | null;
        goBackActionO: (() => void) | null;
        children: ReactElement;
    }
) => {
    useEffect(() => {
        setTopBar(titleO, goBackActionO);
    }, [titleO, goBackActionO, setTopBar]);
    return children;
}

export const WithRootContext = (
    {
        child
    }: {
        child: (setTopBar: (newTopBarProps: ITopBarProps) => void, accessTokenO: IAccessTokens | null) => ReactElement | null;
    }
) => {
    const {accessTokenO, setTopBar} = useOutletContext<{accessTokenO: IAccessTokens | null, setTopBar: (newTopBarProps: ITopBarProps) => void}>();
    return child(setTopBar, accessTokenO);
}

export const WithAccessTokenOContext = (
    {
        child
    }: {
        child: (accessTokensO: IAccessTokens | null) => ReactElement | null;
    }
) => {
    const {accessTokenO} = useOutletContext<{accessTokenO: IAccessTokens | null}>();
    return child(accessTokenO);
}

interface IRootPage {
    path: string;
    component: (setTopBar: (newTopBarProps: ITopBarProps) => void, accessTokenO: IAccessTokens | null) => ReactElement | null;
    children?: IRouteDefinition[];
}

const RootPagesWithRoot: IRootPage[] = [
    {
        path: '/',
        component: (_: (newTopBarProps: ITopBarProps) => void, accessTokenO: IAccessTokens | null) =>
            // Necessary to avoid infinite redirects on login
            !!accessTokenO
                ? <Navigate to={DashboardDefaultRoute} replace={true} />
                : <Loader />
    },
    ...rootPages.map((p) => ({
        path: p.path,
        component: (setTopBar: (newTopBarProps: ITopBarProps) => void, accessTokenO: IAccessTokens | null) =>
            p.isAccessible(accessTokenO?.customerType || null)
                ? <WithTopsBarPropsSetter setTopBar={(titleO: string | null, goBackActionO: (() => void) | null) => setTopBar({titleO, goBackActionO})}
                                          titleO={p.titleO}
                                          goBackActionO={p.goBackActionO}>
                    { p.component(accessTokenO) }
                </WithTopsBarPropsSetter>
                : null,
        children: p.children
    }))
];

const buildRoutesDefinitions = (children?: IRouteDefinition[]): RouteObject[] | undefined =>
    !!children
        ? children.map((c) => ({
            path: c.path,
            element:
                <WithAccessTokenOContext child={(accessTokensO: IAccessTokens | null) =>
                    c.isAccessible(accessTokensO?.customerType || null)
                        ? c.component(accessTokensO)
                        : null /* TODO */
                }/>,
            children: buildRoutesDefinitions(c.children)
        }))
        : undefined;

const router =
    createBrowserRouter([
        {
            path: '/',
            element:
                <UseNavigateHookHack>
                    <GlobalClickWrapper>
                        <TopBarWrapper child={(setTopBar: (newTopBarProps: ITopBarProps) => void, setAccount: (account: AccountInfo) => void) =>
                            <MsalProviderWrapper setAccount={setAccount}
                                                 child={(isMsalAuthenticationComplete: boolean) =>
                                                     isMsalAuthenticationComplete
                                                         ? <AuthUserWrapper child={(accessTokenO: IAccessTokens | null) =>
                                                             <PricingCheck accessTokenO={accessTokenO}>
                                                                 <Outlet context={{accessTokenO, setTopBar}} />
                                                             </PricingCheck>
                                                         }/>
                                                         : <Outlet context={{accessTokenO: null, setTopBar}} />
                                                 } />
                        } />
                    </GlobalClickWrapper>
                </UseNavigateHookHack>,
            children: RootPagesWithRoot
                .map<RouteObject>((d) => ({
                    path: d.path,
                    element:
                        <WithRootContext child={d.component}/>,
                    children: buildRoutesDefinitions(d.children)
                })),
            errorElement: <ErrorHandler/>
        }
]);

export default router;