import { QueryClient } from "@tanstack/react-query";
import { IInvoicesHeader } from "../Dashboard/Invoices/Header";
import { updateInvoicesHeaderAndFiltersCountQueries } from "../Dashboard/Invoices/updateInvoicesQuery";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { getIntCompanyIdentifierSirenO } from "factor-lib/Company/IIntCompanyIdentifier";
import { dateDiffDays, serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { IInvoicesFiltersCount } from "../Dashboard/Invoices/FiltersCount";
import { invoicesFilteredListQueryKey } from "../Dashboard/Invoices/InfiniteQueries";
import { EligibleComputation, NotEligibleComputation, PendingComputation } from "../Dashboard/Invoices/StatusFilters";
import {BuyersForAddInvoiceQueryKey, IInvoiceBuyerInfosInput} from "./InvoiceAddBuyerInfos";
import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import {
    AMOUNT_TAX_LABEL,
    AMOUNT_WO_TAX_LABEL,
    DUE_DATE_LABEL,
    IInvoiceBaseInfosInput,
    ISSUE_DATE_LABEL,
    NUMBER_LABEL
} from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import { isValidAmount } from "factor-lib/forms/Inputs/InputAmount";
import { isValidString } from "factor-lib/forms/Inputs/utils";
import { isValidEmail } from "factor-lib/forms/Inputs/emailUtils";
import { isValidPhone } from "factor-lib/forms/Inputs/phoneUtils";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";

export interface IBuyerContactsForAddInvoiceQueryResult {
    emails: string[];
    phones: string[];
}

export interface IInvoiceAddDSParams {
    buyerSelection: IBuyerCompanySelection;
    buyerEmails: string[];
    buyerPhones: string[];
    invoiceNumber: string;
    invoiceIssueDate: string;
    invoiceAmountWoTax: number;
    invoiceAmountTax: number;
    invoiceDueDate: string;
    invoiceFileId: string;
    additionalFileIds: string[];
}

export const AddInvoiceButtonId = 'addButtonId';
export const UploadFileInvoiceSectionId = 'invoiceFileUploadSection';

export interface INotEligibleReason {
    reason: string; // public
    isGlobalLimitReached: boolean;
}

export const buyerContactsForAddInvoiceQueryKey = (buyerSelection: IBuyerCompanySelection) =>
    ['company', 'selection', (buyerSelection.existingIdO ?? buyerSelection.newSirenO)!, 'buyer-contacts'];

export const addInvoiceUpdateQueriesAsync = (
    queryClient: QueryClient,
    params: IInvoiceAddDSParams,
    responseEligible: boolean | null // null -> pending
) => {
    updateInvoicesHeaderAndFiltersCountQueries(
        queryClient,
        (oldHeader: IInvoicesHeader) => ({
            ...oldHeader,
            totalEligible: responseEligible === true ? oldHeader.totalEligible + params.invoiceAmountWoTax + params.invoiceAmountTax : oldHeader.totalEligible,
            totalUpcoming: oldHeader.totalUpcoming + params.invoiceAmountWoTax + params.invoiceAmountTax,
        }),
        (oldFiltersCount: IInvoicesFiltersCount) => ({
            ...oldFiltersCount,
            eligible: responseEligible === true ? oldFiltersCount.eligible + 1 : oldFiltersCount.eligible,
            notEligible: responseEligible === false ? oldFiltersCount.notEligible + 1 : oldFiltersCount.notEligible,
            pending: responseEligible === null ? oldFiltersCount.pending + 1 : oldFiltersCount.pending
        })
    );

    const computation = responseEligible === true
        ? EligibleComputation
        : responseEligible === false
            ? NotEligibleComputation
            : PendingComputation;

    const buyerSelection: IBuyerCompanySelection = params.buyerSelection;

    const thisBuyerContactsForAddInvoiceQueryKey = buyerContactsForAddInvoiceQueryKey(buyerSelection);
    if (!!queryClient.getQueryData(thisBuyerContactsForAddInvoiceQueryKey)) {
        const { buyerEmails, buyerPhones } = params;

        queryClient.setQueryData<IBuyerContactsForAddInvoiceQueryResult>(
            thisBuyerContactsForAddInvoiceQueryKey,
            (old: IBuyerContactsForAddInvoiceQueryResult | undefined) => {
                const emailsSets = new Set<string>(old!.emails);
                const phonesSets = new Set<string>(old!.phones);
                return ({
                    ...old!,
                    buyerEmails: [
                        ...old!.emails,
                        ...buyerEmails.filter((buyerEmail) => !emailsSets.has(buyerEmail))
                    ],
                    buyerPhones: [
                        ...old!.phones,
                        ...buyerPhones.filter((buyerPhone) => !phonesSets.has(buyerPhone))
                    ]
                });
            }
        );
    }

    return Promise.all([
        // Due to sever-side ordering/filtering, cannot simply add the invoice
        queryClient.invalidateQueries(invoicesFilteredListQueryKey(computation)),
        queryClient.invalidateQueries(BuyersForAddInvoiceQueryKey)
    ]);
};

export const isSameEmail = (e1: string, e2: string): boolean =>
    e1/* this one is already valid */.toLowerCase() === e2.trim().toLowerCase();

const checkFieldValid = (
    missingFields: string[],
    invalidFields: string[],
    fieldInput: string,
    fieldLabel: string,
    fieldChecker: (input: string) => boolean
) => {
    if (!fieldInput) {
        missingFields.push(fieldLabel);
    } else if (!fieldChecker(fieldInput)) {
        invalidFields.push(fieldLabel);
    }
}

// Generic date utils ?
export const isValidPassedDate = (date: Date): boolean =>
    isValidPassedDate2(Date.now(), date);

// Not exact, but not important
const getNbDays = (date: Date): number =>
    date.getUTCDate() + date.getUTCMonth() * 31 + date.getUTCFullYear() * 366;

export const isValidPassedDate2 = (utcNow: number, date: Date): boolean =>
    // Max 14h : https://learn.microsoft.com/en-us/dotnet/api/system.timezoneinfo.baseutcoffset?view=net-7.0#system-timezoneinfo-baseutcoffset
    getNbDays(date) <= getNbDays(new Date(utcNow + 14 * 3600 * 1000 + 1 /* Security */));

export const getRejectionMessage = (
    sellerCompany: {
        id: string;
        identifier: IIntCompanyIdentifier;
    },
    sameSellerBuyerCompanyExceptionMessage: string,
    customerMinInvoiceAmount: number,
    sellerEmail: string,
    sameSellerBuyerEmailExceptionMessage: string,
    params: IInvoiceAddDSParams
): string | null => {

    const issueDate: Date = serverDateDeserialization(params.invoiceIssueDate);
    const dueDate: Date = serverDateDeserialization(params.invoiceDueDate);

    // TODO: remove when we add DatePicker with restricted range ?

    if (!isValidPassedDate(issueDate)) {
        return `La date d'émission doit être dans le passé.`;
    }

    if (dateDiffDays(issueDate, dueDate) < 0) {
        return `La date d'échéance doit être après la date d'émission.`;
    }

    // if (dateDiffDays(today, dueDate) < DUE_DATE_MARGIN_DELAY_DAYS) {
    //     const limitDate = addDays(today, DUE_DATE_MARGIN_DELAY_DAYS) ;
    //     return `La date d'échéance doit être après le ${userFormatDate(limitDate)} inclus.`;
    // }

    const amountTTC: number = params.invoiceAmountWoTax + params.invoiceAmountTax;
    if (amountTTC < customerMinInvoiceAmount) {
        return 'Non éligible, en dessous du montant minimum';
    }

    if ((sellerCompany.id === params.buyerSelection.existingIdO) || (
        getIntCompanyIdentifierSirenO(sellerCompany.identifier) === params.buyerSelection.newSirenO
    )) {
        return sameSellerBuyerCompanyExceptionMessage; // 'Désolé, vous ne pouvez pas financer de facture sur vous-même :)';
    }

    if (params.buyerEmails.some((buyerEmail) => isSameEmail(sellerEmail, buyerEmail))) {
        return sameSellerBuyerEmailExceptionMessage; // 'Désolé, vous ne pouvez pas utiliser votre propre email.';
    }

    return null;
};

export const getInvoiceValidOrNotAddParams = (
    invoiceFileId: string | null,
    invoiceAdditionalFilesIds: string[],
    invoiceBaseInfosInput: IInvoiceBaseInfosInput,
    invoiceBuyerInput: IInvoiceBuyerInfosInput,
    ofBuyerLabel: string,
    errorsCollector: string[] = []
): string[] => {

    let errors = errorsCollector;

    // const missingBuyer = !invoiceBuyerInput.companySelection;
    if (!invoiceBuyerInput.companySelection) {
        errors.push(`Vous n’avez pas sélectionné ${ofBuyerLabel}.`);
    }

    // const missingFile = !invoiceFileId;
    if (!invoiceFileId) {
        errors.push('Vous n’avez pas uploadé de fichier de facture.');
    }

    // const missingAdditionalFile = invoiceAdditionalFilesIds.length === 0;
    if (invoiceAdditionalFilesIds.length === 0) {
        errors.push('Vous n’avez pas uploadé de document justificatif.');
    }

    const missingFields: string[] = [];
    const invalidFields: string[] = [];

    // order is important (same order as inputs)

    invoiceBuyerInput.contacts.forEach((c) => {
        checkFieldValid(missingFields, invalidFields, c.email, 'Email du client', isValidEmail);
    });

    // At least the first phone
    checkFieldValid(missingFields, invalidFields, invoiceBuyerInput.contacts[0].phone, 'Un téléphone du client', isValidPhone);

    invoiceBuyerInput.contacts
        .map((c) => c.phone)
        .forEach((p, cIndex) => {
            if (cIndex > 0) {
                // Skip not set
                if (p.trim().length > 0) {
                    checkFieldValid(missingFields, invalidFields, p, 'Un téléphone du client', isValidPhone);
                }
            }
        });

    // Check for email duplicates
    const buyerEmailsSet = new Set<string>();

    // Check for phones duplicates
    const buyerPhonesSet = new Set<string>();

    for (const contact of invoiceBuyerInput.contacts) {
        const buyerEmail = contact.email;
        if (buyerEmailsSet.has(buyerEmail)) {
            invalidFields.push(`Email (doublon : ${buyerEmail})`);
        } else {
            buyerEmailsSet.add(buyerEmail);
        }

        const buyerPhone = contact.phone;
        if (buyerPhone.trim().length > 0) {
            if (buyerPhonesSet.has(buyerPhone)) {
                invalidFields.push(`Téléphone (doublon : ${buyerPhone})`);
            } else {
                buyerPhonesSet.add(buyerPhone);
            }
        }
    }

    checkFieldValid(missingFields, invalidFields, invoiceBaseInfosInput.number, NUMBER_LABEL, isValidString);

    const issueDateInput = invoiceBaseInfosInput.issueDate;
    const issueDateInputParsed: Date | null = parseDateInput(issueDateInput);
    checkFieldValid(missingFields, invalidFields, issueDateInput, ISSUE_DATE_LABEL, _ => issueDateInputParsed !== null);

    const dueDateInput = invoiceBaseInfosInput.dueDate;
    const dueDateInputParsed: Date | null = parseDateInput(dueDateInput);
    checkFieldValid(missingFields, invalidFields, dueDateInput, DUE_DATE_LABEL, _ => dueDateInputParsed !== null);

    checkFieldValid(missingFields, invalidFields, invoiceBaseInfosInput.amountWoTax, AMOUNT_WO_TAX_LABEL, isValidAmount);
    checkFieldValid(missingFields, invalidFields, invoiceBaseInfosInput.amountTax, AMOUNT_TAX_LABEL, isValidAmount);

    if (missingFields.length > 0) {
        errors.push(`Les champs suivants sont obligatoires : ${missingFields.join(', ')}.`);
    }

    if (invalidFields.length > 0) {
        errors.push(`Les champs suivants ne sont pas valides : ${invalidFields.join(', ')}.`);
    }

    // if (missingBuyer || missingFile || missingAdditionalFile || missingFields.length > 0 || invalidFields.length > 0) {
    //     return ({
    //         valid: null,
    //         invalid: {
    //             missingBuyer,
    //             missingFile,
    //             missingAdditionalFile,
    //             missingFields,
    //             invalidFields
    //         }
    //     });
    // }
    // // else
    //
    // return ({
    //     valid: {
    //         buyerSelection: invoiceBuyerInput.companySelection!,
    //         buyerEmails: invoiceBuyerInput.contacts.map((c) => c.email),
    //         buyerPhones: invoiceBuyerInput.contacts.filter((c) => c.phone.trim().length > 0).map((c) => c.phone),
    //         invoiceNumber: invoiceBaseInfosInput.number,
    //         invoiceIssueDate: serverDateSerialization(issueDateInputParsed!),
    //         invoiceDueDate: serverDateSerialization(dueDateInputParsed!),
    //         invoiceAmountWoTax: parseInputAmount(invoiceBaseInfosInput.amountWoTax),
    //         invoiceAmountTax: parseInputAmount(invoiceBaseInfosInput.amountTax),
    //         invoiceFileId: invoiceFileId,
    //         additionalFileIds: invoiceAdditionalFilesIds
    //     },
    //     invalid: null
    // });

    return errors;
};