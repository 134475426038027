import Loader from "factor-lib/Loader";
import {ReactElement} from "react";
import IAccessTokens from "../../Auth/IAccessTokens";
import CurrentEnv from "../../envs/CurrentEnv";
import NewPricingModal from "./NewPricingModal";
import { UseQueryResult, useQueries } from "@tanstack/react-query";
import {
    IMeCompanyIdentifier,
    IMeCompanyName,
    IMeContactEmail,
    IMeNewPricingAcceptationRequired,
    MeCompanyIdentifierProp,
    MeCompanyNameProp,
    MeContactEmailProp,
    MeNewPricingAcceptationRequiredProp,
    buildMeQueriesConfig,
    mergeQueriesData
} from "./Me";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import DataLoader from "dataloader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {getFactorContext} from "../../IFactorContext";

type IMe = IMeContactEmail & IMeNewPricingAcceptationRequired & IMeCompanyName & IMeCompanyIdentifier;

const PricingCheckAuthenticated = (
    {
        children,
        accessToken
    }: {
        children: ReactElement;
        accessToken: IAccessTokens;
    }
) => {
    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
            accessToken.customerSpecificAxios,
            getFactorContext().logger,
            graphQLQueries
        )
    );

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            [MeCompanyIdentifierProp, MeCompanyNameProp, MeContactEmailProp, MeNewPricingAcceptationRequiredProp]
        )
    );

    return (
        <ReactQueryResultWrapper result={reactQueryResultCombiner<IMe>(
                                     meQueries,
                                     () => mergeQueriesData(meQueries)
                                 )}
                                 displayFullError={CurrentEnv.debug}
                                 onLoading={() =>
                                    <Loader/>
                                }
                                onSuccess={(me: IMe) =>
                                    <>
                                        { children }
                                        { me.newPricingAcceptationRequired &&
                                            <NewPricingModal customerAxios={accessToken.customerAxios}
                                                             companyEmail={me.contactEmail}
                                                             companyName={me.companyName}
                                                             // TODO: what about foreign identifier ?
                                                             companySiren={me.companyIdentifier.value} />
                                        }
                                    </>
                                } />
    );
};

const PricingCheck = (
    {
        children,
        accessTokenO
    }: {
        children: ReactElement;
        accessTokenO: IAccessTokens | null;
    }
) =>
    !!accessTokenO
        ? <PricingCheckAuthenticated accessToken={accessTokenO}>
            { children }
        </PricingCheckAuthenticated>
        : <Loader/>;

export default PricingCheck;