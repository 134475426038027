import {useMsal} from "@azure/msal-react";
import {useEffect} from "react";
import CurrentEnv from "./envs/CurrentEnv";
import {ensureOneAuthUser} from "factor-lib/serverUtils/buildDefaultAuthAxios";
import ILogger from "factor-lib/services/logger/ILogger";
import {InteractionStatus} from "@azure/msal-browser";
import IGoogleTagManager from "./services/GTM/IGoogleTagManager";
import {getFactorContext} from "./IFactorContext";

const LoggedIn = () => {
    const logger: ILogger = getFactorContext().logger;
    const { accounts, instance, inProgress } = useMsal();
    const idTokenClaims = ensureOneAuthUser(instance, logger, accounts).idTokenClaims!;

    useEffect(() => {
        const gtm: IGoogleTagManager = CurrentEnv.gtm(); // new MarketingCookieHandler(config.gtm);
        gtm.init(idTokenClaims.oid!);
        CurrentEnv.intercom()
            .then((intercom) => intercom.init(({
                email: idTokenClaims.emails![0],
                firstName: (idTokenClaims['given_name'] as string | undefined) || null,
                creationDateTime: new Date() // TODO
            })));
    }, [idTokenClaims, inProgress]);

    return <div/>;
}

const Logout = () =>
    // TODO : logout intercom ?
    <div/>;

const GTMAndIntercomWrapper = () => {

    const { inProgress } = useMsal();
    // Looks like we can be called on logout action, besides being inside <MsalAuthenticationTemplate/>
    return inProgress !== InteractionStatus.Logout
        ? <LoggedIn/>
        : <Logout />
};

export default GTMAndIntercomWrapper;
