import { formatAmount } from 'factor-lib/utils/amountUtils';

import './amountwithtaxes.scss';

export enum amountWithTaxesKind {
    KIND_LARGE = 'large',
    KIND_BALANCED = 'balanced'
}

const AmountWithTaxes = (
    {
        amountWoTax,
        amountTTC,
        kind
    }: {
        amountWoTax: number;
        amountTTC: number;
        kind: amountWithTaxesKind;
    }
) => {
    return (
        <div className={`amount-with-taxes--${kind}`}
             style={{display: 'flex', flexDirection: 'column', alignContent: 'end'}}>
            <div className='has-text-right p-no-wrap'>
                <span className='p-very-light p-size-9'>(TTC)</span>&nbsp;
                <span className='amount-with-taxes__text-wi-tax'>
                    { formatAmount(amountTTC) }
                </span>
                
            </div>
            <div className='has-text-right p-no-wrap'>
                <span className='p-very-light p-size-9'>(HT)</span>&nbsp;
                <span className='p-size-75 amount-with-taxes__text-wo-tax'>
                    { formatAmount(amountWoTax) }
                </span>
            </div>
        </div>
    );
}

export default AmountWithTaxes;