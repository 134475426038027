import GoBack from 'factor-lib/GoBack';
import TopBar from 'factor-lib/TopBar/TopBar';
import {DashboardInvoicesDefaultRoute} from "../Pages/Dashboard/dashboardUrlsConsts";
import TopBarUserRightDesktop from "./TopBarUserRightDesktop";
import {AccountInfo} from "@azure/msal-browser";
import ITopBarProps from './ITopBarProps';
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useContext} from "react";
import NavigateContextContext from 'factor-lib/navigationHack/NavigateContextContext';

const FactorTopBar = (
    {
        topBarProps,
        accountO
    }: {
        topBarProps: ITopBarProps | null;
        accountO: AccountInfo | null;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <TopBar style={{marginBottom: '0' /* Default margin of topbar due to level is-marginless from old bulma has disappeared*/}}
                logoClick={() => navigate(DashboardInvoicesDefaultRoute)}
                leftExtra={(!!topBarProps?.goBackActionO &&
                    <GoBack action={topBarProps.goBackActionO} />) || null
                }
                title={topBarProps?.titleO || null}
                rightMenu={
                    !!accountO && // accountsO.length > 0 /* On peut etre appele sans etre authentifie juste avant la redirection */ &&
                        <TopBarUserRightDesktop className='is-hidden-mobile level-right p-margin-right-5'
                                                userName={accountO.name! /* Always set in our case. /!\ username is the email */} />
                } />
    );
}

export default FactorTopBar;
