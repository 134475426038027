import IEnvConfig from "./IEnvConfig";
import TelemetryLogger from 'factor-lib/services/logger/TelemetryLogger';
import loadIntercom from 'factor-lib/services/Intercom/loadIntercom';
import { TelemetryCustomerInstrumentationKey } from 'factor-lib/envs/prodConfigFactory';
import ILogger from "factor-lib/services/logger/ILogger";
import GoogleTagManager from "../services/GTM/GoogleTagManager";

const ProdEnv: IEnvConfig = {

    logger: (): ILogger => new TelemetryLogger(TelemetryCustomerInstrumentationKey),

    // title: 'prod',
    baseUrl: 'https://client.dimpl.io',//  'https://yellow-wave-0c4d5c603.1.azurestaticapps.net',
    backends: {
        commonCustomer: {
            baseUrl: 'https://factor-prod-customer-api.azurewebsites.net',
            adScope: '29c95e2c-cf2b-4c8f-8027-e702f9a5f16b/Single'
        },
        directSeller: {
            baseUrl: 'https://api.dimpl.io/d/', // 'https://factor-prod-directseller-api.azurewebsites.net',
            adScope: '46a108ed-0e74-4615-9f1a-b42255748f19/Single'
        },
        marketplace: {
            baseUrl: 'https://api.dimpl.io/m/', // 'https://factor-prod-marketplaces-api.azurewebsites.net',
            adScope: '8ce7ecc5-3151-45bc-b55b-a6e3244ae013/Single'
        }
    },
    azureAD: {
        domain: 'dimpl',
        clientId: 'eda4ec82-9675-4288-bbfa-d91bf02cd42d', // dev
        signinFlowName: 'B2C_1_first-signin', // https://dimplfactortest.b2clogin.com/dimplfactortest.onmicrosoft.com/B2C_1_first_sign_in',
        // knownAuthorities: 'https://dimplfactortest.b2clogin.com', // dev
    },

    debug: false,

    gtm: () => new GoogleTagManager('GTM-W8K4GR4'), // TODO : same in prod ?

    intercom: loadIntercom,
    logMsalInfo: false
};

export default ProdEnv;
