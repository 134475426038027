import {Navigate, NavigateFunction} from "react-router-dom";
import useUrlQuery from "../../../utils/useUrlQuery";
import {
    DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_NAME,
    DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_SPLITTER,
    DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME,
    DASHBOARD_SORT_ORDER_URL_PARAM_NAME,
    DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME,
    DashboardInvoicesDefaultSort,
    DashboardInvoicesDefaultStatusFilters,
    dashboardInvoicesRoute
} from "../dashboardUrlsConsts";
import {InvoicesStatusFilters} from "./StatusFilters";
import ICustomerType from "../../../ICustomerType";
import {Axios} from "axios";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import InvoicesTableDesktopContentPostRedirectDS from "./InvoicesTableDesktopContentPostRedirectDS";
import InvoicesTableDesktopContentPostRedirectM from "./InvoicesTableDesktopContentPostRedirectM";
import { InvoiceSortProperty } from "./invoicesSortProperties";
import { ISort, SortOrder } from "factor-lib/utils/sortingUtils";

// "01" ?
const INTEGER_REGEX = /^[0-9][1-9]*$/;

const parseValidFilter = (filterStr: string): number | null => {
    if (!INTEGER_REGEX.test(filterStr)) {
        return null;
    }

    const filterParsed: number = parseInt(filterStr, 10);

    if (filterParsed < 0 || filterParsed > InvoicesStatusFilters.length) {
        return null;
    }

    return filterParsed;
};

export const parseStatusFilters = (filtersQueryParam: string): number[] | null => {
    const filtersSplit: string[] = filtersQueryParam.split(DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_SPLITTER);

    if (filtersSplit.length > InvoicesStatusFilters.length) {
        return null;
    }

    if (filtersSplit.length === 1 && !filtersSplit[0]) {
        return [];
    }

    const validFilters: Set<number> = new Set<number>();

    for (let i = 0; i < filtersSplit.length; i++) {
        const filterStr: string = filtersSplit[i];
        const validFilter: number | null = parseValidFilter(filterStr);

        if (validFilter === null || validFilters.has(validFilter)) {
            return null;
        } else {
            validFilters.add(validFilter);
        }
    }

    return Array.from(validFilters);
};

const InvoicesTableDesktopContent = (
    {
        customerType,
        axios
    }: {
        customerType: ICustomerType;
        axios: Axios;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const query = useUrlQuery();

    const statusFiltersQueryParam: string | null =
        query.get(DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_NAME);

    const statusFilters: number[] | null =
        statusFiltersQueryParam !== null ? parseStatusFilters(statusFiltersQueryParam) : null;

    const searchFilter: string =
        query.get(DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME) ?? '';

    const sortPropertyUnchecked: string | null =
        query.get(DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME);

    const sortProperty: InvoiceSortProperty | null =
        sortPropertyUnchecked === InvoiceSortProperty.CreationDate ||
        sortPropertyUnchecked === InvoiceSortProperty.Number ||
        sortPropertyUnchecked === InvoiceSortProperty.AmountWoTax ||
        sortPropertyUnchecked === InvoiceSortProperty.AmountTTC ||
        sortPropertyUnchecked === InvoiceSortProperty.DueDate ||
        sortPropertyUnchecked === InvoiceSortProperty.BuyerName ||
        (customerType === ICustomerType.Marketplace && sortPropertyUnchecked === InvoiceSortProperty.SellerName)
            ? sortPropertyUnchecked as InvoiceSortProperty
            : null;

    const sortOrderUnchecked: string | null  =
        query.get(DASHBOARD_SORT_ORDER_URL_PARAM_NAME);
    
    const sortOrder: SortOrder | null =
        sortOrderUnchecked === SortOrder.Asc ||
        sortOrderUnchecked === SortOrder.Desc
            ? sortOrderUnchecked as SortOrder
            : null;

    if (statusFilters === null || sortProperty === null || sortOrder === null) {
        return (
            <Navigate to={
                          dashboardInvoicesRoute(
                              statusFilters ?? DashboardInvoicesDefaultStatusFilters,
                              {
                                  property: sortProperty ?? DashboardInvoicesDefaultSort.property,
                                  order: sortOrder ?? DashboardInvoicesDefaultSort.order // TODO: based on property's default sort
                              },
                              searchFilter
                          )
                      }
                      replace={true} />
        );
    }
    // else

    const sort: ISort<InvoiceSortProperty> = {
        property: sortProperty,
        order: sortOrder
    };

    const setStatusFilters = (newStatusFilters: number[]) => navigate(
        dashboardInvoicesRoute(
            newStatusFilters,
            sort,
            searchFilter
        )
    );

    const setSearchFilter = (newSearchFilter: string) => navigate(
        dashboardInvoicesRoute(
            statusFilters,
            sort,
            newSearchFilter
        )
    );

    const setSort = (newSort: ISort<InvoiceSortProperty>) => navigate(
        dashboardInvoicesRoute(
            statusFilters,
            newSort,
            searchFilter
        )
    );

    return (
        customerType === ICustomerType.DirectSeller
            ? <InvoicesTableDesktopContentPostRedirectDS directSellerAxios={axios}
                                                         statusFilters={statusFilters}
                                                         setStatusFilters={setStatusFilters}
                                                         searchFilter={searchFilter}
                                                         setSearchFilter={setSearchFilter}
                                                         sort={sort}
                                                         setSort={setSort} />
            : <InvoicesTableDesktopContentPostRedirectM marketplaceAxios={axios}
                                                        statusFilters={statusFilters}
                                                        setStatusFilters={setStatusFilters}
                                                        searchFilter={searchFilter}
                                                        setSearchFilter={setSearchFilter}
                                                        sort={sort}
                                                        setSort={setSort} />
    );
}

export default InvoicesTableDesktopContent;
