import { IInvoicesFiltersCount } from "./FiltersCount";
import { IEligibility } from "../../InvoiceStatus/IInvoiceStatus";
import { PendingEligibilityMessage } from "../CommonMessages";

const Eligible = 'ELIGIBLE';
const NotEligible = 'NOT_ELIGIBLE';
const Pending = 'PENDING';
const FinancingRequested = 'FINANCING_REQUESTED';
const FinancingRequestRejected = 'FINANCING_REQUEST_REJECTED';
const FinancingActive = 'FINANCING_ACTIVE';
const FinancingSettled = 'FINANCING_SETTLED';

type InvoiceStatus =
    typeof Eligible |
    typeof NotEligible |
    typeof Pending |
    typeof FinancingRequested |
    typeof FinancingRequestRejected |
    typeof FinancingActive |
    typeof FinancingSettled;

export interface IBaseComputation {
    name: string;
    status: InvoiceStatus;
    countExtractor: (result: IInvoicesFiltersCount) => number;
    eligibilityO: {
        valueO: IEligibility | null // null -> need to be fetched from back
    } | null; // null -> financing requested
}

export const EligibleComputation: IBaseComputation = ({
    name: 'eligible',
    status: Eligible,
    countExtractor: (r) => r.eligible,
    eligibilityO: ({
        valueO: ({ isEligible: true, notEligibleReason: null })
    })
});

export const NotEligibleComputation: IBaseComputation = ({
    name: 'notEligible',
    status: NotEligible,
    countExtractor: (r) => r.notEligible,
    eligibilityO: ({
        valueO: null
    })
});

export const PendingComputation: IBaseComputation = ({
    name: 'pending',
    status: Pending,
    countExtractor: (r) => r.pending,
    eligibilityO: ({
        valueO: ({ isEligible: null, notEligibleReason: PendingEligibilityMessage })
    })
});


export const FinancingRequestedComputation: IBaseComputation = ({
    name: 'financingRequested',
    status: FinancingRequested,
    countExtractor: (r) => r.financingRequested,
    eligibilityO: null
});

export const FinancingRequestRejectedComputation: IBaseComputation = ({
    name: 'financingRequestRejected',
    status: FinancingRequestRejected,
    countExtractor: (r) => r.financingRequestRejected,
    eligibilityO: null
});

export const FinancedComputation: IBaseComputation = ({
    name: 'financed',
    status: FinancingActive,
    countExtractor: (r) => r.financingActive,
    eligibilityO: null
});

export const SettledComputation: IBaseComputation = ({
    name: 'settled',
    status: FinancingSettled,
    countExtractor: (r) => r.financingSettled,
    eligibilityO: null
});

export interface IInvoicesStatusFilter {
    label: string;
    computations: IBaseComputation[];
}

// warning: order is important, as indexes as used in url query params
export const InvoicesStatusFilters: IInvoicesStatusFilter[] = [
    {
        label: 'Toutes',
        computations: [
            EligibleComputation,
            NotEligibleComputation,
            PendingComputation,
            FinancingRequestedComputation,
            FinancingRequestRejectedComputation,
            FinancedComputation,
            SettledComputation
        ]
    },
    {
        label: 'Éligibles',
        computations: [
            EligibleComputation
        ]
    },
    {
        label: 'Eligibilité en revue', // Slightly shorter than PendingEligibilityMessage
        computations: [
            PendingComputation
        ]
    },
    {
        label: 'Financement demandé',
        computations: [
            FinancingRequestedComputation
        ]
    },
    {
        label: 'Financées',
        computations: [
            FinancedComputation
        ]
    },
    {
        label: 'Non Éligibles',
        computations: [
            NotEligibleComputation
        ]
    },
    {
        label: 'Dossiers clos',
        computations: [
            SettledComputation
        ]
    }
];

export const getInvoicesStatusFiltersBaseComputations = (
    invoicesStatusFilters: ReadonlyArray<IInvoicesStatusFilter>
): IBaseComputation[] => {
    const seen = new Set<string>();
    const collected: IBaseComputation[] = [];
    invoicesStatusFilters.forEach((s) => {
        s.computations.forEach((c) => {
            if (!seen.has(c.name)) {
                collected.push(c);
                seen.add(c.name);
            }
        });
    });
    return collected
};
