import Button from "factor-lib/Buttons/Button";
import { KIND_OUTLINED, KIND_PRIMARY, SIZE_FIXED } from "factor-lib//Buttons/Button";
import Modal from "factor-lib/Modal";
import { useState } from "react";
import InvoiceAddRequestFinancingButtonEnabled from "./InvoiceAddRequestFinancingButton";
import { INotEligibleReason } from "./invoiceAddUtils";
import {
    IInvoicesWhichBecomeUneligible,
    BuyerFinancingImpact, IFinancingImpact
} from "../InvoiceFinancingImpact";
import {REQUEST_FINANCING_ACTION_TEXT} from "../InvoiceStatus/InvoiceStatusMessages";
import { formatAmount } from "factor-lib/utils/amountUtils";


export const INVOICE_ADD_RESULT_MODAL_ID = 'invoiceAddResultModal';
export const INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_ID = 'invoiceAddResultModalCloseButton'
export const INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_TEXT = 'Ok';

const InvoiceAddResultModalEligibleCannotFinanceContent = (
    {
        className,
        cannotFinanceReason,
        closeModal
    }: {
        className?: string;
        cannotFinanceReason: string;
        closeModal: () => void;
    }
) =>
    <div className={`p-vertical-center${!!className ? ` ${className}`: ''}`}>
        <span>
            Facture <span className='p-bold'>éligible</span>, mais non finançable :
        </span>

        <span className='p-margin-top-7'>
            { cannotFinanceReason }
        </span>

        <Button id={INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_ID}
                className='p-margin-top-5'
                text={INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_TEXT}
                isLoading={false}
                actionO={closeModal} />

    </div>;

const InvoiceAddResultModalEligibleCanFinanceContent = <R, >(
    {
        className,
        action,
        invoiceId,
        invoiceAmountTTC,
        buyerFinancingImpactO,
        invoicesWhichBecomeUneligible,
        financingImpactExtractor,
        closeModal
    }: {
        className?: string;
        action: (invoiceId: string) => Promise<R>;
        invoiceId: string;
        invoiceAmountTTC: number;
        buyerFinancingImpactO: BuyerFinancingImpact | null;
        invoicesWhichBecomeUneligible: IInvoicesWhichBecomeUneligible[];
        financingImpactExtractor: (r: R) => IFinancingImpact;
        closeModal: () => void;
    }
) => {
    const [financingRequested, setFinancingRequested] = useState(false);

    return (
        !financingRequested
            ? <div className={`p-vertical-center${!!className ? ` ${className}`: ''}`}>
                <div className='p-vertical-center'>

                    <div className='p-margin-bottom-5'>Facture <span className='p-bold'>éligible</span>.</div>

                    <div>
                        { !!buyerFinancingImpactO && (
                            !!buyerFinancingImpactO.outstandingLimitO.successO && buyerFinancingImpactO.outstandingLimitO.successO.value !== null
                                ? <div>L’encours disponible qu’il vous restera sur cet acheteur sera de {formatAmount(buyerFinancingImpactO.outstandingLimitO.successO.value - buyerFinancingImpactO.newOutstanding)}</div>
                                : <div>L’encours sur cet acheteur sera de {formatAmount(buyerFinancingImpactO.newOutstanding)}</div>
                        )}

                        { invoicesWhichBecomeUneligible.length > 0 &&
                            <div className='p-margin-top-5'>
                                Attention, voici les factures qui deviendraient inéligibles :
                                <div className='p-margin-left-4'>
                                    <ul>
                                        { invoicesWhichBecomeUneligible
                                            .map((i, index) =>
                                                <li key={`k-${index}`}>
                                                    - {i.buyerCompanyName} - {i.number} - {formatAmount(i.amountTTC)}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        }

                        <div className='p-margin-top-5'>Voulez vous envoyer une demande de financement ?</div>
                    </div>
                </div>

                <div className='columns p-margin-top-5'>
                    <div className='column'>
                        <Button id={INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_ID}
                                kind={KIND_OUTLINED}
                                size={SIZE_FIXED}
                                text='Ajouter sans financer'
                                isLoading={false}
                                actionO={closeModal} />
                    </div>
                    <div className='column'>
                        <InvoiceAddRequestFinancingButtonEnabled action={action}
                                                                 invoiceId={invoiceId}
                                                                 invoiceAmountTTC={invoiceAmountTTC}
                                                                 financingImpactExtractor={financingImpactExtractor}
                                                                 setResult={(_) => setFinancingRequested(true)}
                                                                 kind={KIND_PRIMARY}
                                                                 size={SIZE_FIXED}
                                                                 text={REQUEST_FINANCING_ACTION_TEXT} />
                    </div>
                </div>
            </div>
            :
            <div className={`p-vertical-center${!!className ? ` ${className}`: ''}`}>
                <span>
                    Votre demande de financement a été prise en compte.
                    Une fois les vérifications effectuées, le versement sera fait directement sur votre compte.
                </span>

                <Button id={INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_ID}
                        className='p-margin-top-5'
                        text='Ok'
                        isLoading={false}
                        actionO={closeModal} />

            </div>
    );
};

const InvoiceAddResultModalPendingOrNotEligibleContent = (
    {
        className,
        thisBuyerLabel,
        notEligibleReason,
        closeModal
    }: {
        className?: string;
        thisBuyerLabel: string;
        notEligibleReason: INotEligibleReason | null; // null -> pending
        closeModal: () => void;
    }
) =>
    <div className={`p-vertical-center${!!className ? ` ${className}`: ''}`}>
        { notEligibleReason === null
            ? <span>{`L'éligibilité de ${thisBuyerLabel /* ce client */} est `}<span className='p-bold'>en cours de revue</span> par nos équipes. Nous reviendrons vers vous sous 48h maximum avec une réponse.</span>
            : notEligibleReason.isGlobalLimitReached
                ? <span>{notEligibleReason.reason}</span>
                : <span>Facture <span className='p-bold'>non éligible</span> : {notEligibleReason.reason}.</span>
        }

        <Button id={INVOICE_ADD_RESULT_MODAL_CLOSE_BUTTON_ID}
                className='p-margin-top-5'
                text='Ok'
                isLoading={false}
                actionO={closeModal} />

    </div>;

const InvoiceAddResultModal = <R, >(
    {
        sellerCannotFinanceReason,
        invoiceId,
        eligible,
        buyerFinancingImpactO,
        invoicesWhichBecomeUneligible,
        invoiceAmountTTC,
        notEligibleReason,
        requestFinancingAction,
        requestFinancingImpactExtractor,
        thisBuyerLabel,
        closeModal
    }: {
        sellerCannotFinanceReason: string | null;
        invoiceId: string;
        eligible: boolean | null; // null -> pending
        notEligibleReason: INotEligibleReason | null;
        buyerFinancingImpactO: BuyerFinancingImpact | null;
        invoicesWhichBecomeUneligible: IInvoicesWhichBecomeUneligible[] | null; // Only when eligible
        invoiceAmountTTC: number;
        requestFinancingAction: (invoiceId: string) => Promise<R>;
        requestFinancingImpactExtractor: (r: R) => IFinancingImpact;
        thisBuyerLabel: string;
        closeModal: () => void;
    }
) =>
    <Modal id={INVOICE_ADD_RESULT_MODAL_ID}
           maxWidth={null}
           fullMaxWidth={false}
           close={eligible === true && !!sellerCannotFinanceReason
               ? closeModal
               : undefined
           }>
        <div className='p-padding-4'>
            { eligible === true
                ? !!sellerCannotFinanceReason
                    ? <InvoiceAddResultModalEligibleCannotFinanceContent cannotFinanceReason={sellerCannotFinanceReason}
                                                                         closeModal={closeModal} />
                    : <InvoiceAddResultModalEligibleCanFinanceContent<R> invoiceId={invoiceId}
                                                                         invoiceAmountTTC={invoiceAmountTTC}
                                                                         buyerFinancingImpactO={buyerFinancingImpactO}
                                                                         invoicesWhichBecomeUneligible={invoicesWhichBecomeUneligible!}
                                                                         action={requestFinancingAction}
                                                                         financingImpactExtractor={requestFinancingImpactExtractor}
                                                                         closeModal={closeModal}/>
                : <InvoiceAddResultModalPendingOrNotEligibleContent notEligibleReason={notEligibleReason}
                                                                    thisBuyerLabel={thisBuyerLabel}
                                                                    closeModal={closeModal} />
            }
        </div>
    </Modal>;

export default InvoiceAddResultModal;
