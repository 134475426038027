import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {
    buildMeQueriesConfig,
    IMeCannotFinanceReason,
    IMeCompanyId,
    IMeCompanyIdentifier,
    IMeContactEmail,
    IMeMinInvoiceAmount,
    MeCannotFinanceReasonProp,
    MeCompanyIdentifierProp,
    MeCompanyIdProp,
    MeContactEmailProp,
    MeMinInvoiceAmountProp,
    mergeQueriesData
} from "../utils/Me";
import {getFactorContext} from "../../IFactorContext";
import ILogger from "factor-lib/services/logger/ILogger";
import DataLoader from "dataloader";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import {useQueries, UseQueryResult} from "@tanstack/react-query";
import graphQLBatchLoadFunctionAsync from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import InvoiceAddBuyerInfos, {IInvoiceBuyerInfosInput} from "./InvoiceAddBuyerInfos";
import { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import {useState} from "react";
import {IInvoiceFile, IInvoiceFileAddedWithMindeeDto} from "./InvoiceUploadFile";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import {
    IInvoiceBaseInfosInput,
    IInvoiceBaseInfosInputEnabled
} from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import {Axios, AxiosRequestConfig} from "axios";
import {IInvoiceAdditionalFile} from "./InvoiceUploadAdditionalFile";
import {
    getInvoiceValidOrNotAddParams,
    getRejectionMessage,
    IInvoiceAddDSParams
} from "./invoiceAddUtils";
import {IInvoiceAddResponse} from "./InvoiceAddButton";
import {BuyerFinancingImpact, IInvoicesWhichBecomeUneligible} from "../InvoiceFinancingImpact";
import {IRequestFinancingResponseDS, requestFinancingDSAsync} from "../utils/requestFinancingUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import { parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import {IPaginated, query} from "factor-lib/utils/graphQLPagination";
import {BuyerSortProperty, BuyerSortPropertyToOrderByField} from "../Dashboard/Buyers/buyersSortProperties";
import {SortOrder, SortOrderToOrderByDirection} from "factor-lib/utils/sortingUtils";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";
import InvoiceAddContentBaseLayout from "./InvoiceAddContentBaseLayout";

export interface IFinancingImpactDS {
    forBuyer: BuyerFinancingImpact;
    invoicesWhichBecomeUneligible: IInvoicesWhichBecomeUneligible[];
}

export type IMe = IMeCompanyId & IMeCompanyIdentifier & IMeMinInvoiceAmount & IMeCannotFinanceReason & IMeContactEmail;

export const NewBuyerCompanySearchPlaceholder = 'Nom ou SIREN de votre société cliente'
export const SelectExistingBuyerPlaceholder = 'Choisissez un client';



const hasAnyBuyersQueryFuncAsync =
    async (
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
        signal: AbortSignal | undefined,
        queryKey: string[]
    ): Promise<boolean> => {
        const r: any = await graphQLDataLoader.load({
            query: {
                query: `query {
                    hasAnyBuyer: buyers {
                        any
                    }
                }`
            },
            queryKey,
            signal
        });
        return r.hasAnyBuyer.any as boolean;
    };

const buyersQueryFuncAsync =
    async (
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
        signal: AbortSignal | undefined,
        pageParam: any | null,
        searchInputStr: string | null,
        queryKey: string[]
    ): Promise<IPaginated<IBuyerCompany>> => {
        const r: any = await graphQLDataLoader.load({
            query: {
                query: `query (
                    $after: PaginationGuidCursor,
                    $search: String
                ) {
                    buyers (search: $search) {
                        list(
                            first: 10,
                            after: $after,
                            orderByField: ${BuyerSortPropertyToOrderByField.get(BuyerSortProperty.Name)!},
                            orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Asc)!}
                        ) ${query(
                            `
                                id
                                name
                                identifier { ${IntCompanyIdentifierGraphQLFields} }
                            `
                        )}
                    }
                }`,
                variables: {
                    search: searchInputStr,
                    after: pageParam
                }
            },
            queryKey,
            signal
        });
        return r.buyers.list as IPaginated<IBuyerCompany>;
    };

const contactsQueryFn = async (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    signal: AbortSignal | undefined,
    queryKey: string[],
    buyerCompanyId: string
) => {
    const query: IGraphQLParams =
        ({
            query:
                `
                    query Q($companyId: Guid!) {
                        buyerEmails (companyId: $companyId)
                        buyerPhones (companyId: $companyId)
                    }
                `,
            variables: {
                companyId: buyerCompanyId
            }
        });
    const r = await graphQLDataLoader.load({
        query,
        queryKey,
        signal
    });
    return ({
        emails: r.buyerEmails,
        phones: r.buyerPhones,
    });
};

const InvoiceAddContentPostRedirectDS = (
    {
        className,
        customerAxios,
        directSellerAxios,
        buyerPreSelectedId
    }: {
        className?: string;
        customerAxios: Axios;
        directSellerAxios: Axios;
        buyerPreSelectedId: string | null;
    }
) => {
    const factorContext = getFactorContext();
    const logger: ILogger = factorContext.logger;

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunctionAsync(
            directSellerAxios,
            logger,
            graphQLQueries
        )
    );

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            [MeCompanyIdProp, MeCompanyIdentifierProp, MeMinInvoiceAmountProp, MeCannotFinanceReasonProp, MeContactEmailProp]
        )
    );

    const meQuery = reactQueryResultCombiner<IMe>(
        meQueries,
        () => mergeQueriesData(meQueries)
    );

    const [invoiceFile, setInvoiceFile] = useState<IInvoiceFile | null>(null);
    const [invoiceAdditionalFiles, setInvoiceAdditionalFiles] = useState<IInvoiceAdditionalFile[]>([]);

    const [buyerSelection, setBuyerSelection] = useState<IBuyerCompanySelection | null>(!!buyerPreSelectedId ? { existingIdO: buyerPreSelectedId, newSirenO: null } : null);
    const [buyerContactsInputs, setBuyerContactsInputs] = useState<ILineContactInput[]>([{ email: '', phone: '' }]);

    const buyerInfosInput: IInvoiceBuyerInfosInput = {
        companySelection: buyerSelection,
        contacts: buyerContactsInputs
    };

    const [numberInput, setNumberInput] = useState('');
    const [issueDateInput, setIssueDateInput] = useState('');
    const [dueDateInput, setDueDateInput] = useState('');
    const [amountWoTaxInput, setAmountWoTaxInput] = useState('');
    const [amountTaxInput, setAmountTaxInput] = useState('');

    const baseInfosInput: IInvoiceBaseInfosInput = {
        number: numberInput,
        issueDate: issueDateInput,
        dueDate: dueDateInput,
        amountWoTax: amountWoTaxInput,
        amountTax: amountTaxInput
    };

    const baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled = {
        updateNumber: setNumberInput,
        updateIssueDate: setIssueDateInput,
        updateDueDate: setDueDateInput,
        updateAmountWoTax: setAmountWoTaxInput,
        updateAmountTax: setAmountTaxInput,
        autofocus: false
    };

    return (
        <InvoiceAddContentBaseLayout<IMe, IFinancingImpactDS, IInvoiceAddDSParams, IRequestFinancingResponseDS>
            className={className}
            invoiceFileO={invoiceFile}
            setInvoiceFile={setInvoiceFile}
            uploadFileAxiosAction={(file: File, config: AxiosRequestConfig) => {
                const data = new FormData();
                data.append('file', file);
                return directSellerAxios.post<IInvoiceFileAddedWithMindeeDto>(
                    `/directSellerInvoiceFiles`,
                    data,
                    config
                );
            }}
            baseInfosInput={baseInfosInput}
            baseInfosInputEnabled={baseInfosInputEnabled}
            sellerBuyerCardTitle='Informations sur votre client'
            sellerBuyerCardContentNode={
                <InvoiceAddBuyerInfos canLoadO={{
                                          queryKeysFactory: (keys) => keys,
                                          hasAnyQueryFn: (
                                              signal: AbortSignal | undefined,
                                              queryKey: string[]
                                          ) => hasAnyBuyersQueryFuncAsync(
                                              graphQLDataLoader,
                                              signal,
                                              queryKey
                                          ),
                                          queryFn: (
                                              signal: AbortSignal | undefined,
                                              pageParam: any | null,
                                              searchInputStr: string | null,
                                              queryKey: string[]
                                          ) => buyersQueryFuncAsync(
                                              graphQLDataLoader,
                                              signal,
                                              pageParam,
                                              searchInputStr,
                                              queryKey
                                          )
                                      }}
                                      buyerPreSelectedO={!!buyerPreSelectedId ? ({
                                          id: buyerPreSelectedId,
                                          graphQLDataLoader
                                      }) : null}
                                      input={buyerInfosInput}
                                      setBuyerSelection={setBuyerSelection}
                                      setBuyerContactsInputs={setBuyerContactsInputs}
                                      customerSpecificAxios={directSellerAxios}
                                      customerAxios={customerAxios}
                                      autofocus={true}
                                      // existingBuyerLabel={existingBuyerLabel}
                                      // newBuyerLabel={newBuyerLabel}
                                      contactsQueryKeyFactory={(base) => base}
                                      contactsQueryFn={(
                                          signal: AbortSignal | undefined,
                                          queryKey: string[],
                                          buyerCompanyId: string
                                      ) => contactsQueryFn(
                                          graphQLDataLoader,
                                          signal,
                                          queryKey,
                                          buyerCompanyId
                                      )}
                                      newBuyerPlaceholder={NewBuyerCompanySearchPlaceholder}
                                      existingBuyerPlaceholder={SelectExistingBuyerPlaceholder} />
            }
            invoiceAdditionalFiles={invoiceAdditionalFiles}
            setInvoiceAdditionalFiles={setInvoiceAdditionalFiles}
            uploadAdditionalFileAxiosAction={(file: File, config: AxiosRequestConfig) => {
                const data = new FormData();
                data.append('file', file);
                return directSellerAxios.post<string>(
                    `/directSellerInvoiceFiles/additional`,
                    data,
                    config
                );
            }}
            meQuery={meQuery}
            addButtonBaseProps={{
                baseAxios:(params: IInvoiceAddDSParams, config: AxiosRequestConfig) =>
                    directSellerAxios.post<IInvoiceAddResponse<IFinancingImpactDS>>(
                        '/directSellerInvoices',
                        params,
                        config
                    ),
                getValidationErrors:() => getInvoiceValidOrNotAddParams(
                    invoiceFile?.id ?? null,
                    invoiceAdditionalFiles.map((a) => a.id),
                    baseInfosInput,
                    buyerInfosInput,
                    'de client'
                ),
                getValidParams:() => ({
                    buyerSelection: buyerInfosInput.companySelection!,
                    buyerEmails: buyerInfosInput.contacts.map((c) => c.email),
                    buyerPhones: buyerInfosInput.contacts.filter((c) => c.phone.trim().length > 0).map((c) => c.phone),
                    invoiceNumber: baseInfosInput.number,
                    invoiceIssueDate: serverDateSerialization(parseDateInput(baseInfosInput.issueDate)!),
                    invoiceDueDate: serverDateSerialization(parseDateInput(baseInfosInput.dueDate)!),
                    invoiceAmountWoTax: parseInputAmount(baseInfosInput.amountWoTax),
                    invoiceAmountTax: parseInputAmount(baseInfosInput.amountTax),
                    invoiceFileId: invoiceFile!.id,
                    additionalFileIds: invoiceAdditionalFiles.map((a) => a.id)
                }),
                addInvoiceExtractBuyerFinancingImpactO: (i: IFinancingImpactDS) => i.forBuyer,
                addInvoiceInvoicesWhichBecomeUneligibleExtractor: (i: IFinancingImpactDS) => i.invoicesWhichBecomeUneligible,
                requestFinancingAction: (invoiceId2: string) =>
                    requestFinancingDSAsync(directSellerAxios, invoiceId2),
                requestFinancingImpactExtractor: (r: IRequestFinancingResponseDS) => ({
                    forBuyerO: r.financingImpact.forBuyer,
                    invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                }),
                thisBuyerLabel: 'ce client'
            }}
            sellerCannotFinanceReasonFactory={(me: IMe) => me.cannotFinanceReason}
            getRejectionMessageFactory={(me: IMe, p: IInvoiceAddDSParams) => getRejectionMessage(
                {
                    id: me.companyId,
                    identifier: me.companyIdentifier
                },
                'Désolé, vous ne pouvez pas financer de facture sur vous-même :)',
                me.minInvoiceAmount,
                me.contactEmail,
                'Désolé, vous ne pouvez pas utiliser votre propre email.',
                p
            )} />
    );
}

export default InvoiceAddContentPostRedirectDS;