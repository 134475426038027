import ButtonForModal from 'factor-lib/Buttons/ButtonForModal/ButtonForModal';
import { DeclareBuyerPaymentModalContent } from "../utils/DeclareBuyerPaymentModal";
import { TitledModalContent } from "factor-lib/Modals/TitledModal";
import { IFinancingRequestAcceptedStatusWithDetails } from './InvoiceDetailsQuery';
import IInvoiceStatus from '../InvoiceStatus/IInvoiceStatus';
import IDeclareBuyerPaymentParams from "../Dashboard/Invoices/InvoiceList/actions/IDeclareBuyerPaymentParams";

// TODO : reutiliser une classe de l'admin
const DeclareDirectRecuperationButton = (
    {
        className,
        action,
        invoiceId,
        invoiceAmountTTC,
        partialAmountPaidByBuyer,
        invoiceIssueDate,
        invoiceDueDate,
        invoiceStatus,
        minInvoiceAmount
    }: {
        className?: string
        action: (p: IDeclareBuyerPaymentParams) => Promise<void>;
        invoiceId: string;
        invoiceAmountTTC: number;
        partialAmountPaidByBuyer: number;
        invoiceIssueDate: Date;
        invoiceDueDate: Date;
        invoiceStatus: IInvoiceStatus<IFinancingRequestAcceptedStatusWithDetails>;
        minInvoiceAmount: number;
    }
) =>
    <ButtonForModal className={className}
                    id='buyerPayment'
                    text='Signaler un paiement direct'
                    modalMaxWidth='600px'
                    modalFullMaxWidth={false}
                    childModalContent={(closeModal) =>
                        <TitledModalContent title='Déclarer un paiement de votre Acheteur'>
                            <DeclareBuyerPaymentModalContent action={action}
                                                             invoiceId={invoiceId}
                                                             invoiceAmountTTC={invoiceAmountTTC}
                                                             partialAmountPaidByBuyer={partialAmountPaidByBuyer}
                                                             invoiceIssueDate={invoiceIssueDate}
                                                             invoiceDueDate={invoiceDueDate}
                                                             invoiceStatus={{
                                                                 isEligible: invoiceStatus.eligibility?.isEligible ?? null,
                                                                 isFinancingRequested: !!invoiceStatus.financingRequest
                                                                     ? {
                                                                         isRejected: !!invoiceStatus.financingRequest.rejectedDate,
                                                                         isFinanced: !!invoiceStatus.financingRequest.accepted
                                                                     }
                                                                     : null
                                                             }}
                                                             minInvoiceAmount={minInvoiceAmount}
                                                             closeModal={closeModal} />

                        </TitledModalContent>
                    }/>;

export default DeclareDirectRecuperationButton;
