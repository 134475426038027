import TextOnlyModal from "factor-lib/Modals/TextOnlyModal";
import {ReactElement, useState} from "react";

// An utility class for components which may show a "TextOnlyModal" for different reasons
const WithTextOnlyModal = (
    {
        child
    }: {
        child: (setTextOnlyModalO: (newMessageO: string | null) => void) => ReactElement;
    }
) => {
    const [textOnlyModalO, setTextOnlyModalO] = useState<string | null>(null);

    return (
        <>
            { !!textOnlyModalO &&
                <TextOnlyModal close={() => setTextOnlyModalO(null)}
                               message={textOnlyModalO}
                               maxWidth={null}
                               fullMaxWidth={false}
                               buttonsProps={{
                                   id: 'genericTextOnlyModal',
                                   text: 'Ok',
                                   isLoading: false,
                                   actionO: () => setTextOnlyModalO(null)
                               }} />
            }
            { child(setTextOnlyModalO) }
        </>
    );
}

export default WithTextOnlyModal;