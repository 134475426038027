import { InvoiceSortProperty } from "./Invoices/invoicesSortProperties";
import { BuyerSortProperty } from "./Buyers/buyersSortProperties";
import { ISort, SortOrder } from "factor-lib/utils/sortingUtils";

export const DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_NAME = 'filters';
export const DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_SPLITTER = ',';

export const DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME = 'search';
export const DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME = 'sort';
export const DASHBOARD_SORT_ORDER_URL_PARAM_NAME = 'order';

export const DashboardRoutePrefix = '/dashboard';

export const dashboardRoute = (pageName: string) =>
    `${DashboardRoutePrefix}/${pageName}`;

export const DashboardBuyersPageName = 'clients';
export const DashboardInvoicesPageName = 'invoices';

export const DashboardDefaultPageName = DashboardInvoicesPageName;
export const DashboardDefaultRoute = dashboardRoute(DashboardDefaultPageName);

// Invoices

export const DashboardInvoicesDefaultStatusFilters = [1 /* Eligible */, 2 /* Pending */, 3 /* Requested */, 4 /* Financed */];
export const DashboardInvoicesDefaultSort: ISort<InvoiceSortProperty> = {
    property: InvoiceSortProperty.CreationDate,
    order: SortOrder.Desc
};

export const dashboardInvoicesRoute = (
    statusFilters: number[],
    sort: ISort<InvoiceSortProperty>,
    searchFilter: string
): string => {
    const query = new URLSearchParams();

    // Works without since invoices is the default, but let's be more precise
    query.set(
        DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_NAME,
        statusFilters
            .map((f) => f.toString(10))
            .join(DASHBOARD_INVOICES_STATUS_FILTERS_URL_PARAM_SPLITTER)
    );

    if (!!searchFilter) {
        query.set(DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME, searchFilter);
    }

    query.set(DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME, sort.property);
    query.set(DASHBOARD_SORT_ORDER_URL_PARAM_NAME, sort.order);

    return `${dashboardRoute(DashboardInvoicesPageName)}?${query.toString()}`;
}

export const DashboardInvoicesDefaultRoute = dashboardInvoicesRoute(
    DashboardInvoicesDefaultStatusFilters,
    DashboardInvoicesDefaultSort,
    ''
);

// Buyers

export const DashboardBuyersDefaultSort: ISort<BuyerSortProperty> = {
    property: BuyerSortProperty.CreationDate,
    order: SortOrder.Desc
};

export const dashboardBuyersRoute = (
    sort: ISort<BuyerSortProperty>,
    searchFilter: string
): string => {
    const query = new URLSearchParams();

    if (!!searchFilter) {
        query.set(DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME, searchFilter);
    }

    query.set(DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME, sort.property);
    query.set(DASHBOARD_SORT_ORDER_URL_PARAM_NAME, sort.order);

    return `${dashboardRoute(DashboardBuyersPageName)}?${query.toString()}`;
};

// export const DashboardBuyersDefaultRoute = dashboardBuyersRoute(
//     DashboardBuyersDefaultSortProperty,
//     DashboardBuyersDefaultSortOrderAscending,
//     ''
// );
