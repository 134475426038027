import {CSSProperties, useContext} from "react";
import { IInvoicesHeader } from "./Header";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import NavigateContextContext from 'factor-lib/navigationHack/NavigateContextContext';
import InvoicesFilter from "./InvoicesFilter";
import { IInvoicesFiltersCount } from "./FiltersCount";
import WithOverviewSection from "../WithOverviewSection";
import { formatAmountWoCents } from 'factor-lib/utils/amountUtils';
import {INVOICES_TOP_BAR_NEW_MANUAL_INVOICE_ID} from "./OverviewTopbar/InvoicesOverviewTopBarConsts";
import {invoiceAddUrl} from "../../InvoiceAdd/invoiceAddUrl";
import InvoicesList from "./InvoiceList/InvoicesList";
import {UseInfiniteQueryResult} from "@tanstack/react-query";
import {IPaginated} from "factor-lib/utils/graphQLPagination";
import {ISort} from "factor-lib/utils/sortingUtils";
import {InvoiceSortProperty} from "./invoicesSortProperties";
import IActions from "./InvoiceList/actions/IActions";
import {ISeller} from "./FilteredInvoicesM";
import {IDSInvoice} from "./FilteredInvoices";

export const AddInvoiceText = 'Ajouter une facture';

const InvoicesTableDesktopTemplate = <I extends IDSInvoice, R>(
    {
        style,
        statusFilters,
        setStatusFilters,
        searchFilter,
        setSearchFilter,
        loaded,
        queries,
        sellerExtractorO,
        sorter,
        actions,
        sort,
        setSort
    }: {
        style?: CSSProperties;
        statusFilters: number[];
        setStatusFilters: (newStatusFilters: number[]) => void;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        loaded: {
            header: IInvoicesHeader;
            filtersCount: IInvoicesFiltersCount;
            outstandingLimit: number;
        } | null;
        queries: UseInfiniteQueryResult<IPaginated<I>>[];
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        sorter: (i1: I, i2: I) => number;
        actions: IActions<I, R>;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    return (
        <WithOverviewSection style={style}
                             summariesItems={[
                                 {
                                     legend: 'Encours actuel / Limite',
                                     value: !!loaded ? `${formatAmountWoCents(loaded.header.totalActive)} / ${formatAmountWoCents(loaded.outstandingLimit)}` : null
                                 },
                                 {
                                     legend: 'Factures à échéance prochaine',
                                     value: !!loaded ? formatAmountWoCents(loaded.header.totalUpcoming) : null
                                 },
                                 {
                                     legend: 'Factures éligibles à Dimpl',
                                     value: !!loaded ? formatAmountWoCents(loaded.header.totalEligible) : null
                                 }
                             ]}
                             topRightActionO={{
                                 id: INVOICES_TOP_BAR_NEW_MANUAL_INVOICE_ID,
                                 label: AddInvoiceText,
                                 action: () => navigate(invoiceAddUrl)
                             }}>

            <InvoicesFilter className='p-margin-top-4'
                            statusFilters={statusFilters}
                            setStatusFilters={setStatusFilters}
                            searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            filtersCountLoadedO={loaded?.filtersCount ?? null}/>

            <InvoicesList<I, R> queries={queries}
                                sellerExtractorO={sellerExtractorO}
                                sorter={sorter}
                                actions={actions}
                                sort={sort}
                                setSort={setSort} />
        </WithOverviewSection>
    );
};

export default InvoicesTableDesktopTemplate;
