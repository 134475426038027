import {buildInvoicesGraphQLQueryProvider} from "./FilteredInvoices";
import { IMeOutstandingLimit, MeOutstandingLimitProp } from "../../utils/Me";
import InvoicesTableDesktopContentPostRedirect from "./InvoicesTableDesktopContentPostRedirect";
import { Axios } from "axios";
import { buildInvoicesSortWiSeller } from "./InvoiceList/invoicesComparators";
import DataLoader from "dataloader";
import { getFactorContext } from "../../../IFactorContext";
import ILogger from "factor-lib/services/logger/ILogger";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import graphQLBatchLoadFunctionAsync from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import { buildSortFinal } from "factor-lib/utils/graphQLPagination";
import { InvoiceSortProperty } from "./invoicesSortProperties";
import { ISort } from "factor-lib/utils/sortingUtils";
import {IRequestFinancingResponseM} from "../../InvoiceAdd/InvoiceAddContentPostRedirectM";
import {
    requestFinancingMAsync
} from "../../utils/requestFinancingUtils";
import {GraphQLSellerSelection, IWithSellerInvoice} from "./FilteredInvoicesM";

type IMMe = IMeOutstandingLimit;

const InvoicesTableDesktopContentPostRedirectM = (
    {
        marketplaceAxios,
        statusFilters,
        setStatusFilters,
        searchFilter,
        setSearchFilter,
        sort,
        setSort
    }: {
        marketplaceAxios: Axios;
        statusFilters: number[];
        setStatusFilters: (newStatusFilters: number[]) => void;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) => {
    const factorContext = getFactorContext();
    const logger: ILogger = factorContext.logger;

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunctionAsync(
            marketplaceAxios,
            logger,
            graphQLQueries
        )
    );

    const invoicesSorterFinal = buildSortFinal(
        buildInvoicesSortWiSeller(sort.property),
        sort.order,
        (i) => i.id
    );

    return (
        <InvoicesTableDesktopContentPostRedirect<IWithSellerInvoice, IMMe, IRequestFinancingResponseM>
            graphQLDataLoader={graphQLDataLoader}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            sort={sort}
            setSort={setSort}
            meProps={[MeOutstandingLimitProp]}
            actionsProvider={() => ({
                canRequestFinancingO: {
                    cannotFinanceReasonO: (i: IWithSellerInvoice) => i.seller.cannotFinanceReason,
                    action:(invoice: IWithSellerInvoice) =>
                        requestFinancingMAsync(marketplaceAxios, invoice.id),
                    financingImpactExtractor: (r: IRequestFinancingResponseM) => ({
                        forBuyerO: null,
                        invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                    })
                },
                canDeclareBuyerPaymentInvoiceO: null
            })}
            graphQLQueryProvider={buildInvoicesGraphQLQueryProvider(GraphQLSellerSelection)}
            sorter={invoicesSorterFinal}
            sellerExtractorO={(i: IWithSellerInvoice) => i.seller} />
    );
}

export default InvoicesTableDesktopContentPostRedirectM;