import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import CurrentEnv from "./envs/CurrentEnv";
// import HttpClientContext from 'factor-lib/components/HttpClientContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import RouterWrapper from "./RouterWrapper";
import {setFactorContext} from "./IFactorContext";
import logger from "./logger";

// Doit etre en dernier, sinon ne marche pas
import './index.scss';

// ref : https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // https://tanstack.com/query/v4/docs/react/guides/query-invalidation
            // by default, staletime is 0. Next refetch always triggers a network call.
            // TODO: how much ? per query ? is 30 seconds good ?
            staleTime: 30 * 1000

            // retry: false,
            // refetchOnWindowFocus: false
        }
    }
});

setFactorContext(logger, CurrentEnv.debug);

ReactDOM.createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <RouterWrapper/>
                {/*<BrowserRouter>*/}
                {/*    <MsalComponentAndTopBarWrapper msalInstance={msalInstance}/>*/}
                {/*</BrowserRouter>*/}
                { CurrentEnv.debug &&
                    <ReactQueryDevtools initialIsOpen={false} />
                }
            </QueryClientProvider>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
