import {IPaginated} from "factor-lib/utils/graphQLPagination";


export interface ISubQueryInfos<I> {
    loaded: I[];
    endCursor: I;
    nextPageFetchO: (() => Promise<IPaginated<I>>) | null; // null -> no next page
}

class CustomIteratorQuery<I> {

    private _iterator: Iterator<I>;
    private readonly _sorter: ((i1: I, i2: I) => number);
    public readonly hasNextPage: boolean;

    private _currentTail: I | null; // null -> exhausted

    constructor(
        iterator: Iterator<I>,
        sorter: ((i1: I, i2: I) => number),
        hasNextPage: boolean
    ) {
        this._iterator = iterator;
        this._sorter = sorter;
        this.hasNextPage = hasNextPage;
        this._currentTail = this.moveNext();
    }

    public next(): I {
        const r = this._currentTail!;
        this._currentTail = this.moveNext();
        return r;
    }

    private moveNext(): I | null {
        const newNext = this._iterator.next();
        return newNext.done
            ? null // Should only be the case after _tail, if in 'trailing' query.
            : newNext.value;
    }

    public currentTail(finalTail: I): I | null {
        // Is this useful ?
        if (this._currentTail === null) {
            return null;
        }
        return this._sorter(this._currentTail, finalTail) <= 0 ? this._currentTail : null;
    }
}

class CustomIterator<I> implements Iterator<I> {

    private subQueries: Array<CustomIteratorQuery<I>>;

    private readonly _sorter: ((i1: I, i2: I) => number);
    private _finalTail: I;

    private readonly _queries: ISubQueryInfos<I>[];

    constructor(
        subQueries: Array<CustomIteratorQuery<I>>,
        invoicesSorter: (i1: I, i2: I) => number,
        finalTail: I,
        queries: ISubQueryInfos<I>[]
    ) {
        this.subQueries = subQueries;
        this._sorter = invoicesSorter;
        this._finalTail = finalTail;
        this._queries = queries;
    }

    public next(): IteratorResult<I> {
        const pageWithFirstCurrentTail: CustomIteratorQuery<I> | null = this.subQueries
            .filter((s) => !!s.currentTail(this._finalTail))
            .reduce(
                (prev: CustomIteratorQuery<I> | null, newValue: CustomIteratorQuery<I>) =>
                    (!prev || (this._sorter(prev.currentTail(this._finalTail)!, newValue.currentTail(this._finalTail)!) > 0)) ? newValue : prev,
                null
            );

        if (!!pageWithFirstCurrentTail) {
            const newValue = pageWithFirstCurrentTail.next();

            if ((this._finalTail === newValue) && (!pageWithFirstCurrentTail.hasNextPage)) {
                // On peut commencer a depiler les autres queues
                const newLastPage = pageWithFirstUndisplayableCursor(this._queries.filter((q) => this._sorter(q.endCursor, this._finalTail) > 0), this._sorter);
                if (!!newLastPage) {
                    this._finalTail = newLastPage!.endCursor;
                } // else next call will return done
            }

            return ({
                value: newValue,
                done: false
            });
        } // else

        // See if we 'unblock'

        return ({
            value: undefined,
            done: true
        });
    }
}

export default class SortPaginationMerger<I> implements Iterable<I> {

    private readonly _paginatedQueries: ISubQueryInfos<I>[];
    private readonly _sorter: ((i1: I, i2: I) => number); // Doit etre completement "deterministe" : == 0 -> seulement si i1 = i2, cad qu'on a rajouté un check d'id derriere
    private readonly _toCursorValueConvertor: (e: I) => string;

    constructor(
        paginatedQueries: Array<{
            initial: I[];
            endCursor: string | null;
            nextPageFetchO: (() => Promise<IPaginated<I>>) | null; // null -> no next page
        }>,
        invoicesSorter: ((i1: I, i2: I) => number),
        idExtractor: (e: I) => string
    ) {
        this._paginatedQueries = paginatedQueries
            .filter((q) => !!q.endCursor) // filter out empty queries
            .map((q) => ({
                loaded: q.initial,
                nextPageFetchO: q.nextPageFetchO,
                endCursor: q.initial.find((p) => idExtractor(p) === q.endCursor)!
            }));
        this._sorter = invoicesSorter;
        this._toCursorValueConvertor = idExtractor;
    }

    private queriesWithRemainingPages(): ISubQueryInfos<I>[] {
        return this._paginatedQueries
            .filter((q) => !!q.nextPageFetchO);
    }

    public hasNextPages(): boolean {
        return this.queriesWithRemainingPages()
            .length > 0;
    }

    //  Increases > 0, but may be < the default size (20). Is this important ?
    public async fetchAnotherPageAsync(): Promise<void> {
        const lastPaginatedQuery: ISubQueryInfos<I> = pageWithFirstUndisplayableCursor(this.queriesWithRemainingPages(), this._sorter)! /* An hypothesis*/;
        const newBatch: IPaginated<I> = await lastPaginatedQuery.nextPageFetchO! /* Any hypothesis */();
        addNewPage(lastPaginatedQuery, newBatch, this._toCursorValueConvertor);
    }

    [Symbol.iterator](): Iterator<I> {
        const tailEndCursorO = pageWithFirstUndisplayableCursor(this._paginatedQueries, this._sorter)?.endCursor;
        return !!tailEndCursorO
            ? new CustomIterator(
                this._paginatedQueries.map((q) => new CustomIteratorQuery(
                    q.loaded[Symbol.iterator]() /* Restart from start */,
                    this._sorter,
                    !!q.nextPageFetchO
                )),
                this._sorter,
                tailEndCursorO,
                this._paginatedQueries
            )
            // all queries empty
            : [][Symbol.iterator]();
    }
}

const addNewPage = <I, >(
    queryNonComparable: ISubQueryInfos<I>,
    fetchResult: IPaginated<I>,
    toCursorValueConvertor: (e: I) => string
) => {
    const newElements = fetchResult.edges.map((e) => e.node);
    queryNonComparable.loaded = [
        ...queryNonComparable.loaded,
        ...newElements
    ]
    queryNonComparable.endCursor = newElements.find((e) => toCursorValueConvertor(e) === fetchResult.pageInfo.endCursor)!;
    // TODO : update some parameter ?
    queryNonComparable.nextPageFetchO = (fetchResult.pageInfo.hasNextPage && queryNonComparable.nextPageFetchO) || null;
}

const pageWithFirstUndisplayableCursor = <I, >(queries: ISubQueryInfos<I>[], _sorter: ((i1: I, i2: I) => number)): ISubQueryInfos<I> | null =>
    queries.reduce(
        (prev: ISubQueryInfos<I> | null, newValue: ISubQueryInfos<I>) =>
            !prev || (_sorter(prev.endCursor, newValue.endCursor) > 0) ? newValue : prev,
        null
    );