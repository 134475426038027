import IEnvConfig from "./IEnvConfig";
import DevEnv from "./DevEnv";
import IntercomLogging from 'factor-lib/services/Intercom/IntercomLogging';
import ILogger from "factor-lib/services/logger/ILogger";
import WindowLogger from "factor-lib/services/logger/WindowLogger";
import GtmLogging from "../services/GTM/GtmLogging";

const LocalEnv: IEnvConfig = {

    logger:
        // devTelemetry,
        (): ILogger => new WindowLogger(true /* Disable here to disable popup */),

    intercom:
        () => Promise.resolve(new IntercomLogging()),

    gtm:
        () => new GtmLogging(),

    // title: 'local',

    baseUrl: 'http://localhost:3000',
    backends: {
        commonCustomer: {
            baseUrl: 'https://localhost:7185',
            adScope: DevEnv.backends.commonCustomer.adScope
        },
        directSeller: {
            baseUrl: 'https://localhost:7186',
            adScope: DevEnv.backends.directSeller.adScope
        },
        marketplace: {
            baseUrl: 'https://localhost:7187',
            adScope: DevEnv.backends.marketplace.adScope
        }
    },
    azureAD: DevEnv.azureAD,
    debug: true,
    logMsalInfo: true
};

export default LocalEnv;
