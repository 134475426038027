import {AddInvoiceButtonId, IInvoiceAddDSParams, UploadFileInvoiceSectionId} from "./invoiceAddUtils";
import PageSection from "factor-lib/PageSection";
import InvoiceUploadFile, {IInvoiceFile, IInvoiceFileAddedWithMindeeDto} from "./InvoiceUploadFile";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {processMindeePrediction} from "./processMindeePrediction";
import {ReactNode} from "react";
import Card from "../../utils/Card";
import { IInvoiceBaseInfosInput, IInvoiceBaseInfosInputEnabled } from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import ExtraInformationCard from "./ExtraInformationCard";
import { IQueryResult } from "factor-lib/reactquery/ReactQueryResultWrapper";
import {getFactorContext} from "../../IFactorContext";
import InvoiceAddButton, {IBaseProps} from "./InvoiceAddButton";
import {IInvoiceAdditionalFile} from "./InvoiceUploadAdditionalFile";
import Loader from "factor-lib/Loader";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";

export const BuyerSellerId = 'addInvoiceBuyer';

const InvoiceAddContentBaseLayout = <IMe, I, D extends IInvoiceAddDSParams, R>(
    {
        className,
        invoiceFileO,
        setInvoiceFile,
        uploadFileAxiosAction,
        baseInfosInput,
        baseInfosInputEnabled,
        sellerBuyerCardTitle,
        sellerBuyerCardContentNode,
        invoiceAdditionalFiles,
        setInvoiceAdditionalFiles,
        uploadAdditionalFileAxiosAction,
        meQuery,
        addButtonBaseProps,
        getRejectionMessageFactory,
        sellerCannotFinanceReasonFactory
    }: {
        className?: string;
        invoiceFileO: IInvoiceFile | null;
        setInvoiceFile: (newInvoiceFile: IInvoiceFile | null) => void;
        uploadFileAxiosAction: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<IInvoiceFileAddedWithMindeeDto>>;
        baseInfosInput: IInvoiceBaseInfosInput;
        baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled;
        sellerBuyerCardTitle: string;
        sellerBuyerCardContentNode: ReactNode;
        invoiceAdditionalFiles: IInvoiceAdditionalFile[];
        setInvoiceAdditionalFiles: (newFiles: IInvoiceAdditionalFile[]) => void;
        uploadAdditionalFileAxiosAction: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<string>>;
        meQuery: IQueryResult<IMe>;
        addButtonBaseProps: IBaseProps<D, I, R>;
        getRejectionMessageFactory: (me: IMe, props: D) => string | null;
        sellerCannotFinanceReasonFactory: (me: IMe) => string | null;
    }
) => {
    const factorContext = getFactorContext();
    return (
        <div className={`p-padding-top-5 p-padding-bottom-5 p-background${!!className ? ` ${className}` : ''}`}>
            <div className='container'>
                <div className='columns'>
                    <div className='column p-vertical-stretch'>
                        <div className='p-size-4 p-margin-top-5 p-margin-bottom-5'>
                            Etape 1/2 : upload de la facture
                        </div>

                        <PageSection id={UploadFileInvoiceSectionId}
                                     className='p-vertical-stretch p-flex-1'
                                     title={(!invoiceFileO && 'Votre facture va être analysée pour extraire des informations') || undefined}>
                            <InvoiceUploadFile invoiceFile={invoiceFileO}
                                               axiosAction={uploadFileAxiosAction}
                                               setInvoiceFile={setInvoiceFile}
                                               processMindeePrediction={(p, setMindeeConflictingFields) => processMindeePrediction(
                                                   p,
                                                   baseInfosInput,
                                                   baseInfosInputEnabled,
                                                   setMindeeConflictingFields
                                               )} />
                        </PageSection>
                    </div>

                    <div className='column'>
                        <div className='p-size-4 p-margin-top-5 p-margin-bottom-5'>
                            Etape 2/2 : ajout d’informations
                        </div>

                        <Card id={BuyerSellerId}
                              className='p-margin-top-4'
                              childrenClassName='p-padding-4'
                              titleLeftSlot={
                                  <div className='p-bold'> {sellerBuyerCardTitle} </div>
                              }>
                            { sellerBuyerCardContentNode }
                        </Card>

                        <ExtraInformationCard baseInfosInput={baseInfosInput}
                                              baseInfosInputEnabled={baseInfosInputEnabled}
                                              invoiceAdditionalFiles={invoiceAdditionalFiles}
                                              setInvoiceAdditionalFiles={setInvoiceAdditionalFiles}
                                              axiosAction={uploadAdditionalFileAxiosAction}/>
                    </div>
                </div>

                <div className='level'>
                    <div className='level-item level-right'
                         id={AddInvoiceButtonId}>
                        <ReactQueryResultWrapper result={meQuery}
                                                 displayFullError={factorContext.debug}
                                                 onLoading={() => <Loader />}
                                                 onSuccess={(me: IMe) =>
                                                     <InvoiceAddButton<I, D, R> {...{
                                                         ...addButtonBaseProps,
                                                         sellerCannotFinanceReason: sellerCannotFinanceReasonFactory(me),
                                                         getRejectionMessage: (params: D) =>  getRejectionMessageFactory(me, params)
                                                     }} />
                                                 } />
                    </div>
                </div>
            </div>
        </div>
    );
}


export default InvoiceAddContentBaseLayout;