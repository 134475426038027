import { formatAmount } from "factor-lib/utils/amountUtils";
import { IDSInvoice } from "../FilteredInvoices";

const ComplementAmount = (
    {
        className,
        amount,
        title
    }: {
        className: string;
        amount: number;
        title: string
    }
) =>
    <div className={className}
         style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'baseline', lineHeight: '26px'}}>
        <div>
            { formatAmount(amount) }
        </div>
        <div className='p-size-9 p-margin-right-8 p-no-wrap' style={{lineHeight: '19px'}}>
            { title }
        </div>
    </div>;

const InvoiceRowAmount = (
    {
        invoice
    }: {
        invoice: IDSInvoice;
    }
) => {
    const invoiceAmountTTC = invoice.amountWoTax + invoice.amountTax;
    const partialAmountPaidByBuyer = invoice.payment.partialAmountPaidByBuyer;

    const partialRemainingToPay = invoiceAmountTTC - partialAmountPaidByBuyer;
    const hasPartialRemainingToPay = 0 < partialRemainingToPay && partialRemainingToPay < invoiceAmountTTC;

    const commingLingToReturn = invoice.status.financingRequest?.accepted?.commingLingAmountToReturn || 0;

    return hasPartialRemainingToPay || commingLingToReturn > 0
        ? <div>
            <div>
                { formatAmount(invoiceAmountTTC)}
            </div>
            { hasPartialRemainingToPay &&
                <ComplementAmount className='p-warn-text'
                                  title='reste à payer'
                                  amount={partialRemainingToPay} />
            }
            { commingLingToReturn > 0 &&
                <ComplementAmount className='f-to-reverse'
                                  title='à reverser'
                                  amount={commingLingToReturn}/>
            }
        </div> : <span>{formatAmount(invoiceAmountTTC)}</span>
    ;
}

export default InvoiceRowAmount;