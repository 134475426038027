import BuyerContactsInput, { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import { useQuery } from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import { getFactorContext } from "../../IFactorContext";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";
import {buyerContactsForAddInvoiceQueryKey, IBuyerContactsForAddInvoiceQueryResult} from "./invoiceAddUtils";


const InvoiceAddBuyerContactsBuyerSelected = (
    {
        className,
        buyerSelection,
        buyerContactsInputs,
        setBuyerContactsInputs,
        queryKeyFactory,
        queryFn
    }: {
        className?: string;
        buyerSelection: IBuyerCompanySelection;
        buyerContactsInputs: ILineContactInput[];
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;
        queryKeyFactory: (base: string[]) => string[];
        queryFn: ((signal: AbortSignal | undefined, queryKeys: string[]) => Promise<IBuyerContactsForAddInvoiceQueryResult>);
    }
) => {
    const factorContext = getFactorContext();

    const buyerContactsQueryKey = queryKeyFactory(buyerContactsForAddInvoiceQueryKey(buyerSelection));
    const buyerContactsQueryResult = useQuery<IBuyerContactsForAddInvoiceQueryResult>({
        queryKey: buyerContactsQueryKey,
        queryFn: async ({signal}) =>
            queryFn(signal, buyerContactsQueryKey)
    });

    return (
        <ReactQueryResultWrapper result={buyerContactsQueryResult}
                                 displayFullError={factorContext.debug}
                                 onLoading={() =>
                                     <BuyerContactsInput className={className}
                                                         buyerContactsInputs={buyerContactsInputs}
                                                         setBuyerContactsInputs={setBuyerContactsInputs}
                                                         extraBuyerEmailErrorO={null}
                                                         autoCompleteSuggestionsO={null} />
                                 }
                                 onSuccess={(d: IBuyerContactsForAddInvoiceQueryResult) =>
                                     <BuyerContactsInput className={className}
                                                         buyerContactsInputs={buyerContactsInputs}
                                                         setBuyerContactsInputs={setBuyerContactsInputs}
                                                         extraBuyerEmailErrorO={null}
                                                         autoCompleteSuggestionsO={{
                                                             buyerEmails: d.emails,
                                                             buyerPhones: d.phones
                                                         }} />
                                 } />
    );
};

const InvoiceAddBuyerContacts = (
    {
        className,
        buyerSelection,
        buyerContactsInputs,
        setBuyerContactsInputs,
        queryKeyFactory,
        queryFn
    }: {
        className?: string;
        buyerSelection: IBuyerCompanySelection | null;
        buyerContactsInputs: ILineContactInput[];
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;
        queryKeyFactory: (base: string[]) => string[]
        queryFn: (signal: AbortSignal | undefined, queryKeys: string[], buyerCompanyId: string) => Promise<IBuyerContactsForAddInvoiceQueryResult>;
    }
) =>
    !!buyerSelection
        ? buyerSelection.existingIdO
            ? <InvoiceAddBuyerContactsBuyerSelected className={className}
                                                    buyerSelection={buyerSelection}
                                                    buyerContactsInputs={buyerContactsInputs}
                                                    setBuyerContactsInputs={setBuyerContactsInputs}
                                                    queryKeyFactory={queryKeyFactory}
                                                    queryFn={(signal: AbortSignal | undefined, queryKeys: string[]) =>
                                                        queryFn(signal, queryKeys, buyerSelection.existingIdO!)
                                                    } />
            : <BuyerContactsInput className={className}
                                  buyerContactsInputs={buyerContactsInputs}
                                  setBuyerContactsInputs={setBuyerContactsInputs}
                                  extraBuyerEmailErrorO={null}
                                  autoCompleteSuggestionsO={null} />

        : <BuyerContactsInput className={className}
                              buyerContactsInputs={buyerContactsInputs}
                              setBuyerContactsInputs={setBuyerContactsInputs}
                              extraBuyerEmailErrorO={null}
                              autoCompleteSuggestionsO={null} />;

export default InvoiceAddBuyerContacts;
