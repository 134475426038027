import { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import BuyerSelection, { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import Input from "factor-lib/forms/Inputs/Input";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import InvoiceAddBuyerContact from "./InvoiceAddBuyerContact";
import { getFactorContext } from "../../IFactorContext";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { useQuery } from "@tanstack/react-query";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import Loader from "factor-lib/Loader";
import {IPaginated} from "factor-lib/utils/graphQLPagination";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import InvoiceBuyerSelectionCheckExistingBuyers from "./InvoiceBuyerSelectionCheckExistingBuyers";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";
import {IBuyerContactsForAddInvoiceQueryResult} from "./invoiceAddUtils";
import {buyerCompanySearchAsync} from "../../utils/buyerCompanySearch";
import {Axios} from "axios";



const BuyerForAddInvoiceQueryKeyPrefix = ['buyers', 'add-invoice'];
export const BuyersForAddInvoiceQueryKey = [...BuyerForAddInvoiceQueryKeyPrefix, 'list'];

const buyerForAddInvoiceQueryKey = (buyerId: string) =>
    [...BuyerForAddInvoiceQueryKeyPrefix, 'id', buyerId];

export interface IInvoiceBuyerInfosInput {
    companySelection: IBuyerCompanySelection | null;
    contacts: ILineContactInput[];
}

// For now, only ds

const InvoiceBuyerPreSelected = (
    {
        className,
        graphQLDataLoader,
        buyerId
    }: {
        className?: string;
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        buyerId: string;
    }
) => {
    const factorContext = getFactorContext();

    const buyerQueryKey = buyerForAddInvoiceQueryKey(buyerId);
    const buyerQueryResult = useQuery<Omit<IBuyerCompany, 'id'>>({
        queryKey: buyerQueryKey,
        queryFn: async ({signal}) => {
            const query: IGraphQLParams = {
                query: `query Q($buyerId: Guid!) {
                    buyer(id: $buyerId) {
                        name
                        identifier { ${IntCompanyIdentifierGraphQLFields} }
                    }
                }`,
                variables: {
                    buyerId
                }
            };

            const r = await graphQLDataLoader.load({
                query,
                queryKey: buyerQueryKey,
                signal
            });

            return r.buyer;
        }
    });

    return (
        <ReactQueryResultWrapper result={buyerQueryResult}
                                 displayFullError={factorContext.debug}
                                 onLoading={() => <Loader/>}
                                 onSuccess={(buyer) =>
                                    <Input className={className}
                                           inputValue={formatCompanyInput(buyer.identifier, buyer.name)}
                                           enabled={null} />
                                 } />
    );
};


export const BuyerSectionId = 'buyerSection';


const InvoiceAddBuyerInfos = (
    {
        className,
        buyerPreSelectedO,
        canLoadO,
        input,
        setBuyerSelection,
        setBuyerContactsInputs,
        // companySearchRequest,
        customerSpecificAxios,
        customerAxios,
        autofocus,
        existingBuyerLabel,
        newBuyerLabel,
        newBuyerPlaceholder,
        existingBuyerPlaceholder,
        contactsQueryKeyFactory,
        contactsQueryFn
    }: {
        className?: string;
        buyerPreSelectedO: {
            id: string;
            graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        } | null;
        canLoadO: {
            queryKeysFactory: (baseKey: string[]) => string[];
            hasAnyQueryFn: (
                signal: AbortSignal | undefined,
                queryKey: string[]
            ) => Promise<boolean>;
            queryFn: (
                signal: AbortSignal | undefined,
                pageParam: any | null,
                searchInputStr: string | null,
                queryKey: string[]
            ) => Promise<IPaginated<IBuyerCompany>>;
        } | null;
        input: IInvoiceBuyerInfosInput;
        setBuyerSelection: (selection: IBuyerCompanySelection | null) => void;
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;
        // companySearchRequest: (inputCompanySearch: string) => Promise<ISearchCompaniesByNameResponse>;
        customerSpecificAxios: Axios;
        customerAxios: Axios;
        autofocus: boolean;
        existingBuyerLabel?: string;
        newBuyerLabel?: string;
        newBuyerPlaceholder: string;
        existingBuyerPlaceholder: string;
        contactsQueryKeyFactory: (base: string[]) => string[]
        contactsQueryFn: (
            signal: AbortSignal | undefined,
            queryKeys: string[],
            buyerCompanyId: string
        ) => Promise<IBuyerContactsForAddInvoiceQueryResult>;
    }
) => {
    const factorContext = getFactorContext();
    const companySearchRequest = (inputCompanySearch: string): Promise<ISearchCompaniesByNameResponse> =>
        buyerCompanySearchAsync(
            inputCompanySearch,
            customerSpecificAxios,
            customerAxios,
            factorContext.logger
        );
    return (
        <div id={BuyerSectionId} className={className}>
            { !!buyerPreSelectedO
                ? <InvoiceBuyerPreSelected className='field'
                                           graphQLDataLoader={buyerPreSelectedO.graphQLDataLoader}
                                           buyerId={buyerPreSelectedO.id} />
                : !!canLoadO
                    ? <InvoiceBuyerSelectionCheckExistingBuyers className='field'
                                                                queryKeysFactory={canLoadO.queryKeysFactory}
                                                                hasAnyQueryFn={canLoadO.hasAnyQueryFn}
                                                                queryFn={canLoadO.queryFn}
                                                                setBuyerSelection={setBuyerSelection}
                                                                setBuyerContactsInputs={setBuyerContactsInputs}
                                                                companySearchRequest={companySearchRequest}
                                                                autofocus={autofocus}
                                                                existingBuyerLabel={existingBuyerLabel}
                                                                newBuyerLabel={newBuyerLabel}
                                                                newBuyerPlaceholder={newBuyerPlaceholder}
                                                                placeholder={existingBuyerPlaceholder} />
                    // Disabled : for marketplace, we wait for seller selection
                    : <BuyerSelection className='field'
                                      displayFullError={getFactorContext().debug}
                                      newBuyerPlaceholder={newBuyerPlaceholder}
                                      companySearchRequest={companySearchRequest}
                                      setBuyerSelection={setBuyerSelection}
                                      autofocus={autofocus}
                                      resetBuyer={() => {
                                          setBuyerSelection(null);
                                          setBuyerContactsInputs([{ email: '', phone: '' }]);
                                      }}
                                      existingBuyers={{
                                          existingBuyerLabel,
                                          newBuyerLabel,
                                          SelectElement:
                                              <SingleSelect options={[]}
                                                            selectedOption={null}
                                                            selectOption={null}
                                                            placeholder={existingBuyerPlaceholder}
                                                            isLoading={false} />
                                      }} />
            }

            <InvoiceAddBuyerContact className='field'
                                    buyerSelection={input.companySelection}
                                    buyerContactsInputs={input.contacts}
                                    setBuyerContactsInputs={setBuyerContactsInputs}
                                    queryKeyFactory={contactsQueryKeyFactory}
                                    queryFn={contactsQueryFn}
            />
        </div>
    );
}

export default InvoiceAddBuyerInfos;
