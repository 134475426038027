import CurrentEnv from "./envs/CurrentEnv";
import msalInit from "factor-lib/auth/msalInit";
import {PublicClientApplication} from "@azure/msal-browser";
import logger from "./logger";

const msalInstance: PublicClientApplication = msalInit(
    logger,
    {
        // clientId: 'e9750ff5-0880-4ac5-851a-bff534c54840', // api
        clientId: CurrentEnv.azureAD.clientId,
        authority: `https://${CurrentEnv.azureAD.domain}.b2clogin.com/${CurrentEnv.azureAD.domain}.onmicrosoft.com/${CurrentEnv.azureAD.signinFlowName}`,
        knownAuthorities: [`https://${CurrentEnv.azureAD.domain}.b2clogin.com`],
        redirectUri: CurrentEnv.baseUrl,
        // postLogoutRedirectUri: "/"
    },
    CurrentEnv.logMsalInfo
);

export default msalInstance;