import './sortabletabletitle.scss';

// Vue complains about null/undefined handler
const NoOp = () => false;

// TODO : pourrait etre mis dans shared lib, pour etre partage avec l'admin
const SortableTableTitle = (
    {
        alignRight,
        name,
        upSortClickHandler,
        downSortClickHandler
    }: {
        alignRight: boolean;
        name: string;
        upSortClickHandler: (() => void) | null; // null -> currently selected
        downSortClickHandler: (() => void) | null; // null -> currently selected
    }
) =>
    alignRight // On revert
        ? <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
            <div className='p-margin-left-7 p-vertical-center'>
                <div className={`p-arrow-up${!upSortClickHandler ? '' : ' p-clickable'} p-margin-bottom-7`}
                     onClick={upSortClickHandler || NoOp} />
                <div className={`p-arrow-down${!downSortClickHandler ? '' : ' p-clickable'}`}
                     onClick={downSortClickHandler || NoOp} />
            </div>
            <div>
                { name }
            </div>
        </div>
        : <div className='p-horizontal-center'>
            <div>
                { name }
            </div>
            <div className='p-margin-left-7 p-vertical-center'>
                <div className={`p-arrow-up${!upSortClickHandler ? '' : ' p-clickable'} p-margin-bottom-7`}
                     onClick={upSortClickHandler || NoOp} />
                <div className={`p-arrow-down${!downSortClickHandler ? '' : ' p-clickable'}`}
                     onClick={downSortClickHandler || NoOp} />
            </div>
        </div>;

export default SortableTableTitle;
