import {useQuery} from "@tanstack/react-query";
import {IPaginated} from "factor-lib/utils/graphQLPagination";
import {BuyersForAddInvoiceQueryKey} from "./InvoiceAddBuyerInfos";
import { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import BuyerSelection, { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import { formatCompanyInput } from "factor-lib/Company/companyUtils";
import { getFactorContext } from "../../IFactorContext";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import SingleSelect from "factor-lib/forms/Select/SingleSelect";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";
import ExistingPaginatedSelection from "../../utils/ExistingPaginatedSelection";


export const ExistingBuyerSelectorInputId = 'selectExistingBuyerInputId';

const InvoiceBuyerSelectionCheckExistingBuyers = (
    {
        className,
        queryKeysFactory,
        hasAnyQueryFn,
        queryFn,
        setBuyerSelection,
        setBuyerContactsInputs,
        companySearchRequest,
        autofocus,
        existingBuyerLabel,
        newBuyerLabel,
        newBuyerPlaceholder,
        placeholder
    }: {
        className?: string;
        queryKeysFactory: (baseKey: string[]) => string[];
        hasAnyQueryFn: ((
            signal: AbortSignal | undefined,
            queryKey: string[]
        ) => Promise<boolean>);
        queryFn: ((
            signal: AbortSignal | undefined,
            pageParam: any | null,
            searchInputStr: string | null,
            queryKey: string[]
        ) => Promise<IPaginated<IBuyerCompany>>);
        setBuyerSelection: (selection: IBuyerCompanySelection | null) => void;
        setBuyerContactsInputs: (newValue: ILineContactInput[]) => void;
        companySearchRequest: (inputCompanySearch: string) => Promise<ISearchCompaniesByNameResponse>;
        autofocus: boolean;
        existingBuyerLabel?: string;
        newBuyerLabel?: string;
        newBuyerPlaceholder: string;
        placeholder: string;
    }
) => {
    const factorContext = getFactorContext();

    const buyersQueryKey = queryKeysFactory(BuyersForAddInvoiceQueryKey);

    const buyersNonFilteredQueryResult =
        useQuery(
            // buyersQueryOption('', graphQLDataLoader)
            ({
                // enabled: false,
                queryKey: buyersQueryKey,
                queryFn: ({signal}) =>
                    hasAnyQueryFn(signal, buyersQueryKey)
            })
        );

    return (
        <ReactQueryResultWrapper result={buyersNonFilteredQueryResult}
                                 displayFullError={factorContext.debug}
                                 onLoading={() =>
                                     // <Loader/> Pas un loader pour éviter un 'saut' quand, dans le cas marketplace, on passe de disabled à -> loading -> puis loaded
                                     <BuyerSelection className={className}
                                                     displayFullError={factorContext.debug}
                                                     newBuyerPlaceholder={newBuyerPlaceholder}
                                                     companySearchRequest={companySearchRequest}
                                                     setBuyerSelection={setBuyerSelection}
                                                     autofocus={autofocus}
                                                     resetBuyer={() => {
                                                         setBuyerSelection(null);
                                                         setBuyerContactsInputs([{ email: '', phone: '' }]);
                                                     }}
                                                     existingBuyers={{
                                                         existingBuyerLabel,
                                                         newBuyerLabel,
                                                         SelectElement:
                                                             <SingleSelect options={[]}
                                                                           selectedOption={null}
                                                                           selectOption={null}
                                                                           placeholder={'' /* Non affiché */}
                                                                           isLoading={false} />

                                                     }} />
                                }
                                 onSuccess={(hasAnyBuyers: boolean) =>
                                     <BuyerSelection className={className}
                                                     displayFullError={factorContext.debug}
                                                     newBuyerPlaceholder={newBuyerPlaceholder}
                                                     companySearchRequest={companySearchRequest}
                                                     setBuyerSelection={setBuyerSelection}
                                                     autofocus={autofocus}
                                                     resetBuyer={() => {
                                                         setBuyerSelection(null);
                                                         setBuyerContactsInputs([{ email: '', phone: '' }]);
                                                     }}
                                                     existingBuyers={hasAnyBuyers
                                                         ? ({
                                                             existingBuyerLabel,
                                                             newBuyerLabel,
                                                             SelectElement:
                                                                 // (selectedBuyerOption: IOption<IBuyerCompany> | null, updateOption: (newOption: IOption<IBuyerCompany> | null) => void) =>
                                                                 <ExistingPaginatedSelection<IBuyerCompany> intputId={ExistingBuyerSelectorInputId}
                                                                                                            displayFullError={factorContext.debug}
                                                                                                            formatter={(b: IBuyerCompany) => formatCompanyInput(b.identifier, b.name)}
                                                                                                            setSelection={(selection: IBuyerCompany | null) =>
                                                                                                                setBuyerSelection(!!selection ? { existingIdO: selection?.id, newSirenO: null } : null)
                                                                                                            }
                                                                                                            placeholder={placeholder}
                                                                                                            queryKeyFactory={(searchInputStr) => [...BuyersForAddInvoiceQueryKey, 'search', searchInputStr]}
                                                                                                            queryFn={/* queryFn */ (signal: AbortSignal | undefined, pageParam: any | null, searchInputStr: string) =>
                                                                                                                queryFn(
                                                                                                                    signal,
                                                                                                                    pageParam,
                                                                                                                    searchInputStr,
                                                                                                                    [...BuyersForAddInvoiceQueryKey, 'search', searchInputStr]
                                                                                                                )
                                                                                                            }/>

                                                         }) : null
                                                     } />
                                 } />
    );
}

export default InvoiceBuyerSelectionCheckExistingBuyers;