import { useQueries, useQuery, UseQueryResult, UseInfiniteQueryResult } from "@tanstack/react-query";
import InvoicesTableDesktopTemplate from "./InvoicesTableDesktopTemplate";
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper'
import { IInvoicesHeader, invoicesHeaderQueryFn, InvoicesHeaderQueryKey } from "./Header";
import { buildMeQueriesConfig, IMeOutstandingLimit, IMeQueryProp, mergeQueriesData } from "../../utils/Me";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import { IInvoicesFiltersCount, invoicesFiltersCountQueryFn, InvoicesFiltersCountQueryKey } from "./FiltersCount";
import { getFactorContext } from "../../../IFactorContext";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {IDSInvoice, IInvoicesGraphQLQueryProviderParams} from "./FilteredInvoices";
import ProcessAll from "./InfiniteQueries";
import { IPaginated } from "factor-lib/utils/graphQLPagination";
import { InvoiceSortProperty } from "./invoicesSortProperties";
import { ISort } from "factor-lib/utils/sortingUtils";
import IActions from "./InvoiceList/actions/IActions";
import {ISeller} from "./FilteredInvoicesM";


export interface IFullData<M extends IMeOutstandingLimit> {
    header: IInvoicesHeader;
    filtersCount: IInvoicesFiltersCount;
    me: M;
}

const InvoicesTableDesktopContentPostRedirect = <
    I extends IDSInvoice,
    M extends IMeOutstandingLimit,
    R
>(
    {
        graphQLDataLoader,
        statusFilters,
        setStatusFilters,
        searchFilter,
        setSearchFilter,
        sort,
        setSort,

        meProps,
        actionsProvider,
        sellerExtractorO,
        graphQLQueryProvider,
        sorter
    }: {
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        statusFilters: number[];
        setStatusFilters: (newStatusFilters: number[]) => void;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;

        meProps: IMeQueryProp<any>[];
        actionsProvider: (me: M) => IActions<I, R>;
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        graphQLQueryProvider: (p: IInvoicesGraphQLQueryProviderParams) => string;
        sorter: (i1: I, i2: I) => number;
    }
) => {

    const factorContext = getFactorContext();

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            meProps
        )
    );

    // Does not rely on filters : we can already start the queries
    // Will be used redirect or not (trigger prefetch)
    const invoicesHeadersQuery: UseQueryResult<IInvoicesHeader> =
        useQuery<IInvoicesHeader>(
            InvoicesHeaderQueryKey,
            ({ signal }) => invoicesHeaderQueryFn(graphQLDataLoader, InvoicesHeaderQueryKey, signal)
        );

    // Filter counts
    const invoicesFiltersCountQuery: UseQueryResult<IInvoicesFiltersCount> =
        useQuery<IInvoicesFiltersCount>(
            InvoicesFiltersCountQueryKey,
            ({ signal }) => invoicesFiltersCountQueryFn(graphQLDataLoader, InvoicesFiltersCountQueryKey, signal)
        );

    const results: UseQueryResult<any>[] = [
        invoicesHeadersQuery,
        invoicesFiltersCountQuery,
        ...meQueries
    ];

    return (
        <ProcessAll<I> queryContext={{
                           invoicesGraphQLQueryProvider: graphQLQueryProvider,
                           graphQLDataLoader,
                           pageSize: 10, // TODO: ideally depend on screen size
                           sort: sort,
                           searchFilter
                       }}
                       statusFilters={statusFilters}
                       final={(invoicesQueries: UseInfiniteQueryResult<IPaginated<I>>[]) =>
                           <ReactQueryResultWrapper<IFullData<M>> displayFullError={factorContext.debug}
                                                                  result={reactQueryResultCombiner<IFullData<M>>(
                                                                      results,
                                                                      () => ({
                                                                          header: invoicesHeadersQuery.data!,
                                                                          filtersCount: invoicesFiltersCountQuery.data!,
                                                                          me: mergeQueriesData(meQueries)
                                                                      })
                                                                  )}
                                                                  onLoading={() =>
                                                                      <InvoicesTableDesktopTemplate<I, R> statusFilters={statusFilters}
                                                                                                          setStatusFilters={setStatusFilters}
                                                                                                          searchFilter={searchFilter}
                                                                                                          setSearchFilter={setSearchFilter}
                                                                                                          loaded={null}
                                                                                                          queries={invoicesQueries}
                                                                                                          sellerExtractorO={sellerExtractorO}
                                                                                                          sorter={sorter}
                                                                                                          actions={{
                                                                                                              canDeclareBuyerPaymentInvoiceO: null,
                                                                                                              canRequestFinancingO: null
                                                                                                          }}
                                                                                                          sort={sort}
                                                                                                          setSort={setSort} />
                                                                  }
                                                                  onSuccess={(t: IFullData<M>) =>
                                                                      <InvoicesTableDesktopTemplate<I, R> statusFilters={statusFilters}
                                                                                                          setStatusFilters={setStatusFilters}
                                                                                                          searchFilter={searchFilter}
                                                                                                          setSearchFilter={setSearchFilter}
                                                                                                          loaded={{
                                                                                                              header: t.header,
                                                                                                              filtersCount: t.filtersCount,
                                                                                                              outstandingLimit: t.me.outstandingLimit
                                                                                                          }}
                                                                                                          queries={invoicesQueries}
                                                                                                          sellerExtractorO={sellerExtractorO}
                                                                                                          sorter={sorter}
                                                                                                          actions={actionsProvider(t.me)}
                                                                                                          sort={sort}
                                                                                                          setSort={setSort} />

                                                                  } />
                       }/>
    );
};

export default InvoicesTableDesktopContentPostRedirect;
