import Link from "factor-lib/Link";
import { IBuyer } from "./BuyersTableDesktopQuery";
import { PendingEligibilityMessage } from "../CommonMessages";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { invoiceAddForBuyerUrl } from "../../InvoiceAdd/invoiceAddUrl";
import { formatAmount } from "factor-lib/utils/amountUtils";
import BotToolTip from "../BotToolTip";

const BuyersRow = (
    {
        buyer,
        setModifyAskedOutstanding
    }: {
        buyer: IBuyer;
        setModifyAskedOutstanding: () => void;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    return (
        <tr>
            <td style={{textTransform: 'uppercase'}}>
                {/* TODO : resuse React router link */}
                <Link id={`buyer-${buyer.id}-link`}
                      text={buyer.name}
                      action={{
                          clickHandler: () => navigate(invoiceAddForBuyerUrl(buyer.id))
                          // href: invoiceAddForBuyerRoute(buyer.id)
                      }} />

            </td>
            <td align='right'>
                <BotToolTip closeOnLeave={true}
                            toolTipMargin={6}
                            buttonNode={
                                <Link id={`buyer-${buyer.id}-asked-modal`}
                                      text={(buyer.askedOutstanding !== null && buyer.askedOutstanding !== undefined )
                                          ? formatAmount(buyer.askedOutstanding)
                                          : 'Pas d\'encours demandé'}
                                      action={{
                                          clickHandler: setModifyAskedOutstanding
                                      }} />
                            } >
                    <div className='p-tooltip-text'>
                        Cliquez pour modifier l’encours demandé sur ce client
                    </div>
                </BotToolTip>
                
            </td>
            <td align='right'>
                { formatAmount(buyer.outstandings.outstanding) }
                &nbsp;/&nbsp;
                { buyer.outstandings.outstandingLimit !== null
                    ? formatAmount(buyer.outstandings.outstandingLimit)
                    : !!buyer.outstandings.nonPending?.notEligibleReason
                        ? 'Non disponible'
                        : 'En cours de revue'
                }
            </td>
            <td align='right'>
                { buyer.financedCount }
            </td>
            <td>
                { !!buyer.outstandings.nonPending
                    ? buyer.outstandings.nonPending.notEligibleReason !== null
                        ? <span><span className='p-danger-text p-bold'>Non éligible</span> ({buyer.outstandings.nonPending.notEligibleReason})</span>
                        : <span className='p-ok-text p-bold'>Éligible</span>
                    : <span>{ PendingEligibilityMessage }</span>
                }
            </td>
        </tr>
    );
};

export default BuyersRow;