import './TopBarUserDropDown.scss';
import msalInstance from "../msalInstance";

const TopBarUserDropDown = () =>
    <div className='p-padding-6'>
        <div className='logout'
             onClick={() => msalInstance.logoutRedirect({
                 // account: ensureOneAuthUser(filterAccountOnEnvironment(accounts, CurrentEnv.azureAD.domain))
             })}>
            Se déconnecter
        </div>
    </div>;

export default TopBarUserDropDown;
