import IAccessTokens from "../../Auth/IAccessTokens";
import { Navigate, useParams} from "react-router-dom";
import Loader from 'factor-lib/Loader';
import ICustomerType from "../../ICustomerType";
import { InvoiceDetailsUrlInvoiceIdParamName } from "./invoiceDetailsUrl";
import { isValidGuid } from "factor-lib/utils/guidUtils";
import { DashboardInvoicesDefaultRoute } from "../Dashboard/dashboardUrlsConsts";
import InvoiceDetailsPagePostRedirectDS from "./InvoiceDetailsPagePostRedirectDS";
import InvoiceDetailsPagePostRedirectM from "./InvoiceDetailsPagePostRedirectM";

const InvoiceDetailsPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessTokens | null;
    }
) => {
    const invoiceId = useParams()[InvoiceDetailsUrlInvoiceIdParamName];

    return (
        !invoiceId || !isValidGuid(invoiceId)
            ? <Navigate to={DashboardInvoicesDefaultRoute}
                        replace={true} />
            : !accessTokenO
                ? <Loader />
                : accessTokenO.customerType === ICustomerType.DirectSeller
                    ? <InvoiceDetailsPagePostRedirectDS invoiceId={invoiceId}
                                                        directSellerAxios={accessTokenO.customerSpecificAxios} />
                    : accessTokenO.customerType === ICustomerType.Marketplace
                        ? <InvoiceDetailsPagePostRedirectM invoiceId={invoiceId}
                                                           marketplaceAxios={accessTokenO.customerSpecificAxios} />
                        : null /* TODO */
    );
}

export default InvoiceDetailsPage;
