import {IDSInvoice} from "../FilteredInvoices";
import {computeInvoiceDescAndAction, ICanRequestFinance} from "../IInvoiceDescAndAction";
import BotToolTip from "../../BotToolTip";

import Link from "factor-lib/Link";
import Button, { SIZE_COMPACT } from "factor-lib//Buttons/Button";

import InvoiceRowAmount from "./InvoiceRowAmount";

import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

import {formatAmount } from "factor-lib/utils/amountUtils";
import { serverDateDeserialization, userFormatDate } from "factor-lib/utils/dateUtils";
import {invoiceDetailsUrl} from "../../../InvoiceDetails/invoiceDetailsUrl";
import { canDeclareBuyerPayment } from "../../../utils/delcareBuyerPaymentUtils";

import './tablerow.scss';
import {ISeller} from "../FilteredInvoicesM";

export const fullButtonId =
    (buttonPrefix: string, invoiceOrDraftId: string) => `${buttonPrefix}-${invoiceOrDraftId}`;

export const invoiceNumberFormat = (invoiceNumber: string) =>
    `#${invoiceNumber}`;

const InvoiceRow = <I extends IDSInvoice,>(
    {
        invoice,
        sellerExtractorO,
        setCurrentDeclareBuyerPaymentO,
        canRequestFinanceO
    }: {
        invoice: I;
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        setCurrentDeclareBuyerPaymentO: (() => void) | null;
        canRequestFinanceO: ICanRequestFinance | null;
    }
) => {
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const { action, daysComment } = computeInvoiceDescAndAction(invoice, canRequestFinanceO);

    // TODO : display requestFinancingMutation.error

    //     commentNode: null /* PRED-1708 : no tooltip for now (!!a.comment && (
    //                   <TopQuestionMarkTooltip>
    //                       <div style={{width: '150px'}}>
    //                           { a.comment }
    //                       </div>
    //                   </TopQuestionMarkTooltip>
    //               )) || null */

    return (
        <tr>
            <td>
                {/* TODO : reuse react router link */}
                <Link id={`invoice-${invoice.number}`}
                      action={{
                          clickHandler: () => navigate(invoiceDetailsUrl(invoice.id)),
                          // href: invoiceDetailsRoute(invoice.id)

                      }}
                      text={invoiceNumberFormat(invoice.number)}
                />
            </td>
            {!!sellerExtractorO &&
                <td style={{textTransform: 'uppercase'}}>
                    { sellerExtractorO(invoice).friendlyName }
                </td>
            }
            <td style={{textTransform: 'uppercase'}}>
                { invoice.buyer.name }
            </td>
            <td>
                <div>
                  { userFormatDate(serverDateDeserialization(invoice.dueDate)) }
                </div>
                { !!daysComment &&
                    <div className='p-bold'> { daysComment } </div>
                }
            </td>
            <td align='right' className='minSize p-size-4'>
                { formatAmount(invoice.amountWoTax) }
            </td>
            <td align='right' style={{paddingLeft: '1em', paddingRight: '1em', paddingTop: 0, paddingBottom: 0}}
                className='minSize p-size-4'>
                <InvoiceRowAmount invoice={invoice}/>
            </td>
            <td style={{paddingLeft: '1em', paddingRight: '1em'}}>
                <div className='p-both-center'>
                    { !!action.button
                        ? <Button id={fullButtonId(action.button.preId, invoice.id)}
                                  text={action.button.text}
                                  size={SIZE_COMPACT}
                                  isLoading={action.button.isLoading}
                                  className='is-paddingless'
                                  kind={action.button.kind}
                                  actionO={action.button!.action} />
                        : (!!action.labels && (
                             <div className='p-ok-text p-bold' style={{textAlign: 'center'}}>
                                 { action.labels.map((l, lIndex) => (
                                     <div key={`s-${lIndex}`}> { l } </div>
                                 )) }
                            </div>
                        )) || null
                    }
                </div>
            </td>
            {/*<td className='minSize' style={{padding: '0 !important', width: '17px !important'}}>*/}
            {/*    { !!invoiceDescAndAction.commentNode &&*/}
            {/*        <div style={{*/}
            {/*              // I cannot find an easier solution to smooth the extra gap which I do not know where it comes from*/}
            {/*                    display: 'flex'*/}
            {/*                }} >*/}
            {/*            { invoiceDescAndAction.commentNode }*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</td>*/}
            <td>
                { (!!setCurrentDeclareBuyerPaymentO &&
                    canDeclareBuyerPayment(
                        // we can ignore : invoice.payment.completePaidDate (which is amount paid to Dimpl), which would be included in settled
                        invoice.status?.financingRequest?.accepted?.settledDateTime || null,
                        invoice.amountWoTax + invoice.amountTax,
                        invoice.payment.partialAmountPaidByBuyer
                    ) &&
                    <BotToolTip closeOnLeave={true}
                                toolTipMargin={7}
                                buttonNode={
                                    <div className='p-3-dots p-complement-message'>&#8942;</div>
                                }>
                        <div className='p-tooltip-text'>
                            <div className='level'
                                 id='invoicesDashboardPaid'
                                 onClick={setCurrentDeclareBuyerPaymentO}
                            >
                                <div className='level-left p-margin-6 p-clickable'>
                                    <div className='euro level-item'>€</div>
                                    <div className='buyerPaidText p-bold level-item'>
                                        Signaler un paiement direct
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BotToolTip>
                ) || null }
            </td>
        </tr>
    );
};

export default InvoiceRow;
