import {ReactNode} from "react";

import './card.scss';

const Card = (
    {
        id,
        className,
        childrenClassName,
        titleLeftSlot,
        titleRightSlot,
        children,
        footerSlot
    }: {
        id?: string;
        className?: string;
        childrenClassName?: string;
        titleLeftSlot?: ReactNode;
        titleRightSlot?: ReactNode;
        children: ReactNode;
        footerSlot?: ReactNode;
    }
) =>
    <div id={id}
         className={`p-border-small${!!className ? ` ${className}` : ''}`}>
        <div className='p-box-header p-padding-left-4 p-padding-top-5 p-padding-bottom-5 p-padding-right-4'>
            { !!titleRightSlot
                ? <div className='p-nc-header'>
                    { titleLeftSlot }
                    <div className='p-no-flex p-margin-left-6'>
                        { titleRightSlot }
                    </div>
                </div> : titleLeftSlot
            }
        </div>
        <div className='p-card-title-separator'/>
        <div className={`p-nc-content${!!childrenClassName ? ` ${childrenClassName}` : ''}`}>
            { children }
        </div>
        { footerSlot }
    </div>

export default Card;
