import { sum } from 'factor-lib/utils/utils';
import IOption from 'factor-lib/forms/Select/IOption';
import MultipleSelect from 'factor-lib/forms/Select/MultipleSelect';
import { InvoicesStatusFilters } from "./StatusFilters";
import { IInvoicesFiltersCount } from "./FiltersCount";
import { DebounceInput } from 'react-debounce-input';
import {ChangeEvent} from "react";

export const SearchText = 'Rechercher';

const InvoicesFilter = (
    {
        className,
        statusFilters,
        setStatusFilters,
        searchFilter,
        setSearchFilter,
        filtersCountLoadedO
    }: {
        className?: string;
        statusFilters: number[];
        setStatusFilters: (newValue: number[]) => void;
        searchFilter: string;
        setSearchFilter: (newValue: string) => void;
        filtersCountLoadedO: IInvoicesFiltersCount | null;
    }
) => {
    const statusFiltersOptions: IOption<number>[] =
        InvoicesStatusFilters.map((filter, filterIndex) => {
            return ({
                value: filterIndex,
                label: `${filter.label} ${!!filtersCountLoadedO
                    ? ` (${
                        sum(filter.computations.map((r) => r.countExtractor(filtersCountLoadedO)))
                    })`
                    : '...'
                }`
            });
        });
    
    const statusFiltersSet: Set<number> = new Set(statusFilters);

    const selectedStatusFiltersOptions: IOption<number>[] =
        statusFiltersOptions.filter((o) => statusFiltersSet.has(o.value));

    return (
        <div className={`level${!!className ? ` ${className}` : ''}`}>
            <div className='level-left'>
                <div>
                    Filtre :
                </div>
                <div className='p-margin-left-5'>
                    <div style={{minWidth: '400px'}}>
                        <MultipleSelect options={statusFiltersOptions}
                                        selectedOptions={selectedStatusFiltersOptions}
                                        setSelectedOptions={(options) => setStatusFilters(options.map((o) => o.value))}
                                        clearable={false}
                                        searchable={false} />
                    </div>
                </div>
            </div>
            <div className='level-right'>
                <DebounceInput className='p-search-box input'
                               minLength={0}
                               value={searchFilter}
                               debounceTimeout={500}
                               placeholder={SearchText}
                               onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFilter(e.target.value)} />
            </div>
        </div>
    );
}

export default InvoicesFilter;
