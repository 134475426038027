import {useInfiniteQuery, UseInfiniteQueryResult} from "@tanstack/react-query";
import {useDebounce} from "use-debounce";
import {useState} from "react";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import {IPaginated} from "factor-lib/utils/graphQLPagination";
import PaginatedSelect, {queryOptions} from 'factor-lib/forms/Select/PaginatedSelect';



// we display the select even if the filtered query is still loading
// the default (non filtered) query is loaded

const ExistingPaginatedSelection = <T, >(
    {
        intputId,
        setSelection,
        formatter,
        placeholder,
        queryKeyFactory,
        queryFn,
        displayFullError
    }: {
        intputId?: string;
        setSelection: (selection: T | null) => void;
        formatter: (e: T) => string;
        placeholder: string;
        queryKeyFactory: (searchInputStr: string) => string[];
        queryFn: (signal: AbortSignal | undefined, pageParam: any | null, searchInputStr: string) => Promise<IPaginated<T>>;
        displayFullError: boolean;
    }
) => {
    const [displayedInput, setDisplayedInput] = useState<string>('');
    const [debouncedInput] = useDebounce(displayedInput, 500);

    // if the input is empty, we ignore the debounce (for example user closed the menu, or cleared the input)
    const effectiveInput: string = displayedInput !== '' ? debouncedInput : '';

    // at first, always fetched because its the same as the non filtered query
    const queryResult: UseInfiniteQueryResult<IPaginated<T>> = useInfiniteQuery(queryOptions(
        // effectiveInput,
        queryKeyFactory(effectiveInput),
        (signal: AbortSignal | undefined, pageParam: any | null) => queryFn(signal, pageParam, effectiveInput)
    ));

    return (
        <ReactQueryResultWrapper result={queryResult}
                                 displayFullError={displayFullError}
                                 onLoading={() =>
                                     <PaginatedSelect<T> inputId={intputId}
                                                         effectiveInput={effectiveInput}
                                                         displayedInput={displayedInput}
                                                         setSelection={setSelection}
                                                         loaded={null}
                                                         onInputChange={setDisplayedInput}
                                                         formatter={formatter}
                                                         placeholder={placeholder} />
                                 }
                                 onSuccess={(data, isIncrementalLoading) =>
                                     <PaginatedSelect<T> inputId={intputId}
                                                         effectiveInput={effectiveInput}
                                                         displayedInput={displayedInput}
                                                         setSelection={setSelection}
                                                         loaded={{
                                                             data,
                                                             isIncrementalLoading,
                                                             fetchNextPage: () => queryResult.fetchNextPage()
                                                         }}
                                                         onInputChange={setDisplayedInput}
                                                         formatter={formatter}
                                                         placeholder={placeholder} />

                                 }/>
    );
}

export default ExistingPaginatedSelection;