import Card from "../../utils/Card";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import InvoiceUploadAdditionalFile, {IInvoiceAdditionalFile} from "./InvoiceUploadAdditionalFile";
import InvoiceAddBaseInfos, {
    IInvoiceBaseInfosInput,
    IInvoiceBaseInfosInputEnabled
} from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import Upload from "factor-lib/forms/Upload/Upload";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";

export const additionalFileId = (index: number): string  => `additionalFile${index}`;

const ExtraInformationCard = (
    {
        baseInfosInput,
        baseInfosInputEnabled,
        invoiceAdditionalFiles,
        setInvoiceAdditionalFiles,
        axiosAction
    }: {
        baseInfosInput: IInvoiceBaseInfosInput;
        baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled;
        invoiceAdditionalFiles: IInvoiceAdditionalFile[];
        setInvoiceAdditionalFiles: (newFiles: IInvoiceAdditionalFile[]) => void;
        axiosAction: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<string>>;
    }
) =>
    <Card id='addInvoiceBaseInfos'
          className='p-margin-top-5'
          childrenClassName='p-padding-4'
          titleLeftSlot={<div className='p-bold'>Détails de la facture</div>}>

        <InvoiceAddBaseInfos input={baseInfosInput}
                             enabled={baseInfosInputEnabled} />

        { invoiceAdditionalFiles
            .map((file, index) =>
                <div key={`index${index}`} className='p-margin-top-5'>
                    <div className='p-margin-bottom-7 p-form-label'>
                        Document justificatif n° {index + 1}
                    </div>
                    <Upload id={additionalFileId(index)}
                            acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                            externalInitialErrors={null}
                            existingFileName={file.fileName}
                            actionO={null} />
                </div>
            )
        }

        { invoiceAdditionalFiles.length < 5 &&
            <div className='p-margin-top-5'>
                <div className='p-margin-bottom-7 p-form-label'>
                    Justificatif (bon de livraison, devis...)
                </div>
                <InvoiceUploadAdditionalFile // directSellerAxios={directSellerAxios}
                                             axiosAction={axiosAction}
                                             setNewInvoiceAdditionalFile={(a) =>
                                                 setInvoiceAdditionalFiles([...invoiceAdditionalFiles, a])
                                             }/>
            </div>
        }
    </Card>;

export default ExtraInformationCard;