import { InvoicesBaseQueryKey } from "./AllInvoicesQuery";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";

export const InvoicesFiltersCountQueryKey = [InvoicesBaseQueryKey, 'filtersCount'];

const nbGraphQLFields = 'nb';

interface INb {
    nb: number;
}

export interface IInvoicesFiltersCount {
    eligible: number;
    notEligible: number;
    pending: number;
    financingRequested: number;
    financingRequestRejected: number;
    financingActive: number;
    financingSettled: number;
}

const invoicesStatusCountQuery: IGraphQLParams =
    {
        query: `query {
            invoicesSummary {
                eligible { ${nbGraphQLFields} }
                notEligible { ${nbGraphQLFields} }
                pending { ${nbGraphQLFields} }
                financingRequestRejected { ${nbGraphQLFields} }
                financingRequested { ${nbGraphQLFields} }
                financingActive { ${nbGraphQLFields} }
                financingSettled { ${nbGraphQLFields} }
            }
        }`
    };

interface IInvoicesFiltersCountQueryResult {
    invoicesSummary: {
        eligible: INb;
        notEligible: INb;
        pending: INb;
        financingRequestRejected: INb;
        financingRequested: INb;
        financingActive: INb;
        financingSettled: INb;
    };
}

export const invoicesFiltersCountQueryFn = async (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    queryKey: string[],
    signal?: AbortSignal
): Promise<IInvoicesFiltersCount> => {
    const { invoicesSummary } = await graphQLDataLoader.load({
        query: invoicesStatusCountQuery,
        queryKey,
        signal
    }) as IInvoicesFiltersCountQueryResult;
    return ({
        eligible: invoicesSummary.eligible.nb,
        notEligible: invoicesSummary.notEligible.nb,
        pending: invoicesSummary.pending.nb,
        financingRequested: invoicesSummary.financingRequested.nb,
        financingRequestRejected: invoicesSummary.financingRequestRejected.nb,
        financingActive: invoicesSummary.financingActive.nb,
        financingSettled: invoicesSummary.financingSettled.nb,
    });
}
