import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import {useState} from "react";
import {Axios} from "axios";
import CompanySearcher from "factor-lib/CompanySearcher/CompanySearcher";
import {buyerCompanySearchAsync} from "./buyerCompanySearch";
import {getFactorContext} from "../IFactorContext";

export interface IBuyerInputEnabled {
    autofocus: boolean;
    updateSiren: (siren: string | null) => void;
}

const BuyerSearcher = (
    {
        displayFullError,
        customerAxios,
        directSellerAxios,
        enabled
    }: {
        displayFullError: boolean;
        customerAxios: Axios,
        directSellerAxios: Axios,
        enabled: IBuyerInputEnabled | null;
    }
) => {
    const [buyerSearchInput, setBuyerSearchInput] = useState('');
    return (
        <CompanySearcher displayFullError={displayFullError}
                         placeHolder='Nom ou SIREN de votre société cliente'
                         searchInput={buyerSearchInput}
                         setSearchInput={setBuyerSearchInput}
                         companySearchRequest={async (inputCompanySearch: string): Promise<ISearchCompaniesByNameResponse> =>
                             buyerCompanySearchAsync(
                                 inputCompanySearch,
                                 directSellerAxios,
                                 customerAxios,
                                 getFactorContext().logger
                             )
                         }
                         autofocus={!!enabled && enabled.autofocus}
                         setSiren={(siren) => {
                             // Ne devrait pas etre fait en amont ?
                             if (!!enabled) {
                                 enabled.updateSiren(siren);
                             }
                         }} />
    );
};

export default BuyerSearcher;
