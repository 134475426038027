import SummaryItem from "./SummaryItem";
import {CSSProperties, ReactNode} from "react";
import Loader from "factor-lib/Loader";
import Button from "factor-lib/Buttons/Button";


const WithOverviewSection = (
    {
        style,
        summariesItems,
        topRightActionO, // right node button action
        children
    }: {
        style?: CSSProperties;
        // En pratique, les value sont null ou non-nulls en meme temps
        summariesItems: Array<{
            legend: string;
            value: string | null;
        }>;
        topRightActionO: {
            id: string;
            label: string;
            action: () => void;
        } | null;
        children: ReactNode;
    }
) =>
    <div className='is-hidden-mobile p-vertical-stretch p-full-height'
         style={!!style ? ({...style, overflowX: 'auto'}) : { overflowX: 'auto' }}
    >
        <div className='p-border-down p-no-flex'>
            <div className='level'>
                <div className='level-left'>
                    { summariesItems.map((si, siIndex) =>
                        <SummaryItem className='level-item'
                                     key={`si-${siIndex}`}
                                     legend={si.legend}
                                     value={si.value ?? <Loader/>}
                                     hasRightBorder={true /* for now, always */} />
                    )}
                </div>

                { !!topRightActionO &&
                    <div className='level-right'>
                        <Button id={topRightActionO.id}
                                className='p-margin-right-4'
                                text={topRightActionO.label}
                                isLoading={false}
                                actionO={topRightActionO.action}/>
                    </div>
                }
            </div>
        </div>
        <div className='p-background p-default-flex-item p-padding-left-3 p-padding-right-3'>
            { children }
        </div>
    </div>;

export default WithOverviewSection;