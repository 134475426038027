import {BuyerGraphQLQuerySelection, BuyersQueryKey, IBuyer} from "./BuyersTableDesktopQuery";
import BuyersTableDesktopTemplate from "./BuyersTableDesktopTemplate";
import { Axios } from "axios";
import {
    UseInfiniteQueryOptions,
    UseQueryResult,
    useInfiniteQuery,
    useQueries,
    UseInfiniteQueryResult
} from "@tanstack/react-query";
import {
    IMeCompanyIdentifier, IMeOutstanding, IMeOutstandingLimit,
    MeCompanyIdentifierProp, MeOutstandingLimitProp, MeOutstandingProp,
    buildMeQueriesConfig,
    mergeQueriesData
} from "../../utils/Me";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import DataLoader from "dataloader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {getFactorContext} from "../../../IFactorContext";
import { IPaginated, query } from "factor-lib/utils/graphQLPagination";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import { BuyerSortProperty, BuyerSortPropertyToOrderByField } from "./buyersSortProperties";
import { ISort, SortOrderToOrderByDirection } from "factor-lib/utils/sortingUtils";

type IMe = IMeCompanyIdentifier & IMeOutstanding & IMeOutstandingLimit;

const buyersInfiniteQueryOptions = (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    searchFilter: string,
    sort: ISort<BuyerSortProperty>
): UseInfiniteQueryOptions<IPaginated<IBuyer>> => {
    const queryKey = [...BuyersQueryKey, sort.property, sort.order, 'search', searchFilter];

    return ({
        queryKey,
        queryFn: async ({signal, pageParam = null /* Why default ? */}) => {

            const variables: Record<string, any> = {
                first: 10,
                search: !!searchFilter ? searchFilter : null,
                orderByField: BuyerSortPropertyToOrderByField.get(sort.property)!,
                orderByDirection: SortOrderToOrderByDirection.get(sort.order)!,
                after: pageParam
            };

            const r: any = await graphQLDataLoader.load({
                query: {
                    query: `query ($first: Int!, $after: PaginationGuidCursor, $search: String, $orderByField: BuyerPaginationOrderByField!, $orderByDirection: PaginationOrderByDirection!) {
                        buyers (search: $search) {
                            list (
                                first: $first,
                                after: $after,
                                orderByField: $orderByField,
                                orderByDirection: $orderByDirection
                            ) ${query(BuyerGraphQLQuerySelection)}
                        }
                    }`,
                    variables
                },
                queryKey,
                signal
            });

            return r.buyers.list as IPaginated<IBuyer>;
        },
        getNextPageParam: (lastPage, _) => lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined
    });
};

const BuyersTableDesktopContentPostRedirect = (
    {
        customerAxios,
        directSellerAxios,
        searchFilter,
        setSearchFilter,
        sort,
        setSort
    }: {
        customerAxios: Axios;
        directSellerAxios: Axios;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        sort: ISort<BuyerSortProperty>;
        setSort: (newSort: ISort<BuyerSortProperty>) => void;
    }
) => {
    const factorContext = getFactorContext();

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
            directSellerAxios,
            factorContext.logger,
            graphQLQueries
        )
    );

    const buyersQueryResult: UseInfiniteQueryResult<IPaginated<IBuyer>> = useInfiniteQuery<IPaginated<IBuyer>>(
        buyersInfiniteQueryOptions(
            graphQLDataLoader,
            searchFilter,
            sort
        )
    );

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            [MeCompanyIdentifierProp, MeOutstandingProp, MeOutstandingLimitProp]
        )
    );

    return (
        <ReactQueryResultWrapper result={reactQueryResultCombiner<IMe>(
                                     meQueries,
                                     () => mergeQueriesData(meQueries)
                                 )}
                                 displayFullError={factorContext.debug}
                                 onLoading={() =>
                                     <BuyersTableDesktopTemplate customerAxios={customerAxios}
                                                                 directSellerAxios={directSellerAxios}
                                                                 searchFilter={searchFilter}
                                                                 setSearchFilter={setSearchFilter}
                                                                 loaded={null}
                                                                 buyersQueryResult={buyersQueryResult}
                                                                 sort={sort}
                                                                 setSort={setSort} />
                                 }
                                 onSuccess={(me: IMe) =>
                                     <BuyersTableDesktopTemplate customerAxios={customerAxios}
                                                                 directSellerAxios={directSellerAxios}
                                                                 searchFilter={searchFilter}
                                                                 setSearchFilter={setSearchFilter}
                                                                 loaded={{
                                                                     sellerIdentifier: me.companyIdentifier,
                                                                     sellerOutstanding: me.outstanding,
                                                                     sellerOutstandingLimit: me.outstandingLimit
                                                                 }}
                                                                 buyersQueryResult={buyersQueryResult}
                                                                 sort={sort}
                                                                 setSort={setSort} />
                                 } />
    );
}

export default BuyersTableDesktopContentPostRedirect;
