
export const GLOBAL_CLICK_EVENT = 'global-click';

// Inspired from https://www.pluralsight.com/guides/how-to-communicate-between-independent-components-in-reactjs
const EventBus = {
    on(event: string, callback: (data?: any) => void) {
        document.addEventListener(event, (e) => callback((e as CustomEvent).detail));
    },

    dispatch(event: string, data?: any) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },

    remove(event: string, callback: (data?: any) => void) {
        document.removeEventListener(event, callback);
    },
}

export default EventBus;
