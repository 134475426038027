import {IDSInvoice} from "./FilteredInvoices";

export const GraphQLSellerSelection =
    `seller { 
        id friendlyName
        cannotFinanceReason
    } `;

export interface ISeller {
    id: string;
    friendlyName: string;
    cannotFinanceReason: string | null;
}

export interface IWithSellerInvoice extends IDSInvoice {
    seller: ISeller;
}