import { useState } from "react";
import BuyerAddModal from "./BuyerAddModal";
import { Axios } from "axios";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import WithOverviewSection from "../WithOverviewSection";
import { formatAmountWoCents } from "factor-lib/utils/amountUtils";
import BuyersFilter from "./BuyersFilter";
import BuyersList from "./BuyersList";
import {ISort} from "factor-lib/utils/sortingUtils";
import {BuyerSortProperty} from "./buyersSortProperties";
import {UseInfiniteQueryResult} from "@tanstack/react-query";
import {IPaginated} from "factor-lib/utils/graphQLPagination";
import {IBuyer} from "./BuyersTableDesktopQuery";

export const AddBuyerButtonText = 'Ajouter un client';

const BuyersTableDesktopTemplate = (
    {
        customerAxios,
        directSellerAxios,
        searchFilter,
        setSearchFilter,
        loaded,
        buyersQueryResult,
        sort,
        setSort
    }: {
        customerAxios: Axios;
        directSellerAxios: Axios;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        loaded: {
            sellerIdentifier: IIntCompanyIdentifier;
            sellerOutstanding: number;
            sellerOutstandingLimit: number;
        } | null;
        buyersQueryResult: UseInfiniteQueryResult<IPaginated<IBuyer>>;
        sort: ISort<BuyerSortProperty>;
        setSort: (newSort: ISort<BuyerSortProperty>) => void;
    }
) => {

    const [showAddBuyerModal, setShowAddBuyerModal] = useState(false);

    return (
        <WithOverviewSection summariesItems={[{
                                 legend: 'Votre encours / Limite',
                                 value: !!loaded ? `${formatAmountWoCents(loaded.sellerOutstanding)} / ${formatAmountWoCents(loaded.sellerOutstandingLimit)}` : null
                             }]}
                             topRightActionO={{
                                 id: 'add-buyer-button',
                                 label: AddBuyerButtonText,
                                 action: () => setShowAddBuyerModal(true)
                             }}>

            <BuyersFilter className='p-margin-top-4'
                          searchFilter={searchFilter}
                          setSearchFilter={setSearchFilter} />

            <BuyersList directSellerAxios={directSellerAxios}
                        query={buyersQueryResult}
                        sort={sort}
                        setSort={setSort}/>

            { !!loaded /* use could have clicked the button but waiting for loaded to show modal, weird */ &&
                showAddBuyerModal &&
                <BuyerAddModal customerAxios={customerAxios}
                               directSellerAxios={directSellerAxios}
                               sellerIdentifier={loaded.sellerIdentifier}
                               buyersQueryResult={buyersQueryResult}
                               closeModal={() => setShowAddBuyerModal(false)} />
            }
        </WithOverviewSection>
    );
};

export default BuyersTableDesktopTemplate;
