import { ChangeEvent } from 'react';
import { DebounceInput } from 'react-debounce-input';

export const SearchText = 'Rechercher';

const BuyersFilter = (
    {
        className,
        searchFilter,
        setSearchFilter
    }: {
        className?: string;
        searchFilter: string;
        setSearchFilter: (newValue: string) => void;
    }
) =>
    <div className={`level${!!className ? ` ${className}` : ''}`}>
        <div className='level-left'/>
        <div className='level-right'>
            <DebounceInput className='p-search-box input'
                           minLength={0}
                           value={searchFilter}
                           debounceTimeout={500}
                           placeholder={SearchText}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFilter(e.target.value)} />
        </div>
    </div>;

export default BuyersFilter;
