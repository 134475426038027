import Button from "factor-lib/Buttons/Button";
import {KIND_PRIMARY, SIZE_FIXED } from "factor-lib//Buttons/Button";
import {useContext, useState} from "react";
import Modal from "factor-lib/Modal";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { getFactorContext } from "../../IFactorContext";
import { computeText } from "factor-lib/Buttons/ButtonMutationEnabled";
import {addInvoiceUpdateQueriesAsync, IInvoiceAddDSParams, INotEligibleReason} from "./invoiceAddUtils";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { CallWithDimplScoreInBackTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import { extractFullErrorMessageNonDefault } from "factor-lib/serverUtils/extractAxiosErrorMessage";
import InvoiceAddResultModal from "./InvoiceAddResultModal";
import TextOnlyModal from "factor-lib/Modals/TextOnlyModal";
import {NavigateFunction} from "react-router-dom";
import {DashboardInvoicesDefaultRoute} from "../Dashboard/dashboardUrlsConsts";
import {BuyerFinancingImpact, IFinancingImpact, IInvoicesWhichBecomeUneligible} from "../InvoiceFinancingImpact";

export const InvoiceAddSectionId = 'invoiceAddSection';
const InvoiceAddButtonId = 'invoiceAddButton';
const InvoiceAddButtonKind = KIND_PRIMARY;
const InvoiceAddButtonSize = SIZE_FIXED;

export const InvoiceAddButtonMessage = 'Ajouter';

export interface IInvoiceAddResponse<I> {
    invoiceId: string;
    eligible: boolean | null; // null -> pending
    notEligibleReason: INotEligibleReason | null;
    financingImpact: I | null; // Only when eligible
}

export interface IInvoiceAddResponseNotDuplicateO<I> {
    nonDuplicateO: IInvoiceAddResponse<I> | null;
}

interface IInvoiceAddResponsWithAmountTTC<I> {
    response: IInvoiceAddResponse<I>;
    invoiceAmountTTC: number;
}

const addInvoiceRequestAsync = async<I, P> (
    baseAxios: (params: P, config: AxiosRequestConfig) => Promise<AxiosResponse<IInvoiceAddResponse<I>>>,
    params: P
): Promise<IInvoiceAddResponseNotDuplicateO<I /* IInvoiceAddResponse */>> => {
    try {
        return ({
            nonDuplicateO: (await baseAxios(
                params,
                {timeout: CallWithDimplScoreInBackTimeoutMilliseconds}
            )).data
        })
    } catch (error: any) {
        const message: string = extractFullErrorMessageNonDefault(error);
        if (message.startsWith('Facture existe déjà :')) {
            return ({
                nonDuplicateO: null
            })
        } // else
        throw error;
    }
};

const RejectionMessageModal = (
    {
        className,
        message,
        closeModal
    }: {
        className?: string;
        message: string;
        closeModal: () => void;
    }
) =>
    <Modal id='rejectionMessageModal'
           className={className}
           maxWidth={null}
           fullMaxWidth={false}
           close={closeModal} >
        <div className='p-padding-4 p-vertical-center'>
            <div className='p-horizontal-center p-margin-bottom-5'>
                <div className='p-error-icon p-margin-right-6'>!</div>
                <div>
                    { message }
                </div>
            </div>

            <Button id='rejectionMessageModalButton'
                    text='Ok'
                    isLoading={false}
                    actionO={closeModal} />
        </div>
    </Modal>;

const InvalidParamsMessagesModal = (
    {
        className,
        invalidParamsMessages,
        closeModal
    }: {
        className?: string;
        invalidParamsMessages: string[];
        closeModal: () => void;
    }
) =>
    <Modal id='invalidParamsModal'
           className={className}
           active={true}
           maxWidth={null}
           fullMaxWidth={false}
           close={closeModal}>
        <div className='p-padding-top-3 p-padding-bottom-4 p-padding-left-4 p-padding-right-4 p-vertical-center'>
            Le formulaire contient une ou plusieurs erreurs :

            <ul className='p-margin-top-5 p-margin-bottom-4 p-margin-left-4 p-margin-right-4' style={{listStyle: 'circle'}}>
                { invalidParamsMessages.map((ip, ini) =>
                    <li key={`i-${ini}`}>
                        { ip }
                    </li>
                )}
            </ul>
            <Button id='closeInvalidParamsModal'
                    text='Ok'
                    isLoading={false}
                    actionO={closeModal} />

        </div>
    </Modal>;

export interface IBaseProps<D extends IInvoiceAddDSParams, I, R> {
    baseAxios: (params: D, config: AxiosRequestConfig) => Promise<AxiosResponse<IInvoiceAddResponse<I>>>;
    getValidationErrors: () => string[];
    getValidParams: () => D | null, // not null if
    addInvoiceExtractBuyerFinancingImpactO: (i: I) => BuyerFinancingImpact | null;
    addInvoiceInvoicesWhichBecomeUneligibleExtractor: (i: I) => IInvoicesWhichBecomeUneligible[];
    requestFinancingAction: (invoiceId: string) => Promise<R>;
    requestFinancingImpactExtractor: (r: R) => IFinancingImpact;
    thisBuyerLabel: string;
}

export interface IProps<D extends IInvoiceAddDSParams, I, R> extends IBaseProps<D, I, R>{
    getRejectionMessage: (params: D) => string | null;
    sellerCannotFinanceReason: string | null;
}

const InvoiceAddButton = <I, D extends IInvoiceAddDSParams, R> (
    {
        baseAxios,
        getValidationErrors,
        getValidParams,
        getRejectionMessage,
        sellerCannotFinanceReason,
        addInvoiceExtractBuyerFinancingImpactO,
        addInvoiceInvoicesWhichBecomeUneligibleExtractor,
        requestFinancingAction,
        requestFinancingImpactExtractor,
        thisBuyerLabel
    }: IProps<D, I, R>
) => {
    const factorContext = getFactorContext();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const queryClient = useQueryClient();

    const [displaySuccessModal, setDisplaySuccessModal] = useState<IInvoiceAddResponsWithAmountTTC<I> | null>(null);
    const [displayRejectionModal, setDisplayRejectionModal] = useState<string | null>(null);
    const [displayInvalidParamsModal, setDisplayInvalidParamsModal] = useState<string[] | null>(null);
    const [displayExistingInvoiceModal, setDisplayExistingInvoiceModal] = useState(false);

    const addInvoiceMutation: UseMutationResult<IInvoiceAddResponseNotDuplicateO<I /* IInvoiceAddResponse */>, any, D> =
        useMutation<IInvoiceAddResponseNotDuplicateO<I /* IInvoiceAddResponse */>, any, D>(
            async (params2) => await addInvoiceRequestAsync(
                baseAxios,
                // directSellerAxios,
                params2
            ),
            ({
                onSuccess: (result, params2) => {
                    const successO = result.nonDuplicateO;
                    if (!!successO) {
                        const r = addInvoiceUpdateQueriesAsync(queryClient, params2, successO.eligible);
                        setDisplaySuccessModal({
                            response: successO,
                            invoiceAmountTTC: params2.invoiceAmountWoTax + params2.invoiceAmountTax
                        });
                        return r;
                    } // else
                    setDisplayExistingInvoiceModal(true);
                }
            })
        );

    return (
        <div id={InvoiceAddSectionId}>
            <Button id={InvoiceAddButtonId}
                    text={computeText(InvoiceAddButtonMessage, addInvoiceMutation, factorContext.debug)}
                    size={InvoiceAddButtonSize}
                    kind={InvoiceAddButtonKind}
                    isLoading={addInvoiceMutation.isLoading}
                    actionO={() => {
                        // if there was an error (button displays error message)
                        // we want to reset if the user clicks the button again
                        addInvoiceMutation.reset();

                        const errorsO = getValidationErrors();

                        // TODO : review, invalid pattern
                        if (errorsO.length > 0) {
                            return setDisplayInvalidParamsModal(errorsO);
                        }
                        // else

                        const validParams: D = getValidParams()!;

                        const rejectionMessage: string | null = getRejectionMessage(validParams);

                        if (!!rejectionMessage) {
                            return setDisplayRejectionModal(rejectionMessage);
                        }
                        // else

                        addInvoiceMutation.mutate(validParams);
                    }} />

            { !!displayInvalidParamsModal &&
                <InvalidParamsMessagesModal invalidParamsMessages={displayInvalidParamsModal}
                                            closeModal={() => setDisplayInvalidParamsModal(null)} />
            }

            { !!displayRejectionModal &&
                <RejectionMessageModal message={displayRejectionModal}
                                       closeModal={() => setDisplayRejectionModal(null)}/>
            }

            { !!displaySuccessModal &&
                <InvoiceAddResultModal<R> sellerCannotFinanceReason={sellerCannotFinanceReason}
                                          invoiceId={displaySuccessModal.response.invoiceId}
                                          eligible={displaySuccessModal.response.eligible}
                                          notEligibleReason={displaySuccessModal.response.notEligibleReason}
                                          buyerFinancingImpactO={(!!displaySuccessModal.response.financingImpact &&
                                              addInvoiceExtractBuyerFinancingImpactO(displaySuccessModal.response.financingImpact)
                                          ) || null}
                                          invoicesWhichBecomeUneligible={(
                                              !!displaySuccessModal.response.financingImpact &&
                                              addInvoiceInvoicesWhichBecomeUneligibleExtractor(displaySuccessModal.response.financingImpact)
                                          ) || null}
                                          invoiceAmountTTC={displaySuccessModal.invoiceAmountTTC}
                                          requestFinancingAction={requestFinancingAction}
                                          requestFinancingImpactExtractor={requestFinancingImpactExtractor}
                                          thisBuyerLabel={thisBuyerLabel}
                                          closeModal={() => navigate(DashboardInvoicesDefaultRoute)} />


            }
            { displayExistingInvoiceModal &&
                <TextOnlyModal message='Une facture avec le meme numéro existe déjà'
                               buttonsProps={{
                                   id: 'DuplicateNumberId',
                                   text: 'Ok',
                                   isLoading: false,
                                   actionO: () => setDisplayExistingInvoiceModal(false)
                               }}
                               maxWidth={null}
                               fullMaxWidth={false}
                               close={() => setDisplayExistingInvoiceModal(false)} />
            }
        </div>
    );
}

export default InvoiceAddButton;