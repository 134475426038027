import {AxiosRequestConfig, AxiosResponse} from "axios";
import Upload from "factor-lib/forms/Upload/Upload";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import extractAxiosErrorMessage from "factor-lib/serverUtils/extractAxiosErrorMessage";
import { useState } from "react";
import { getFactorContext } from "../../IFactorContext";



export const UPLOAD_ADDITIONAL_FILE_ID = 'uploadAdditionalFile';

export interface IInvoiceAdditionalFile {
    fileName: string;
    id: string;
}

const uploadAdditionalFileAsync = async (
    axiosAction: (/* file: File, */ config: AxiosRequestConfig) => Promise<AxiosResponse<string>>,
    // directSellerAxios: Axios,
    displayFullError: boolean,
    file: File,
    setAdditionalFileError: (errorText: string) => void,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    setNewInvoiceAdditionalFile: (a: IInvoiceAdditionalFile) => void
): Promise<void> => {
    await axiosAction(
        {
            onUploadProgress,
            timeout: FileUploadTimeoutMilliseconds
        }
    )/* directSellerAxios.post<string>(
        `/directSellerInvoiceFiles/additional`,
        data
    ) */
        .then((additionalFileId) =>
            setNewInvoiceAdditionalFile({fileName: file.name, id: additionalFileId.data})
        )
        .catch((e) =>
            setAdditionalFileError(extractAxiosErrorMessage(e, displayFullError ? null : {displayShortDefaultError: false}))
        );
}

const InvoiceUploadAdditionalFile = (
    {
        // directSellerAxios,
        axiosAction,
        setNewInvoiceAdditionalFile
    }: {
        // directSellerAxios: Axios;
        axiosAction: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<string>>;
        setNewInvoiceAdditionalFile: (a: IInvoiceAdditionalFile) => void;
    }
) => {
    const factorContext = getFactorContext();

    const [additionalFileUploadError, setAdditionalFileUploadError] = useState<string | null>(null); // TODO : check this

    return (
        <Upload id={UPLOAD_ADDITIONAL_FILE_ID}
                acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                externalInitialErrors={!!additionalFileUploadError ? [additionalFileUploadError] : null}
                existingFileName={null}
                actionO={{
                    uploadFile: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                        uploadAdditionalFileAsync(
                            (config: AxiosRequestConfig) => axiosAction(file, config),
                            factorContext.debug,
                            file,
                            setAdditionalFileUploadError,
                            onUploadProgress,
                            setNewInvoiceAdditionalFile
                        ),
                    deleteFile: null,
                    errorHandler: () => {}
                }} />
    );
}

export default InvoiceUploadAdditionalFile;
