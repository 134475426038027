import {Outlet, useLocation} from "react-router-dom";
import {dashboardRoute, DashboardRoutePrefix} from "./dashboardUrlsConsts";
import { dashboardSections } from "./IDashboardSection";

import IAccessTokens from "../../Auth/IAccessTokens";

import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useContext} from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";

import SideBarDesktop from "./SideBar/SideBarDesktop";
import { ISideBarMenuItem } from "./SideBar/SideBarMenu";

import './dashboard.scss';

const DashboardPage = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessTokens | null;
    }
) => {
    const location = useLocation();
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    const page = location.pathname.substring(DashboardRoutePrefix.length + 1);

    const dashboardMenuItems: ISideBarMenuItem[] = dashboardSections
        .filter(ds => ds.isAccessible(accessTokenO?.customerType || null))
        .map((ds) => ({
            isActive: ds.routePageParam === page,
            icon: ds.icon,
            label: ds.label,
            visibleMobile: false,
            action: () => navigate(dashboardRoute(ds.routePageParam)),
        }));

    return (
        // Do we want to skip the sidebar on the left if length === 1 ?
        <div className='p-default-flex-item p-menu-component'>
            <SideBarDesktop className='p-no-flex p-menu'
                            menuItems={dashboardMenuItems}
                            children={null} />

            <div className='p-default-flex-item p-component-wrapper-relative'>
                <div className='p-component-absolute'>
                    <Outlet context={{accessTokenO}}/>
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
