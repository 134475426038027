import { useQuery } from "@tanstack/react-query";
import {
    graphQLQuery,
    IDSInvoiceWithDetails,
    IInvoiceDetailsQueryResult,
    invoiceDetailsQueryKey
} from "./InvoiceDetailsQuery";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import Loader from "factor-lib/Loader";
import InvoiceDetailsPageLoaded from "./InvoiceDetailsPageLoaded";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {getFactorContext} from "../../IFactorContext";
import IInvoiceActions from "./IInvoiceActions";
import DataLoader from "dataloader";

const InvoiceDetailsPagePostRedirect = <I extends IDSInvoiceWithDetails, R>(
    {
        graphQLDataLoader,
        invoiceId,
        sellerGraphQLSelection,
        actionsProvider
    }: {
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
        invoiceId: string;
        sellerGraphQLSelection: string;
        actionsProvider: (/* me: ME, */ i: I) => IInvoiceActions<R>;
    }
) => {
    const factorContext = getFactorContext();

    const queryKey = invoiceDetailsQueryKey(invoiceId);
    const invoiceDetailsQueryResult = useQuery<IInvoiceDetailsQueryResult<I>>(
        queryKey,
        async ({signal}) => {
            const query: IGraphQLParams = {
                query: graphQLQuery(sellerGraphQLSelection),
                variables: {
                    invoiceId
                }
            };
            return ({
                invoiceDetails: ((await graphQLDataLoader.load({
                    query,
                    queryKey,
                    signal
                }))).invoiceDetails
            });
        }
    );

    return (
        <ReactQueryResultWrapper result={invoiceDetailsQueryResult}
                                 displayFullError={factorContext.debug}
                                 onLoading={() => <Loader />}
                                 onSuccess={(d: IInvoiceDetailsQueryResult<I>) =>
                                    <InvoiceDetailsPageLoaded<I, R> className='p-padding-top-5 p-padding-left-3 p-padding-right-3'
                                                                    invoiceDetails={d.invoiceDetails}
                                                                    actions={actionsProvider(d.invoiceDetails)} />
                                 } />
    );
};

export default InvoiceDetailsPagePostRedirect;
