// TODO : move to generic utils at some point

import {ReactNode, useEffect, useState} from "react";
import EventBus, {GLOBAL_CLICK_EVENT} from "../../../EventBus";
import './toptooltip.scss';

export const NEW_TOOLTIP_EVENT = 'new-tooltip';

const TopToolTipContent = (
    {
        cleanMouseOver,
        contentNode
    }: {
        cleanMouseOver: () => void;
        contentNode: ReactNode;
    }
) => {
    useEffect(() => {
        EventBus.on(NEW_TOOLTIP_EVENT, cleanMouseOver);
        EventBus.on(GLOBAL_CLICK_EVENT, cleanMouseOver);
        return () => {
            EventBus.remove(NEW_TOOLTIP_EVENT, cleanMouseOver);
            EventBus.remove(GLOBAL_CLICK_EVENT, cleanMouseOver);
        };
    }, [cleanMouseOver])
    return (
        <div className='p-top-tooltip p-b-small'>
            <div className='p-arrow p-padding-7'>
                { contentNode }
            </div>
        </div>
    );
}


const TopToolTip = (
    {
        className,
        buttonNode,
        contentNode
    }: {
        className?: string,
        buttonNode: ReactNode;
        contentNode: ReactNode;
    }
) => {
    const [mouseOver, setMouseOver] = useState(false);
    const cleanMouseOver = () => {
        setMouseOver(false);
    };
    return (
        <div className={`p-ttp-wrapper${!!className ? ` ${className}` : ''}`}
             onMouseEnter={() => {
                 EventBus.dispatch(NEW_TOOLTIP_EVENT);
                 setMouseOver(true);
             }}
        >
            { buttonNode }
            { mouseOver &&
                <TopToolTipContent cleanMouseOver={cleanMouseOver}
                                   contentNode={contentNode} />
            }
        </div>
    );
}

export default TopToolTip;
