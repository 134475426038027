
// Collects a little bit as well
import IGoogleTagManager from './IGoogleTagManager';

export default class GtmLogging implements IGoogleTagManager {

    protected static log(message: string) {
        // tslint:disable-next-line:no-console
        console.log(`[GTM] ${message}`);
    }

    public inited: boolean = false;
    public events: string[] = [];

    /*
    public init(): void {
        if (this.inited) {
          throw new Error('Already initiated');
        }
        this.inited = true;
        this.log(`Init`);
    }

    public loginUser(userAccount: IUserAccount): void {
        this.log(`Logged in user : ${userAccount.userInfos.Email}`);
    }

    public userUpdate(userAccount: IUserAccount): void {
        this.log(`Update on user : ${userAccount.userInfos.Email}`);
    }

    public logout(): void {
        this.log('Logged out');
    } //*/

    public init(userId: string | null): void {
        GtmLogging.log(!!userId ? `Initing with user ${userId}` : 'Initing without user');
    }

    public login(userId: string): void {
        GtmLogging.log(`Logging user ${userId}`);
    }

    public logout(): void {
        GtmLogging.log(`Logging out current user`);
    }

    public event(name: string, properties?: any): void {
        GtmLogging.log(
            !!properties
                ? `Event : ${name}, ${JSON.stringify(properties)}`
                : `Event : ${name}`
        );
        this.events.push(name);
    }

    public newPage(name: string, path: string): void {
        GtmLogging.log(`New page ${name} at path ${path}`);
    }
}
