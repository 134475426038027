import { userFormatDateTime } from 'factor-lib/utils/dateUtils';
import './verticalstepperelement.scss';


const VerticalStepperElement = (
    {
        status,
        isLast,
        lineOk,
        activityDate,
        message
    } : {
        className?: string;
        key?: string;
        status: boolean;
        isLast: boolean;
        lineOk: boolean;
        activityDate: Date;
        message: string;
    }
) =>
    (
        <div className='p-vse'>
            <div className='p-vse-int'>
                <div className={`p-no-flex p-stepper-round ${
                    status ? 'p-ok' : 'p-not-done'}`}>
                    <div>
                        &#10003;
                    </div>
                </div>
                <div className='p-margin-left-4'>
                    <div className='p-padding-bottom-5'>
                        <div>{message}</div>
                        <div className='p-complement-message'>
                            {`Date: ${ userFormatDateTime(activityDate) }`}
                        </div>
                    </div>
                </div>
            </div>
            { isLast
                ? null
                : <div className={`p-vse-line p-full-height${lineOk ? ' p-ok' : ''}`} />
            }
        </div>
    );

export default VerticalStepperElement;
