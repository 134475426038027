import Loader from "factor-lib/Loader";
import IAccessTokens from "../../../Auth/IAccessTokens";
import BuyersTableDesktopContent from "./BuyersTableDesktopContent";

const BuyersTableDesktopSection = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessTokens | null;
    }
) =>
    !!accessTokenO
        ? !!accessTokenO.authUser.directSellerIdO
            ? <BuyersTableDesktopContent customerAxios={accessTokenO.customerAxios}
                                         directSellerAxios={accessTokenO.customerSpecificAxios!} />
            : !!accessTokenO.authUser.marketplaceIdO
                ? <span>TODO: marketplace</span>
                : null // TODO: Ni l'un ni l'autre
        : <Loader />;

export default BuyersTableDesktopSection;
