import './summaryitem.scss';
import {ReactNode} from "react";

const SummaryItem = (
    {
        className,
        legend,
        value,
        hasRightBorder
    }: {
        className?: string;
        legend: string;
        value: ReactNode;
        hasRightBorder: boolean;
    }
) =>
    <div className={`${!!className ? `${className} ` : ''}p-margin-4`}>
        <div className={hasRightBorder ? 'p-padding-right-3 p-border-right' : ''}>
            <div className='p-si-legend'>
                { legend }
            </div>
            {/* <!-- TODO : price in euros ?? What ??--> */}
            <div className='p-summary-number p-bold'>
                { value }
            </div>
        </div>
    </div>;

export default SummaryItem;
