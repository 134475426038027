import {ReactNode, useCallback, useEffect, useRef, useState} from "react";
import './bottooltip.scss';
import EventBus, { GLOBAL_CLICK_EVENT } from "../../EventBus";
import { NEW_TOOLTIP_EVENT } from "./Invoices/TopToolTip";

const DefaultCloseDelayMilliseconds = 300;

// closes on click, or when another element with same event opens
// optionally closes when mouse leave after a given delay
// can be used elsewhere
export const useClosableHoverable = (
    event: string,
    closeOnLeave: {
        delayMilliseconds: number | null; // null -> default
    } | null,
): [boolean, () => void, () => void] => {
    const [mouseOver, setMouseOver] = useState(false);    
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const close = useCallback(() => {
        setMouseOver(false);
    }, [setMouseOver]);

    const onMouseEnter = () => {
        if (!!closeOnLeave && !!timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        setMouseOver(true);
        EventBus.dispatch(event, timerRef);
    };

    const onMouseLeave = () => {
        if (!!closeOnLeave) {
            timerRef.current = setTimeout(close, closeOnLeave.delayMilliseconds ?? DefaultCloseDelayMilliseconds);
        }
    };

    useEffect(() => {
        const onOtherOpened = (otherTimerRef: React.MutableRefObject<NodeJS.Timeout | null>) => {
            if (otherTimerRef !== timerRef) {
                close();
            }
        };

        EventBus.on(event, onOtherOpened);
        EventBus.on(GLOBAL_CLICK_EVENT, close);

        return () => {
            EventBus.remove(event, onOtherOpened);
            EventBus.remove(GLOBAL_CLICK_EVENT, close);
        };
    }, [event, close]);

    return [mouseOver, onMouseEnter, onMouseLeave];
}

const BotToolTip = (
    {
        className,
        toolTipMargin,
        buttonNode,
        closeOnLeave,
        children
    }: {
        className?: string,
        toolTipMargin?: number;
        buttonNode: ReactNode;
        closeOnLeave: boolean;
        children: ReactNode;
    }
) => {
    const [mouseOver, onMouseEnter, onMouseLeave] = useClosableHoverable(
        NEW_TOOLTIP_EVENT,
        closeOnLeave ? { delayMilliseconds: null } : null
    );

    return (
        <div className={`p-ttp-wrapper${!!className ? ` ${className}` : ''}`}>
            <div onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}>
                { buttonNode }
            </div>

            { mouseOver &&
                <div className={`p-bot-tooltip p-b-small${!!toolTipMargin ? ` p-margin-top-${toolTipMargin}` : ''}`}
                     onMouseEnter={onMouseEnter}
                     onMouseLeave={onMouseLeave}>
                    <div className='p-arrow p-padding-7'>
                        { children }
                    </div>
                </div>
            }
        </div>
    );
};

export default BotToolTip;
