import ILogger from "factor-lib/services/logger/ILogger";

// different from currentEnv, as does not rely on implementations
export default interface IFactorContext {
    logger: ILogger;
    debug: boolean;
}

let wrapper: IFactorContext | null = null;

export const setFactorContext = (logger: ILogger, debug: boolean): void => {
    wrapper = ({
        logger,
        debug
    });
}

const throwError = (): IFactorContext => {
    throw new Error('No context set');
}

export const getFactorContext = (): IFactorContext =>
    wrapper ?? throwError();