import { QueryClient } from "@tanstack/react-query";
import { updateInfiniteQuery } from "factor-lib/utils/graphQLPagination";
import { BuyersQueryKey, IBuyer } from "./BuyersTableDesktopQuery";

export const updateBuyerInList = (
    queryClient: QueryClient,
    buyerId: string,
    updater: (b: IBuyer) => IBuyer
 ) =>
    updateInfiniteQuery(
        queryClient,
        BuyersQueryKey,
        (b) => b.id === buyerId,
        updater
    );
