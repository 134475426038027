import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {
    buildMeQueriesConfig,
    IMeMinInvoiceAmount,
    MeMinInvoiceAmountProp,
    mergeQueriesData
} from "../utils/Me";
import {getFactorContext} from "../../IFactorContext";
import ILogger from "factor-lib/services/logger/ILogger";
import DataLoader from "dataloader";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import {useQueries, UseQueryResult} from "@tanstack/react-query";
import graphQLBatchLoadFunctionAsync from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import {useState} from "react";
import {IInvoiceFile, IInvoiceFileAddedWithMindeeDto} from "./InvoiceUploadFile";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import { ILineContactInput } from "factor-lib/AddInvoice/BuyerContactsInput";
import {
    IInvoiceBaseInfosInput,
    IInvoiceBaseInfosInputEnabled
} from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import {IInvoiceAddResponse} from "./InvoiceAddButton";
import {Axios, AxiosRequestConfig} from "axios";
import {IInvoiceAdditionalFile} from "./InvoiceUploadAdditionalFile";
import {IInvoicesWhichBecomeUneligible} from "../InvoiceFinancingImpact";
import { IQueryResult } from "factor-lib/reactquery/ReactQueryResultWrapper";
import {
    getInvoiceValidOrNotAddParams,
    getRejectionMessage
} from "./invoiceAddUtils";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import { parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import {SortOrder, SortOrderToOrderByDirection} from "factor-lib/utils/sortingUtils";
import {IPaginated, mapPaginated, query} from "factor-lib/utils/graphQLPagination";
import InvoiceAddBuyerInfos, {IInvoiceBuyerInfosInput} from "./InvoiceAddBuyerInfos";
import {BuyerSortProperty, BuyerSortPropertyToOrderByField} from "../Dashboard/Buyers/buyersSortProperties";
import { IBuyerCompany } from "factor-lib/AddInvoice/BuyerSelection";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import IBuyerCompanySelection from "factor-lib/AddInvoice/IBuyerCompanySelection";
import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import InvoiceAddContentBaseLayout from "./InvoiceAddContentBaseLayout";
import {requestFinancingMAsync} from "../utils/requestFinancingUtils";
import ExistingPaginatedSelection from "../../utils/ExistingPaginatedSelection";

export interface IFinancingImpactM {
    invoicesWhichBecomeUneligible: IInvoicesWhichBecomeUneligible[];
}

export interface IInvoiceAddMParams {
    sellerCompanyId: string;
    buyerSelection: IBuyerCompanySelection;
    buyerEmails: string[];
    buyerPhones: string[];
    invoiceNumber: string;
    invoiceIssueDate: string;
    invoiceAmountWoTax: number;
    invoiceAmountTax: number;
    invoiceDueDate: string;
    invoiceFileId: string;
    additionalFileIds: string[];
}

export interface IRequestFinancingResponseM {
    financingImpact: IFinancingImpactM;
}

export interface ISeller {
    company:  {
        id: string;
        identifier: IIntCompanyIdentifier;
        friendlyName: string;
    };
    cannotFinanceReason: string | null;
    email: string;
}


export const SellersQueryKey = ['sellers'];

export enum SellerSortProperty {
    CreationDate = 'creationDateTime',
    Name = 'name',
    FriendlyName = 'name'
}

export const requestFinancingMUrl = (invoiceId: string) =>
    `/marketplaceInvoices/${invoiceId}/requestFinancing`;

export const SellerSortPropertyToOrderByField: Map<SellerSortProperty, string> = new Map([
    [ SellerSortProperty.CreationDate, 'CREATION_DATE_TIME' ],
    [ SellerSortProperty.Name, 'NAME' ],
    [ SellerSortProperty.FriendlyName, 'FRIENDLY_NAME' ],
]);

const MarketplaceExistingBuyerLabel = 'Acheteur existant';
const MarketplaceNewBuyerLabel = 'Nouvel acheteur';

const sellersQueryFunc =
    async (
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
        signal: AbortSignal | undefined,
        pageParam: any | null,
        searchInputStr: string,
        queryKey: string[]
    ) => {
        const r: any = await graphQLDataLoader.load({
            query: {
                query: `query ($search: String, $after: PaginationGuidCursor) {
                    sellers(search: $search) {
                        list(
                            first: 10,
                            after: $after,
                            orderByField: ${SellerSortPropertyToOrderByField.get(SellerSortProperty.FriendlyName)!},
                            orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Asc)!}
                        ) ${query(
                            `
                                id
                                identifier { type value }
                                friendlyName
                                cannotFinanceReason
                                email
                            `
                        )}
                    }
                }`,
                variables: {
                    search: searchInputStr,
                    after: pageParam
                }
            },
            queryKey,
            signal
        });
        return mapPaginated(
            r.sellers.list as IPaginated<{
                id: string;
                identifier: IIntCompanyIdentifier;
                friendlyName: string;
                cannotFinanceReason: string | null;
                email: string;
            }>,
            (s) => {
                const r: ISeller = ({
                    email: s.email,
                    cannotFinanceReason: s.cannotFinanceReason,
                    company: {
                        id: s.id,
                        identifier: s.identifier,
                        friendlyName: s.friendlyName
                    }
                });

                return r;
            }
        );
    };

export type IMe = IMeMinInvoiceAmount;

export const NewBuyerMarketplacePlaceholder = 'Nom ou SIREN de la société acheteuse';
const ExistingBuyerMarketplacePlaceholder = 'Choisissez une société acheteuse';


const hasAnyBuyersQueryFunc =
    async (
        sellerCompanyId: string,
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
        signal: AbortSignal | undefined,
        queryKey: string[]
    ): Promise<boolean> => {
        const r: any = await graphQLDataLoader.load({
            query: {
                query: `query ($sellerId: Guid!) {
                    seller(id: $sellerId) {
                        hasAnyBuyer: buyers {
                            any
                        }
                    }
                }`,
                variables: {
                    sellerId: sellerCompanyId
                }
            },
            queryKey,
            signal
        });
        return r.seller.hasAnyBuyer.any as boolean;
    };

const buyerQueryFunc =
    async (
        sellerCompanyId: string,
        graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
        signal: AbortSignal | undefined,
        pageParam: any | null,
        searchInputStr: string | null,
        queryKey: string[]
    ): Promise<IPaginated<IBuyerCompany>> => {
        const r: any = await graphQLDataLoader.load({
            query: {
                query: `query (
                    $sellerId: Guid!,
                    $after: PaginationGuidCursor,
                    $search: String
                ) {
                    seller(id: $sellerId) {
                        buyers (search: $search) {
                            list (
                                first: 10,
                                after: $after,
                                orderByField: ${BuyerSortPropertyToOrderByField.get(BuyerSortProperty.Name)!},
                                orderByDirection: ${SortOrderToOrderByDirection.get(SortOrder.Asc)!}
                            ) ${query(
                                `
                                    id
                                    name
                                    identifier { ${IntCompanyIdentifierGraphQLFields} }
                                `
                            )} 
                        }
                    }
                }`,
                variables: {
                    search: searchInputStr,
                    after: pageParam,
                    sellerId: sellerCompanyId
                }
            },
            queryKey,
            signal
        });
        return r.seller.buyers.list as IPaginated<IBuyerCompany>;
    };

const contactsQueryFn = async (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    signal: AbortSignal | undefined,
    queryKey: string[],
    sellerCompanyId: string,
    buyerCompanyId: string
) => {
    const query: IGraphQLParams =
        ({
            query: `query (
                    $sellerId: Guid!,
                    $buyerId: Guid!
                ) {
                    seller(id: $sellerId) {
                        buyer(id: $buyerId) {
                            emails
                            phones
                        }
                    }
                }`,
            variables: {
                sellerId: sellerCompanyId,
                buyerId: buyerCompanyId
            }
        });
    const r = (await graphQLDataLoader.load({
        query,
        queryKey,
        signal
    })).seller.buyer;
    return ({
        emails: r.emails,
        phones: r.phones,
    });
};

export const ExistingSellerSelectorInputId = 'selectExistingSellerInputId';

export const sellerFormatting = (seller: ISeller) =>
    seller.company.friendlyName;

const InvoiceAddContentPostRedirectM = (
    {
        className,
        customerAxios,
        marketplaceAxios,
    }: {
        className?: string;
        customerAxios: Axios;
        marketplaceAxios: Axios;
    }
) => {
    const factorContext = getFactorContext();
    const logger: ILogger = factorContext.logger;

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunctionAsync(
            marketplaceAxios,
            logger,
            graphQLQueries
        )
    );

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            [MeMinInvoiceAmountProp]
        )
    );

    const meQuery: IQueryResult<IMe> = reactQueryResultCombiner<IMe>(
        meQueries,
        () => mergeQueriesData(meQueries)
    );

    // TODO : from url at some point ?
    const [sellerSelection, setSellerSelection] = useState<ISeller | null>(null);

    const [invoiceFile, setInvoiceFile] = useState<IInvoiceFile | null>(null);
    const [invoiceAdditionalFiles, setInvoiceAdditionalFiles] = useState<IInvoiceAdditionalFile[]>([]);

    const [buyerSelection, setBuyerSelection] = useState<IBuyerCompanySelection | null>(/* !!buyerPreSelectedId ? { id: buyerPreSelectedId, siren: null } :  */null);
    const [buyerContactsInputs, setBuyerContactsInputs] = useState<ILineContactInput[]>([{ email: '', phone: '' }]);

    const buyerInfosInput: IInvoiceBuyerInfosInput = {
        companySelection: buyerSelection,
        contacts: buyerContactsInputs
    };

    const [numberInput, setNumberInput] = useState('');
    const [issueDateInput, setIssueDateInput] = useState('');
    const [dueDateInput, setDueDateInput] = useState('');
    const [amountWoTaxInput, setAmountWoTaxInput] = useState('');
    const [amountTaxInput, setAmountTaxInput] = useState('');

    const baseInfosInput: IInvoiceBaseInfosInput = {
        number: numberInput,
        issueDate: issueDateInput,
        dueDate: dueDateInput,
        amountWoTax: amountWoTaxInput,
        amountTax: amountTaxInput
    };

    const baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled = {
        updateNumber: setNumberInput,
        updateIssueDate: setIssueDateInput,
        updateDueDate: setDueDateInput,
        updateAmountWoTax: setAmountWoTaxInput,
        updateAmountTax: setAmountTaxInput,
        autofocus: false
    };

    return (
        <InvoiceAddContentBaseLayout<IMe, IFinancingImpactM, IInvoiceAddMParams, IRequestFinancingResponseM>
            className={className}
            invoiceFileO={invoiceFile}
            setInvoiceFile={setInvoiceFile}
            uploadFileAxiosAction={(file: File, config: AxiosRequestConfig) => {
                const data = new FormData();
                data.append('file', file);
                return marketplaceAxios.post<IInvoiceFileAddedWithMindeeDto>(
                    `/marketplaceInvoiceFiles`,
                    data,
                    config
                );
            }}
            baseInfosInput={baseInfosInput}
            baseInfosInputEnabled={baseInfosInputEnabled}
            sellerBuyerCardTitle='Infos sur le vendeur et l’acheteur'
            sellerBuyerCardContentNode={
                <div>
                    <ExistingPaginatedSelection<ISeller> intputId={ExistingSellerSelectorInputId}
                                                         displayFullError={factorContext.debug}
                                                         setSelection={setSellerSelection}
                                                         formatter={sellerFormatting}
                                                         placeholder='Choisissez un vendeur'
                                                         queryKeyFactory={(searchInputStr) => [...SellersQueryKey, 'search', searchInputStr]}
                                                         queryFn={(signal: AbortSignal | undefined, pageParam: any | null, searchInputStr: string) =>
                                                             sellersQueryFunc(
                                                                 graphQLDataLoader,
                                                                 signal,
                                                                 pageParam,
                                                                 searchInputStr,
                                                                 [...SellersQueryKey, 'search', searchInputStr]
                                                             )
                                                         } />
                    <InvoiceAddBuyerInfos className='p-margin-top-4'
                                          canLoadO={!!sellerSelection ? ({
                                              queryKeysFactory: (baseKeys) => ['seller', 'id', sellerSelection!/* Not very clean */.company.id, ...baseKeys],
                                              hasAnyQueryFn: (
                                                  signal: AbortSignal | undefined,
                                                  queryKey: string[]
                                              ) => hasAnyBuyersQueryFunc(
                                                  sellerSelection!.company.id,
                                                  graphQLDataLoader,
                                                  signal,
                                                  queryKey
                                              ),
                                              queryFn: (
                                                  signal: AbortSignal | undefined,
                                                  pageParam: any | null,
                                                  searchInputStr: string | null,
                                                  queryKey: string[]
                                              ) => buyerQueryFunc(
                                                  sellerSelection!.company.id,
                                                  graphQLDataLoader,
                                                  signal,
                                                  pageParam,
                                                  searchInputStr,
                                                  queryKey
                                              )
                                          }) : null}
                                          buyerPreSelectedO={null}
                                          input={buyerInfosInput}
                                          setBuyerSelection={setBuyerSelection}
                                          setBuyerContactsInputs={setBuyerContactsInputs}
                                          customerSpecificAxios={marketplaceAxios}
                                          customerAxios={customerAxios}
                                          autofocus={false}
                                          existingBuyerLabel={MarketplaceExistingBuyerLabel}
                                          newBuyerLabel={MarketplaceNewBuyerLabel}
                                          contactsQueryKeyFactory={(baseKeys) => ['seller', 'id', sellerSelection!/* Not very clean */.company.id, ...baseKeys]}
                                          contactsQueryFn={(
                                              signal: AbortSignal | undefined,
                                              queryKey: string[],
                                              buyerCompanyId: string
                                          ) => contactsQueryFn(
                                              graphQLDataLoader,
                                              signal,
                                              queryKey,
                                              sellerSelection!/* Not very clean */.company.id,
                                              buyerCompanyId
                                          )}
                                          newBuyerPlaceholder={NewBuyerMarketplacePlaceholder}
                                          existingBuyerPlaceholder={ExistingBuyerMarketplacePlaceholder}/>
                </div>
            }
            invoiceAdditionalFiles={invoiceAdditionalFiles}
            setInvoiceAdditionalFiles={setInvoiceAdditionalFiles}
            uploadAdditionalFileAxiosAction={(file: File, config: AxiosRequestConfig) => {
                const data = new FormData();
                data.append('file', file);
                return marketplaceAxios.post<string>(
                    `/marketplaceInvoiceFiles/additional`,
                    data,
                    config
                );
            }}
            meQuery={meQuery}
            addButtonBaseProps={{
                baseAxios:(params: IInvoiceAddMParams, config: AxiosRequestConfig) =>
                    marketplaceAxios.post<IInvoiceAddResponse<IFinancingImpactM>>(
                        '/marketplaceInvoices',
                        params,
                        config
                    ),
                getValidationErrors: () => {
                    let errorsCollector: string[] = [];
                    if (!sellerSelection) {
                        errorsCollector.push(`Vous n’avez pas sélectionné de vendeur.`);
                    }
                    return getInvoiceValidOrNotAddParams(
                        invoiceFile?.id ?? null,
                        invoiceAdditionalFiles.map((a) => a.id),
                        baseInfosInput,
                        buyerInfosInput,
                        `d'acheteur`,
                        errorsCollector
                    );
                },
                getValidParams: () => ({
                    sellerCompanyId: sellerSelection!.company.id,
                    buyerSelection: buyerInfosInput.companySelection!,
                    buyerEmails: buyerInfosInput.contacts.map((c) => c.email),
                    buyerPhones: buyerInfosInput.contacts.filter((c) => c.phone.trim().length > 0).map((c) => c.phone),
                    invoiceNumber: baseInfosInput.number,
                    invoiceIssueDate: serverDateSerialization(parseDateInput(baseInfosInput.issueDate)!),
                    invoiceDueDate: serverDateSerialization(parseDateInput(baseInfosInput.dueDate)!),
                    invoiceAmountWoTax: parseInputAmount(baseInfosInput.amountWoTax),
                    invoiceAmountTax: parseInputAmount(baseInfosInput.amountTax),
                    invoiceFileId: invoiceFile!.id,
                    additionalFileIds: invoiceAdditionalFiles.map((a) => a.id)
                }),
                addInvoiceExtractBuyerFinancingImpactO: () => null,
                addInvoiceInvoicesWhichBecomeUneligibleExtractor: (i: IFinancingImpactM) => i.invoicesWhichBecomeUneligible,
                requestFinancingAction: (invoiceId2: string) =>
                    requestFinancingMAsync(marketplaceAxios, invoiceId2),
                requestFinancingImpactExtractor: (r: IRequestFinancingResponseM) => ({
                    forBuyerO: null,
                    invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                }),
                thisBuyerLabel: 'cet acheteur'
            }}
            getRejectionMessageFactory={(me: IMe, p: IInvoiceAddMParams) => getRejectionMessage(
                sellerSelection!.company,
                'Même société vendeur / acheteur',
                me.minInvoiceAmount,
                sellerSelection!.email,
                `Désolé, vous ne pouvez pas utiliser le même email pour l'acheteur et pour le vendeur.`,
                p
            )}
            sellerCannotFinanceReasonFactory={() => sellerSelection?.cannotFinanceReason || null}/>
    );
}

export default InvoiceAddContentPostRedirectM;