import {UseInfiniteQueryResult, useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import { Axios } from "axios";
import { computeText } from "factor-lib/Buttons/ButtonMutationEnabled";
import Modal from "factor-lib/Modal";
import { useState } from "react";
import { trimEverywhere } from 'factor-lib/forms/Inputs/utils';
import {BuyersQueryKey, IBuyer, IBuyerOutstandings} from "./BuyersTableDesktopQuery";
import BuyerSearcher from "../../../utils/BuyerSearcher";
import InputAmount from "factor-lib/forms/Inputs/InputAmount";
import { CallWithDimplScoreInBackTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import { getIntCompanyIdentifierSirenO, IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import OkCancelRow from 'factor-lib/Modals/OkCancelRow';
import { IPaginated, getNodes } from "factor-lib/utils/graphQLPagination";
import { getFactorContext } from "../../../IFactorContext";


interface IAddBuyerResult {
    id: string;
    name: string;
    outstandings: IBuyerOutstandings;
}

interface IAddBuyerParams {
    siren: string;
    askedOutstanding: number | null;
}

const BuyerAddModal = (
    {
        customerAxios,
        directSellerAxios,
        sellerIdentifier,
        buyersQueryResult,
        //existingBuyerSirens,
        closeModal
    }: {
        customerAxios: Axios,
        directSellerAxios: Axios,
        sellerIdentifier: IIntCompanyIdentifier;
        buyersQueryResult: UseInfiniteQueryResult<IPaginated<IBuyer>>;
        //existingBuyerSirens: Set<string>; was used when we fetched all buyers without pagination
        closeModal: () => void;
    }
) => {
    // If non null : valid siren trimmed
    const [ buyerSiren, setBuyerSiren ] = useState<string | null>(null);
    const [ askedOutstandingInput, setAskedOutstandingInput ] = useState('');

    const queryClient = useQueryClient();

    // Pattern : https://react-query.tanstack.com/examples/optimistic-updates
    const addBuyerMutation: UseMutationResult<IAddBuyerResult, any, IAddBuyerParams> =
        useMutation<IAddBuyerResult, any, IAddBuyerParams>(
            async (params2) =>
                (await directSellerAxios.post<IAddBuyerResult>(
                    `/directSellerBuyers`,
                    params2,
                    {timeout: CallWithDimplScoreInBackTimeoutMilliseconds}
                )).data,
            ({
                onSuccess: () => {
                    // Cannot update because of pagination
                    const r = queryClient.invalidateQueries(BuyersQueryKey);
                    closeModal();
                    return r;
                }
            })
        );

    const errorMessage: string | null =
        !!buyerSiren
            ? buyerSiren === getIntCompanyIdentifierSirenO(sellerIdentifier)
                ? 'Vous ne pouvez pas vous ajouter en tant que client'
                : !!buyersQueryResult.data
                    // Not exhaustive, but better than nothing
                    ? !!getNodes<IBuyer>(buyersQueryResult.data).find((n: IBuyer) => getIntCompanyIdentifierSirenO(n.identifier) === buyerSiren)
                        ? 'Ce client existe déjà'
                        : null
                    : null
            : null;

    const displayFullError = getFactorContext().debug;
    return (
        <Modal id='add-buyer-modal'
               maxWidth={null}
               fullMaxWidth={false}
               close={closeModal}>
            <div className='p-padding-4 p-vertical-stretch'>
                <div className='p-size-5 p-bold p-margin-bottom-4 p-vertical-center'>
                    Ajouter un Client
                </div>

                { !!errorMessage &&
                    <div className='p-error-message p-margin-bottom-5 p-full-width'>
                        <div className='level'>
                            <div className='level-item'>
                                <div className='p-error-icon p-margin-right-6'>!</div>
                                { errorMessage }
                            </div>
                        </div>
                    </div>
                }

                <div className='p-full-width'>
                    <BuyerSearcher displayFullError={displayFullError}
                                   customerAxios={customerAxios}
                                   directSellerAxios={directSellerAxios}
                                   enabled={{
                                       autofocus: true,
                                       updateSiren: (s: string | null) =>
                                           setBuyerSiren(!!s ? trimEverywhere(s) : null)
                                   }} />
                </div>

                <InputAmount className='p-full-width p-margin-top-5'
                             fieldName='Encours demandé'
                             inputAmountValue={askedOutstandingInput}
                             enabled={{
                                 updateInputValue: setAskedOutstandingInput
                             }} />

                <OkCancelRow className='p-margin-top-4'
                             okText={computeText('Ajouter', addBuyerMutation, displayFullError)}
                             validate={!!buyerSiren && (!askedOutstandingInput || isValidAmount(askedOutstandingInput)) && !errorMessage
                                 ? () => addBuyerMutation.mutate({
                                     siren: buyerSiren,
                                     askedOutstanding: !!askedOutstandingInput ? parseInputAmount(askedOutstandingInput) : null
                                 })
                                 : null
                             }
                             isLoading={addBuyerMutation.isLoading}
                             cancel={closeModal} />
            </div>
        </Modal>
    );
};

export default BuyerAddModal;
