import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {getFactorContext} from "../../IFactorContext";
import {requestFinancingUpdateQueriesAsync} from "../utils/requestFinancingUtils";
import {IFinancingImpact} from "../InvoiceFinancingImpact";

const InvoiceAddRequestFinancingButtonId = 'invoiceAddRequestFinancingButton';

const InvoiceAddRequestFinancingButtonEnabled = <R, >(
    {
        action,
        invoiceId,
        invoiceAmountTTC,
        financingImpactExtractor,
        setResult,
        kind,
        size,
        text
    }: {
        action: (invoiceId: string) => Promise<R>;
        invoiceId: string;
        invoiceAmountTTC: number;
        financingImpactExtractor: (r: R) => IFinancingImpact;
        setResult: (result: R) => void;
        kind: string;
        size: string;
        text: string;
    }
) => {
    const queryClient = useQueryClient();
    const factorContext = getFactorContext();

    const financingRequestsMutation: UseMutationResult<R, any, string> =
        useMutation<R, any, string>(
            action,
            ({
                onSuccess: (response: R, invoice2Id) => {
                    setResult(response);
                    const impact = financingImpactExtractor(response);
                    const r = requestFinancingUpdateQueriesAsync(
                        queryClient,
                        invoice2Id,
                        invoiceAmountTTC,
                        0,
                        impact.forBuyerO,
                        impact.invoicesWhichBecomeUneligible
                    );
                    return r;
                }
            })
        );

    return (
        <ButtonMutationEnabled id={InvoiceAddRequestFinancingButtonId}
                               kind={kind}
                               size={size}
                               text={text}
                               mutation={financingRequestsMutation}
                               value={invoiceId}
                               displayFullError={factorContext.debug} />

    );
};

export default InvoiceAddRequestFinancingButtonEnabled;
