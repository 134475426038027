import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import { comparingNumber, comparingString as comparingStringLib } from "factor-lib/utils/comparingUtils";
import { comparingDate } from "factor-lib/utils/dateUtils";
import { IDSInvoice } from "../FilteredInvoices";
import { InvoiceSortProperty } from "../invoicesSortProperties";
import {IWithSellerInvoice} from "../FilteredInvoicesM";

// TODO  : in a more generic utils file ?
export const comparingString = <T>(extractor: (e: T) => string) =>
    comparingStringLib((i: T) => extractor(i).toUpperCase() /* User expected behavior for sorting (PRED-2270)*/);

const creationDateComparator = comparingDate<IDSInvoice>((i: IDSInvoice) => serverDateDeserialization(i.creationDateTime));
const numberComparator = comparingString<IDSInvoice>((i: IDSInvoice) => i.number);
const amountWoTaxComparator = comparingNumber<IDSInvoice>((i: IDSInvoice) => i.amountWoTax);
const amountTTCComparator = comparingNumber<IDSInvoice>((i: IDSInvoice) => i.amountWoTax + i.amountTax);
const dueDateComparator = comparingDate<IDSInvoice>((i: IDSInvoice) => serverDateDeserialization(i.dueDate));
const buyerNameComparator = comparingString<IDSInvoice>((i) => i.buyer.name);
const sellerNameComparator = comparingString<IWithSellerInvoice>((i) => i.seller.friendlyName);

export const buildInvoicesSortWoSeller = (
    sortProperty: InvoiceSortProperty
): (i1: IDSInvoice, i2: IDSInvoice) => number => {
    switch (sortProperty) {
        case InvoiceSortProperty.CreationDate:
            return creationDateComparator;
        case InvoiceSortProperty.Number:
            return numberComparator;
        case InvoiceSortProperty.AmountWoTax:
            return amountWoTaxComparator;
        case InvoiceSortProperty.AmountTTC:
            return amountTTCComparator;
        case InvoiceSortProperty.DueDate:
            return dueDateComparator;
        case InvoiceSortProperty.BuyerName:
            return buyerNameComparator;
        default:
            // should not happen, sort property already checked
            throw Error('invalid sort property');
    }
};

export const buildInvoicesSortWiSeller = (
    sortProperty: InvoiceSortProperty
): (i1: IWithSellerInvoice, i2: IWithSellerInvoice) => number => {
    if (sortProperty === InvoiceSortProperty.SellerName) {
        return sellerNameComparator;
    }
    // else

    return buildInvoicesSortWoSeller(sortProperty);
};
