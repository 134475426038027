import {ReactElement, useMemo, useState} from "react";
import ITopBarProps from "./TopBar/ITopBarProps";
import {AccountInfo} from "@azure/msal-browser";
import FactorTopBar from "./TopBar/FactorTopBar";

const TopBarWrapper = (
    {
        child
    }: {
        child: (
            safeSetTopBarProps: (newTopBarProps: ITopBarProps) => void,
            safeSetAccountProps: (account: AccountInfo) => void
        ) => ReactElement;
    }
) => {
    const [topBarProps, setTopBarProps] = useState<ITopBarProps | null>(null);
    const [accountO, setAccountO] = useState<AccountInfo | null>(null);

    const childMemo = useMemo(
        () => child(setTopBarProps, setAccountO),
        [child]
    );

    return (
        <>
            <FactorTopBar topBarProps={topBarProps}
                          accountO={accountO} />
            { childMemo }
        </>
    )
};

export default TopBarWrapper;