import { IDSInvoice } from "../FilteredInvoices";
import InvoiceRow from "./InvoiceRow";
import InvoiceRowWithRequestFinancingWrapper from "./InvoiceRowWithRequestFinancingWrapper";
import SortPaginationMerger from "../SortPaginationMerger";
import Loader from "factor-lib/Loader";
import { getFactorContext } from "../../../../IFactorContext";
import { IPaginated, base64Encode, getNodes } from "factor-lib/utils/graphQLPagination";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { INVOICE_LIST_ID } from "./invoicesListConsts";
import SortableTableTitle from "../../SortableTableTitle";
import { InvoiceSortProperty } from "../invoicesSortProperties";
import { AmountTTCText, AmountWoTaxText, BuyerName, DueDateText, NumberText, SellerName } from "../invoicesFieldsText";
import WithDeclarationPayment, {alreadyPaidInvoice} from "./WithDeclarationPayment";
import WithTextOnlyModal from "../../../../utils/WithTextOnlyModal";
import {CSSProperties, ReactNode} from "react";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { ISort, SortOrder } from "factor-lib/utils/sortingUtils";
import FullPageRefreshing, {DefaultOpacityForReloadingStyle} from "../../../utils/FullPageRefreshing";
import IActions from "./actions/IActions";
import IRequestFinancingAction from "./actions/IRequestFinancingAction";
import {ISeller} from "../FilteredInvoicesM";


const InvoicesListTable = (
    {
        sellersEnabled,
        sort,
        setSort,
        children
    }: {
        sellersEnabled: boolean;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
        children?: ReactNode;
    }
) =>
    <table id={INVOICE_LIST_ID}
           className='table is-striped is-fullwidth p-table p-border p-il-table'>
        <thead>
            <tr>
                <th>
                    <SortableTableTitle name={NumberText}
                                        alignRight={false}
                                        upSortClickHandler={sort.property !== InvoiceSortProperty.Number || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: InvoiceSortProperty.Number, order: SortOrder.Asc}))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== InvoiceSortProperty.Number || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: InvoiceSortProperty.Number, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                { sellersEnabled &&
                    <th>
                        <SortableTableTitle name={SellerName}
                                            alignRight={false}
                                            upSortClickHandler={sort.property !== InvoiceSortProperty.SellerName || sort.order !== SortOrder.Asc
                                                ? (() => setSort({ property: InvoiceSortProperty.SellerName, order: SortOrder.Asc }))
                                                : null
                                            }
                                            downSortClickHandler={sort.property !== InvoiceSortProperty.SellerName || sort.order !== SortOrder.Desc
                                                ? (() => setSort({ property: InvoiceSortProperty.SellerName, order: SortOrder.Desc }))
                                                : null
                                            } />
                    </th>
                }
                <th>
                    <SortableTableTitle name={BuyerName}
                                        alignRight={false}
                                        upSortClickHandler={sort.property !== InvoiceSortProperty.BuyerName || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: InvoiceSortProperty.BuyerName, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== InvoiceSortProperty.BuyerName || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: InvoiceSortProperty.BuyerName, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th className='minSize p-no-wrap'>
                    <SortableTableTitle name={DueDateText}
                                        alignRight={false}
                                        upSortClickHandler={sort.property !== InvoiceSortProperty.DueDate || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: InvoiceSortProperty.DueDate, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== InvoiceSortProperty.DueDate || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: InvoiceSortProperty.DueDate, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th className='minSize p-no-wrap'>
                    <SortableTableTitle name={AmountWoTaxText}
                                        alignRight={true}
                                        upSortClickHandler={sort.property !== InvoiceSortProperty.AmountWoTax || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: InvoiceSortProperty.AmountWoTax, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== InvoiceSortProperty.AmountWoTax || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: InvoiceSortProperty.AmountWoTax, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th className='minSize p-no-wrap'
                    style={{paddingLeft: '1em', paddingRight: '1em'}}>
                    <SortableTableTitle name={AmountTTCText}
                                        alignRight={true}
                                        upSortClickHandler={sort.property !== InvoiceSortProperty.AmountTTC || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: InvoiceSortProperty.AmountTTC, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== InvoiceSortProperty.AmountTTC || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: InvoiceSortProperty.AmountTTC, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th>
                    { /* Button */ }
                </th>
                {/*<th className='minSize'>*/}
                {/*     Question mark tooltip */}
                {/*</th>*/}
                <th className='minSize'>
                    { /* Buyer paid */ }
                </th>
            </tr>
        </thead>
        { children }
    </table>;

export interface ILocalRequestFinancingAction<I, R> extends IRequestFinancingAction<I, R> {
    setTextOnlyModal: (textO: string | null) => void;
}
export interface ILocalActions<I extends IDSInvoice, R> {
    // directSellerAxios: Axios;
    canRequestFinancingO: ILocalRequestFinancingAction<I, R> | null;

    setCurrentDeclareBuyerPaymentInvoiceO: ((i: I) => void) | null;
}

const InvoicesListLoaded = <I extends IDSInvoice, R>(
    {
        style,
        invoices,
        isIncrementalLoading,
        sellerExtractorO,
        actions,
        sort,
        setSort
    }: {
        style?: CSSProperties;
        invoices: SortPaginationMerger<I>;
        isIncrementalLoading: boolean;
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        actions: ILocalActions<I, R>;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) => {

    const hasNextPage = invoices.hasNextPages();

    const [sentryRef] = useInfiniteScroll({
        loading: isIncrementalLoading,
        hasNextPage: hasNextPage,
        onLoadMore: () => invoices.fetchAnotherPageAsync(),
        rootMargin: '0px 0px 400px 0px',
    });

    const canRequestFinancingO = actions?.canRequestFinancingO;

    return (
        <div style={style}>
            <InvoicesListTable sellersEnabled={!!sellerExtractorO}
                               sort={sort}
                               setSort={setSort}>
                <tbody>
                    { Array.from(invoices, (i, invoiceIndex) =>
                        !!canRequestFinancingO
                            ? <InvoiceRowWithRequestFinancingWrapper<I, R> key={`invoice-${invoiceIndex}`}
                                                                           invoice={i}
                                                                           sellerExtractorO={sellerExtractorO}
                                                                           setCurrentDeclareBuyerPaymentO={!!actions?.setCurrentDeclareBuyerPaymentInvoiceO
                                                                               ? (() => actions.setCurrentDeclareBuyerPaymentInvoiceO!(i))
                                                                               : null
                                                                           }
                                                                           displayCannotFinanceModal={
                                                                               (!!canRequestFinancingO.cannotFinanceReasonO(i) && (() =>
                                                                                       canRequestFinancingO.setTextOnlyModal(canRequestFinancingO.cannotFinanceReasonO(i))
                                                                               ))
                                                                               || (i.payment.partialAmountPaidByBuyer >= i.amountTax + i.amountWoTax && (() =>
                                                                                   canRequestFinancingO.setTextOnlyModal(alreadyPaidInvoice))
                                                                               )
                                                                               || null
                                                                           }
                                                                           action={canRequestFinancingO.action}
                                                                           financingImpactExtractor={canRequestFinancingO.financingImpactExtractor}/>
                            : <InvoiceRow key={`invoice-${invoiceIndex}`}
                                          invoice={i}
                                          sellerExtractorO={sellerExtractorO}
                                          setCurrentDeclareBuyerPaymentO={!!actions?.setCurrentDeclareBuyerPaymentInvoiceO
                                              ? (() => actions.setCurrentDeclareBuyerPaymentInvoiceO!(i))
                                              : null
                                          }
                                          canRequestFinanceO={null} />
                    )}
                </tbody>
            </InvoicesListTable>

            {/* https://github.com/onderonur/react-infinite-scroll-hook#simple-example */}
            {(isIncrementalLoading || hasNextPage) && (
                <div ref={sentryRef}><Loader /></div>
            )}
        </div>
    );
}

const InvoicesList2 = <I extends IDSInvoice, R>(
    {
        queries,
        sellerExtractorO,
        sorter,
        actions,
        sort,
        setSort

    }: {
        queries: UseInfiniteQueryResult<IPaginated<I>>[];
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        sorter: (i1: I, i2: I) => number;
        actions: ILocalActions<I, R>;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) => {
    const factorContext = getFactorContext();

    return (
        <ReactQueryResultWrapper displayFullError={factorContext.debug}
                                 result={reactQueryResultCombiner<SortPaginationMerger<I>>(
                                     queries,
                                     () => new SortPaginationMerger<I>(
                                         queries
                                             .map((i) => {
                                                 const pages = i.data!.pages;
                                                 const lastPage = pages[pages.length - 1];
                                                 return ({
                                                     initial: getNodes(i.data!),
                                                     endCursor: lastPage.pageInfo.endCursor,
                                                     nextPageFetchO: (lastPage.pageInfo.hasNextPage && (async () => {
                                                         const result = await i.fetchNextPage();
                                                         // Je ne comprends pas le typage
                                                         const newResultPages = result.data!.pages;
                                                         return newResultPages[newResultPages.length - 1]; // Je ne comprends pas
                                                       })) || null
                                                   });
                                               }),
                                           sorter,
                                           (i) => base64Encode(i.id)
                                     )
                                 )}
                                 onLoading={() =>
                                     <div>
                                         <InvoicesListTable sellersEnabled={!!sellerExtractorO}
                                                            sort={sort}
                                                            setSort={setSort}/>
                                         <Loader/>
                                     </div>
                                 }
                                 onReloading={(oldInvoices: SortPaginationMerger<I>) =>
                                     <FullPageRefreshing>
                                         <InvoicesListLoaded<I, R> style={DefaultOpacityForReloadingStyle}
                                                                   invoices={oldInvoices}
                                                                   isIncrementalLoading={false}
                                                                   sellerExtractorO={sellerExtractorO}
                                                                   actions={actions}
                                                                   sort={sort}
                                                                   setSort={setSort} />
                                     </FullPageRefreshing>
                                 }
                                 onSuccess={(invoices: SortPaginationMerger<I>, isIncrementalLoading) =>
                                     <InvoicesListLoaded<I, R> invoices={invoices}
                                                               isIncrementalLoading={isIncrementalLoading}
                                                               sellerExtractorO={sellerExtractorO}
                                                               actions={actions}
                                                               sort={sort}
                                                               setSort={setSort} />
                                 } />
    );
}

const InvoicesList = <I extends IDSInvoice, R>(
    {
        queries,
        sellerExtractorO,
        sorter,
        actions,
        sort,
        setSort
    }: {
        queries: UseInfiniteQueryResult<IPaginated<I>>[];
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        sorter: (i1: I, i2: I) => number;
        actions: IActions<I, R>;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) =>
    <WithTextOnlyModal child={(setTextOnlyModalO: (newMessageO: string | null) => void) =>
        !!actions.canDeclareBuyerPaymentInvoiceO
            ? <WithDeclarationPayment action={actions.canDeclareBuyerPaymentInvoiceO.action}
                                      minInvoiceAmount={actions.canDeclareBuyerPaymentInvoiceO.minInvoiceAmount}
                                      child={(setCurrentDeclareBuyerPaymentInvoice) =>
                                          <InvoicesList2<I, R> queries={queries}
                                                               sellerExtractorO={sellerExtractorO}
                                                               sorter={sorter}
                                                               actions={{
                                                                   canRequestFinancingO: !!actions.canRequestFinancingO ? ({
                                                                       ...actions.canRequestFinancingO,
                                                                       setTextOnlyModal: setTextOnlyModalO,
                                                                   }) : null,
                                                                   setCurrentDeclareBuyerPaymentInvoiceO: setCurrentDeclareBuyerPaymentInvoice
                                                               }}
                                                               sort={sort}
                                                               setSort={setSort} />
                                      }/>
            : <InvoicesList2<I, R> queries={queries}
                                   sellerExtractorO={sellerExtractorO}
                                   sorter={sorter}
                                   actions={{
                                       canRequestFinancingO: !!actions.canRequestFinancingO ? ({
                                           ...actions.canRequestFinancingO,
                                           setTextOnlyModal: setTextOnlyModalO,
                                       }) : null,
                                       setCurrentDeclareBuyerPaymentInvoiceO: null
                                   }}
                                   sort={sort}
                                   setSort={setSort} />


    } />;

export default InvoicesList;
