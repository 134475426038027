
// Only for ds
export enum BuyerSortProperty {
    CreationDate = 'creationDateTime',
    Name = 'name',
    FinancedCount = 'financedCount'
}

export const BuyerSortPropertyToOrderByField: Map<BuyerSortProperty, string> = new Map([
    [ BuyerSortProperty.CreationDate, 'CREATION_DATE_TIME' ],
    [ BuyerSortProperty.Name, 'NAME' ],
    [ BuyerSortProperty.FinancedCount, 'FINANCED_COUNT' ]
]);
