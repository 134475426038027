import SideBarMenu, { ISideBarMenuItem } from './SideBarMenu';

import './sidebardesktop.scss';

const SideBarDesktop = (
    {
        className,
        menuId,
        menuItems,
        children
    }: {
        className?: string;
        menuId?: string;
        menuItems: ISideBarMenuItem[];
        children: any;
    }
) =>
    <div className={`p-sidebar${!!className ? ` ${className}` : ''}`}>
        <SideBarMenu id={menuId}
                     items={menuItems} />
        { children }
    </div>;

export default SideBarDesktop;
