import {Axios} from "axios";
import Loader from "factor-lib/Loader";
import {useQuery} from "@tanstack/react-query";
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper';
import CurrentEnv from "../../../envs/CurrentEnv";

const MetabaseNbJoursFinancedReady = (
    {
        customerAxios
    }: {
        customerAxios: Axios;
    }
) => {
    const tokenQueryResult = useQuery({
        queryKey: ['days-financed-token'],
        queryFn: () => customerAxios.get<string>('/metabase/financedDays')
            .then((d) => d.data)
    });

    return (
        <ReactQueryResultWrapper<string> result={tokenQueryResult}
                                         onLoading={() => <Loader/>}
                                         displayFullError={CurrentEnv.debug}
                                         onSuccess={(jwtToken) =>
                                             <iframe className='p-full-width p-full-height'
                                                     title='metabase'
                                                     src={`https://dimpl.metabaseapp.com/embed/dashboard/${jwtToken}#bordered=true&titled=true`}/>
                                        } />

    );
}

const MetabaseNbJoursFinanced = (
    {
        customerAxiosO
    }: {
        customerAxiosO: Axios | null;
    }
) =>
    !!customerAxiosO
        ? <MetabaseNbJoursFinancedReady customerAxios={customerAxiosO}/>
        : <Loader/>;

export default MetabaseNbJoursFinanced;