import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import { UseQueryOptions } from "@tanstack/react-query/src/types";
import { UseQueryResult } from "@tanstack/react-query";
import DataLoader from "dataloader";
import { IIntCompanyIdentifier, IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {ReactQueryNoNeedRefresh} from "factor-lib/reactquery/utils";

export interface IMeQueryProp<T> {
    name: string;
    graphQLFields: string;
    extractor: (d: any) => T;
}

export const meQueryKey = (prop: IMeQueryProp<any>) => ['me', prop.name];

export const meQuery = (prop: IMeQueryProp<any>): IGraphQLParams => ({
    query: ` query {
        me {
            ${prop.graphQLFields}
        }
    }`
});

// TODO: does not work for nexted queries such as { me {contact { phone } } } although it is not used for now
export const buildMeQueryConfig = (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    prop: IMeQueryProp<any>
): UseQueryOptions<any> => {
    const queryKey = meQueryKey(prop);
    return ({
        queryKey,
        queryFn: async ({signal}) => ({
            [prop.name]: prop.extractor((await graphQLDataLoader.load(
                {
                    query: meQuery(prop),
                    queryKey,
                    signal
                }
            )).me)
        }),
        // Should not change
        ...ReactQueryNoNeedRefresh
    });
}

export const buildMeQueriesConfig = (graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>, props: IMeQueryProp<any>[]) =>
    ({
        queries: props.map((prop) => buildMeQueryConfig(graphQLDataLoader, prop))
    });

// This only exists on directSeller
export const MeCannotFinanceReasonProp: IMeQueryProp<string> = {
    name: 'cannotFinanceReason',
    graphQLFields: 'cannotFinanceReason',
    extractor: (me) => me.cannotFinanceReason
};
export interface IMeCannotFinanceReason {
    cannotFinanceReason: string | null;
}

export const MeOutstandingLimitProp: IMeQueryProp<number> = {
    name: 'outstandingLimit',
    graphQLFields: 'outstandingLimit',
    extractor: (me) => me.outstandingLimit
};
export interface IMeOutstandingLimit {
    outstandingLimit: number;
}

export const MeOutstandingProp: IMeQueryProp<number> = {
    name: 'outstanding',
    graphQLFields: 'outstanding',
    extractor: (me) => me.outstanding
};
export interface IMeOutstanding {
    outstanding: number;
}

export const MeCompanyIdProp: IMeQueryProp<string> = {
    name: 'companyId',
    graphQLFields: 'company { id }',
    extractor: (me) => me.company.id
};

// Un peu bizarre
export interface IMeCompanyId {
    companyId: string;
}

export const MeCompanyIdentifierProp: IMeQueryProp<IIntCompanyIdentifier> = {
    name: 'companyIdentifier',
    graphQLFields: `company { identifier { ${IntCompanyIdentifierGraphQLFields} } }`,
    extractor: (me) => me.company.identifier
};
export interface IMeCompanyIdentifier {
    companyIdentifier: IIntCompanyIdentifier;
}

export const MeCompanyNameProp: IMeQueryProp<string> = {
    name: 'companyName',
    graphQLFields: 'company { name }',
    extractor: (me) => me.company.name
};
export interface IMeCompanyName {
    companyName: string;
}

export const MeMinInvoiceAmountProp: IMeQueryProp<number> = {
    name: 'minInvoiceAmount',
    graphQLFields: 'minInvoiceAmount',
    extractor: (me) => me.minInvoiceAmount
};
export interface IMeMinInvoiceAmount {
    minInvoiceAmount: number;
}

export const MeContactEmailProp: IMeQueryProp<string> = {
    name: 'contactEmail',
    graphQLFields: 'contact { email }',
    extractor: (me) => me.contact.email
};
export interface IMeContactEmail {
    contactEmail: string;
}

export const MeNewPricingAcceptationRequiredProp: IMeQueryProp<boolean> = {
    name: 'newPricingAcceptationRequired',
    graphQLFields: 'newPricingAcceptationRequired',
    extractor: (me) => me.newPricingAcceptationRequired
};
export interface IMeNewPricingAcceptationRequired {
    newPricingAcceptationRequired: boolean;
}

// TODO : does not look very specific

const differentValuesError = (newProperty: string, existingPropertyValue: any, newPropertyValue: any) =>
    new Error(`Different values for prop ${newProperty} : ${existingPropertyValue} vs ${newPropertyValue}`);

export const mergeObjects2 = (a: any, b: any): any => {
    let r: any = {...a};
    for (const newProperty in b) {
        const existingPropertyValue = r[newProperty];
        const newPropertyValue = b[newProperty];
        if (existingPropertyValue !== undefined) {
            // merge
            if (typeof existingPropertyValue === 'object') {
                if (existingPropertyValue === null || newPropertyValue === null) {
                    if (existingPropertyValue !== null || newPropertyValue !== null) {
                        throw differentValuesError(newProperty, existingPropertyValue, newPropertyValue);
                    }
                } else {
                    r[newProperty] = mergeObjects2(existingPropertyValue, newPropertyValue);
                }
            } else {
                if (existingPropertyValue !== newPropertyValue) {
                    throw differentValuesError(newProperty, existingPropertyValue, newPropertyValue);
                }
            }
        } else {
            // simple add
            r[newProperty] = newPropertyValue;
        }
    }
    return r;
}

// Looks very generic
export const mergeObjects = (datas: any[]): any =>
    datas.reduce(mergeObjects2, {});

// Looks very generic
export const mergeQueriesData = (meQueries: UseQueryResult<any>[]): any =>
    mergeObjects(meQueries.map((meQuery) => meQuery.data!));
