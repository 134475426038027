import IEnvConfig from "./IEnvConfig";
import TelemetryLogger from 'factor-lib/services/logger/TelemetryLogger';
import { TelemetryCustomerInstrumentationKey } from 'factor-lib/envs/devConfigFactory';
import ILogger from "factor-lib/services/logger/ILogger";
import GoogleTagManager from "../services/GTM/GoogleTagManager";
import loadIntercom from "factor-lib/services/Intercom/loadIntercom";
import WindowLogger from "factor-lib/services/logger/WindowLogger";
import CompositeLogger from "factor-lib/services/logger/CompositeLogger";

export const devTelemetry = (): ILogger => new TelemetryLogger(TelemetryCustomerInstrumentationKey)

const DevEnv: IEnvConfig = {
    // title: 'dev',

    logger: () => new CompositeLogger([devTelemetry(), new WindowLogger(false)]),

    baseUrl: 'https://lemon-moss-05296fb03.1.azurestaticapps.net',
    backends: {
        commonCustomer: {
            baseUrl: 'https://factor-dev-customer-api.azurewebsites.net',
            adScope: 'e88c1ea2-82a8-4bb9-a9f9-d56fcb8cfa3a/All'
        },
        directSeller: {
            baseUrl: 'https://factor-dev-directseller-api.azurewebsites.net',
            adScope: '26122441-ee59-4e35-87fd-65c729dd499f/Any'
        },
        marketplace: {
            baseUrl: 'https://factor-dev-marketplaces-api.azurewebsites.net',
            adScope: 'f6e07cf1-7ffb-4e82-8756-4a1e4744ca38/Any'
        }
    },
    azureAD: {
        domain: 'dimplfactortest',
        clientId: '52914361-1091-431e-af1a-f15a7cfe7e4d', // dev
        signinFlowName: 'B2C_1_first_sign_in' // https://dimplfactortest.b2clogin.com/dimplfactortest.onmicrosoft.com/B2C_1_first_sign_in
        // knownAuthorities: 'https://dimplfactortest.b2clogin.com', // dev
    },
    debug: true,

    gtm: () => new GoogleTagManager('GTM-KBZB537'), // Same as Predict, but may change

    intercom: loadIntercom,
    logMsalInfo: false
};

export default DevEnv;
