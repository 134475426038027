import Loader from "factor-lib/Loader";
import {CSSProperties, ReactNode} from "react";

export const DefaultOpacityForReloadingStyle: CSSProperties = ({
    opacity: '0.2'
});

const FullPageRefreshing = (
    {
        children
    }: {
        children: ReactNode;
    }
) =>
    <>
        { children }
        { /* Rely on a 'relative' element in the html tree. For now, this is in the dashboard layout. */}
        <div style={{position: 'absolute', zIndex: 1, left: 0, top: 0, right: 0, bottom: 0, margin: 'auto'}}
             className='p-both-center'>
            <Loader />
        </div>
    </>;

export default FullPageRefreshing;
