import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {
    requestFinancingUpdateQueriesAsync
} from "../utils/requestFinancingUtils";
import Button from "factor-lib/Buttons/Button";
import {REQUEST_FINANCING_ACTION_TEXT} from "../InvoiceStatus/InvoiceStatusMessages";
import { computeText } from 'factor-lib/Buttons/ButtonMutationEnabled';
import {useState} from "react";
import TextOnlyModal from "factor-lib/Modals/TextOnlyModal";
import {getFactorContext} from "../../IFactorContext";
import {IFinancingImpact} from "../InvoiceFinancingImpact";
import {IDSInvoiceWithDetails} from "./InvoiceDetailsQuery";

const FinancingRequestButton = <I extends IDSInvoiceWithDetails, R>(
    {
        className,
        invoice,
        cannotFinanceReasonO,
        action,
        financingImpactExtractor

    }: {
        className?: string;
        invoice: I;
        cannotFinanceReasonO: string | null;
        action: () => Promise<R>;
        financingImpactExtractor: (r: R) => IFinancingImpact;
    }
) => {
    const queryClient = useQueryClient();
    const factorContext = getFactorContext();

    const [displayCannotCoverModal, setDisplayCannotCoverModal] = useState(false);

    const requestFinancingMutation: UseMutationResult<R , any, void /* IDSInvoice */> =
        useMutation<R, any, void>(
            action,
            ({
                onSuccess: (response) => {
                    const impact = financingImpactExtractor(response);
                    return requestFinancingUpdateQueriesAsync(
                        queryClient,
                        invoice.id,
                        invoice.amountWoTax + invoice.amountTax,
                        invoice.payment.partialAmountPaidByBuyer,
                        impact.forBuyerO,
                        impact.invoicesWhichBecomeUneligible
                    );
                }
            })
        );

    return (
        <div className={className}>
            <Button id='requestFinancingButton'
                    text={computeText(REQUEST_FINANCING_ACTION_TEXT, requestFinancingMutation, factorContext.debug)}
                    isLoading={requestFinancingMutation.isLoading}
                    actionO={() => {
                        if (!!cannotFinanceReasonO) {
                            setDisplayCannotCoverModal(true);
                        } else {
                            requestFinancingMutation.mutate();
                        }
                    }} />

            { displayCannotCoverModal &&
                <TextOnlyModal close={() => setDisplayCannotCoverModal(false)}
                               message={cannotFinanceReasonO!}
                               maxWidth={null}
                               fullMaxWidth={false}
                               buttonsProps={{
                                   id: 'cannotCoverModal',
                                   text: 'Ok',
                                   isLoading: false,
                                   actionO: () => setDisplayCannotCoverModal(false)
                               }} />
            }
        </div>
    );
}

export default FinancingRequestButton;