import BuyerInvoiceInfosPartialPayment from "./BuyerInvoiceInfosPartialPayment";
import {IDSInvoiceWithDetails, IFinancingRequestAcceptedStatusWithDetails} from "./InvoiceDetailsQuery";
import AmountWithTaxes, {amountWithTaxesKind} from "../utils/AmountWithTaxes";
import {ReactNode} from "react";
import {serverDateDeserialization, userFormatDate } from "factor-lib/utils/dateUtils";
import { FR_LOCALE } from 'factor-lib/utils/utils';

const formatEffectiveNbOfDaysFinancedFormat = (new Intl.NumberFormat(
    FR_LOCALE,
    // (store.state.language as unknown as ILanguageWrapper).language, // Vuex typescript bug !
    {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    }
))

// 0,14 -> 0,1
// 0,15 -> 0,2
// 0,12 -> 0,2
export const formatEffectiveNbOfDaysFinanced = (effectiveNbOfDaysFinanced: number): string =>
    formatEffectiveNbOfDaysFinancedFormat.format((Math.floor(effectiveNbOfDaysFinanced * 10 - 0.5) + 0.5) / 10);

const InfoElement = (
    {
        title,
        bold,
        children
    }: {
        title: string;
        bold: boolean;
        children: ReactNode;
    }
) =>
    <div className='level'>
        <div className='level-left'>
            { `${title} :` }
        </div>
        <div className={`level-right${bold ? ' p-bold' : ''}`}>
            { children }
        </div>
    </div>;

const InvoiceInfosCard = (
    {
        amountWoTax,
        amountTax,
        issueDate,
        dueDate,
        buyer,
        status,
        payment
    }: IDSInvoiceWithDetails
) => {
    const invoiceAmountTTC = amountWoTax + amountTax;
    const partialAmountPaidByBuyer = payment.partialAmountPaidByBuyer;
    const { financingRequest } = status;
    const financingRequestAccepted = financingRequest?.accepted;
    const commingLingAmountToReturn = financingRequestAccepted?.commingLingAmountToReturn || 0;

    const financing: IFinancingRequestAcceptedStatusWithDetails | null = financingRequestAccepted || null;
    const notEligibleReason: string | null = (!financingRequest && status.eligibility!.notEligibleReason) || null;

    return (
        <div className='p-bg-white p-padding-left-4 p-padding-right-4 p-padding-top-3 p-padding-bottom-3'>
            <InfoElement title='Votre Acheteur'
                         bold={false}>
                { buyer.name }
            </InfoElement>
            <InfoElement title='Contact acheteur'
                         bold={false}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'end'
                    }}>
                    { buyer.emails.map((buyerEmail, index) =>
                        <div key={`be-${index}`}> { buyerEmail }</div>
                    ) }
                    { buyer.phones.map((buyerPhone, index) =>
                        <div key={`bp-${index}`}> { buyerPhone }</div>
                    ) }
                </div>
            </InfoElement>

            <InfoElement title="Date d'émission"
                         bold={false}>
                { userFormatDate(serverDateDeserialization(issueDate)) }
            </InfoElement>
            <InfoElement title="Date d'échéance"
                         bold={false}>
                { userFormatDate(serverDateDeserialization(dueDate)) }
            </InfoElement>

            <div className='p-horizontal-divider'/>

            { partialAmountPaidByBuyer > 0 && partialAmountPaidByBuyer < invoiceAmountTTC
                ? <BuyerInvoiceInfosPartialPayment amountWoTax={amountWoTax}
                                                   amountTax={amountTax}
                                                   partialAmountPaidByBuyer={partialAmountPaidByBuyer}
                                                   commingLingAmountToReturn={commingLingAmountToReturn} />
                : <div className='level p-padding-top-4'>
                    <div className='p-margin-bottom-6'>
                        Montant de la facture :
                    </div>
                    <AmountWithTaxes amountTTC={invoiceAmountTTC}
                                     amountWoTax={amountWoTax}
                                     kind={amountWithTaxesKind.KIND_LARGE} />
                </div>
            }

            { (!!financing || !!notEligibleReason) &&
                <div>
                    <div className='p-horizontal-divider p-margin-bottom-5'/>
                    { !!financing
                        ? <InfoElement title='Nombre de jours financés effectifs'
                                       bold={true}>
                            { formatEffectiveNbOfDaysFinanced(financing.effectiveNbOfDaysFinanced)  }
                        </InfoElement> : <InfoElement title='Raison de non éligibilité'
                                                      bold={true}>
                            { notEligibleReason }
                        </InfoElement>
                    }
                </div>
            }
        </div>
    );
};

export default InvoiceInfosCard;
