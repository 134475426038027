import { useInfiniteQuery, UseInfiniteQueryResult } from "@tanstack/react-query";
import { ReactElement } from "react";
import { IPaginated, query } from "factor-lib/utils/graphQLPagination";
import { IBaseComputation, getInvoicesStatusFiltersBaseComputations, InvoicesStatusFilters } from "./StatusFilters";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import { UseInfiniteQueryOptions } from "@tanstack/react-query/src/types";
import { InvoicesBaseQueryKey } from "./AllInvoicesQuery";
import { IDSInvoice, IInvoicesGraphQLQueryProviderParams } from "./FilteredInvoices";
import { InvoiceSortProperty, InvoiceSortPropertyToOrderByField } from "./invoicesSortProperties";
import { ISort, SortOrderToOrderByDirection } from "factor-lib/utils/sortingUtils";


const InvoicesFilteredListQueryKey: string[] =
    [InvoicesBaseQueryKey, 'filteredList'];

export const invoicesFilteredListQueryKey = (computation: IBaseComputation): string[] =>
    [...InvoicesFilteredListQueryKey, computation.name];

interface IQueryContext {
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>;
    invoicesGraphQLQueryProvider: (p: IInvoicesGraphQLQueryProviderParams) => string;
    pageSize: number;
    searchFilter: string;
    sort: ISort<InvoiceSortProperty>;
}

const getCommonVariables = (queryContext: IQueryContext): Record<string, any> => ({
    first: queryContext.pageSize,
    search: !!queryContext.searchFilter ? queryContext.searchFilter : null,
    orderByField: InvoiceSortPropertyToOrderByField.get(queryContext.sort.property)!,
    orderByDirection: SortOrderToOrderByDirection.get(queryContext.sort.order)!
});

const infiniteQueryOption = <I extends IDSInvoice, >(
    queryContext: IQueryContext,
    computation: IBaseComputation
): UseInfiniteQueryOptions<IPaginated<I>> => {
    const queryKey = [...invoicesFilteredListQueryKey(computation), queryContext.sort.property, queryContext.sort.order, queryContext.searchFilter];
    
    // alias in graphql query to avoid merge
    const alias = `invoices_filtered_${computation.name}`;

    return ({
        queryKey,
        queryFn: async ({signal, pageParam = null /* Why default ? */}) => {
            const cn = computation.name;

            const variables: Record<string, any> = getCommonVariables(queryContext);
            variables[`status_${cn}`] = computation.status;
            variables[`after_${cn}`] = pageParam;

            const fullR: any = await queryContext.graphQLDataLoader.load({
                query: {
                    query: `query ($status_${cn}: CustomerInvoiceStatusEnum!, $first: Int!, $after_${cn}: PaginationGuidCursor, $search: String, $orderByField: InvoicePaginationOrderByField!, $orderByDirection: PaginationOrderByDirection!) {
                        ${alias}: 
                            invoices(
                                statuses: [$status_${cn}],
                                search: $search,
                                first: $first,
                                after: $after_${cn},
                                orderByField: $orderByField,
                                orderByDirection: $orderByDirection
                            )  ${query(
                                queryContext.invoicesGraphQLQueryProvider({
                                    eligibilityO: !!computation.eligibilityO
                                        ? ({ fetch: !computation.eligibilityO.valueO })
                                        : null
                                })
                            )} 
                    }`,
                    variables
                },
                queryKey,
                signal
            });

            const r = fullR[alias] as IPaginated<I>;

            return ({
                ...r,
                edges: r.edges.map((e) => ({
                    ...e,
                    node: {
                        ...e.node,
                        status: !!computation.eligibilityO
                            ? {
                                eligibility: computation.eligibilityO.valueO ?? e.node.status.eligibility!,
                                financingRequest: null
                            }
                            : {
                                eligibility: null,
                                financingRequest: e.node.status.financingRequest!
                            }
                    }
                }))
            });
        },
        getNextPageParam: (lastPage, _) => lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined
    });
};

const WithInvoiceStatusInfiniteQuery = <I extends IDSInvoice, > (
    {
        queryContext,
        currentQueries,
        computation,
        childrenFactory
    }: {
        queryContext: IQueryContext;
        currentQueries: Array<UseInfiniteQueryResult<IPaginated<I>>>;
        computation: IBaseComputation;
        childrenFactory: (queries: Array<UseInfiniteQueryResult<IPaginated<I>>>) => ReactElement;
    }
) => {
    const newQuery: UseInfiniteQueryResult<IPaginated<I>> = useInfiniteQuery(infiniteQueryOption(queryContext, computation));

    // When the query status changes, react does not rerender the children
    // TODO: understand why and remove this fix
    return childrenFactory([...currentQueries, {...newQuery}]);
}

const ProcessAllRecursive = <I extends IDSInvoice, >(
    {
        queryContext,
        computations,
        computationIndex,
        queriesBeforeComputation,
        final
    }: {
        queryContext: IQueryContext;
        computations: IBaseComputation[];
        computationIndex: number;
        queriesBeforeComputation: Array<UseInfiniteQueryResult<IPaginated<I>>>;
        final: (allQueries: UseInfiniteQueryResult<IPaginated<I>>[]) => ReactElement;
    }
) =>
    computationIndex >= computations.length
        ? final(queriesBeforeComputation)
        : <WithInvoiceStatusInfiniteQuery queryContext={queryContext}
                                          currentQueries={queriesBeforeComputation}
                                          computation={computations[computationIndex]}
                                          childrenFactory={(queriesAfterComputation: Array<UseInfiniteQueryResult<IPaginated<I>>>) =>
                                              <ProcessAllRecursive queryContext={queryContext}
                                                                   computations={computations}
                                                                   computationIndex={computationIndex + 1}
                                                                   queriesBeforeComputation={queriesAfterComputation}
                                                                   final={final} />
                                          }/>;

const ProcessAll = <I extends IDSInvoice, >(
    {
        statusFilters,
        queryContext,
        final
    }: {
        statusFilters: number[];
        queryContext: IQueryContext;
        final: (allQueries: UseInfiniteQueryResult<IPaginated<I>>[]) => ReactElement;
    }
) => {
    const statusesSet: Set<number> = new Set(statusFilters);
    const computations = getInvoicesStatusFiltersBaseComputations(InvoicesStatusFilters.filter((_, i) => statusesSet.has(i)));

    return (
        <ProcessAllRecursive queryContext={queryContext}
                             computations={computations}
                             computationIndex={0}
                             queriesBeforeComputation={[]}
                             final={final} />
    );
}

export default ProcessAll;