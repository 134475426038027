import IEnvConfig from "./IEnvConfig";
import LocalEnv from "./LocalEnv";
import DevEnv from "./DevEnv";
import DemoEnv from "./DemoEnv";
import ProdEnv from "./ProdEnv";
import { getEnv, ensureNonNullEnvName, envError } from 'factor-lib/utils/envUtils';

const nonTestEnv = (): IEnvConfig => {
    const currentConfigO: IEnvConfig | null = getEnv(() => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME), LocalEnv, DevEnv, DemoEnv, ProdEnv);
    return !!currentConfigO ? currentConfigO : envError();
}

const currentConfigEnv: IEnvConfig =
    process.env.NODE_ENV !== 'test'
        ? nonTestEnv()
        : null as unknown as IEnvConfig; // TODO : improve

export default currentConfigEnv;
