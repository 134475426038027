import { useState } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import InputAmount, { formatAmountToInput, isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import ButtonMutation from "factor-lib/Buttons/ButtonMutation";
import { Axios } from "axios";
import { getFactorContext } from "../../../IFactorContext";
import { updateBuyerInList } from "./updateBuyersQuery";

interface IUpdateBuyerAskedOutstandingParams {
    buyerId: string;
    askedOutstanding: number;
}

// Not exactly same as in lib : oldValue can be null
export const isValidNewAmountInput = (oldValue: number | null, newInput: string) =>
    isValidAmount(newInput) && (oldValue === null || (parseInputAmount(newInput) !== oldValue));

const BuyerAskedOutstandingModalContent = (
    {
        directSellerAxios,
        buyerId,
        currentAskedOutstanding,
        closeModal,
    }: {
        directSellerAxios: Axios;
        buyerId: string;
        currentAskedOutstanding: number | null;
        closeModal: () => void;
    }
) => {
    const factorContext = getFactorContext();
    const [newAskedOutstandingInput, setNewAskedOutstandingInput] =
        useState<string>(currentAskedOutstanding !== null ? formatAmountToInput(currentAskedOutstanding) : '');

    const queryClient = useQueryClient();

    const setBuyerAskedOutstandingMutation: UseMutationResult<void, any, IUpdateBuyerAskedOutstandingParams> =
        useMutation<void, any, IUpdateBuyerAskedOutstandingParams>(
            async (buyerAskedOutstanding) => {
                (await directSellerAxios.put<void>(
                    `/directSellerBuyers/${buyerAskedOutstanding.buyerId}/askedOutstanding`,
                    {
                        askedOutstanding: buyerAskedOutstanding.askedOutstanding
                    }
                ));
            },
            ({
                onSuccess: (_, p) => {
                    updateBuyerInList(
                        queryClient,
                        buyerId,
                        (b) => ({
                            ...b,
                            askedOutstanding: p.askedOutstanding
                        })
                    );
                    closeModal();
                },
            })
        );

    return (
        <div className='p-padding-3 p-vertical-stretch'>
            <div className='field'>
                Nouvel encours :
            </div>
            <div className='field'>
                <InputAmount className='field p-full-width'
                             fieldName='Encours demandé'
                             inputAmountValue={newAskedOutstandingInput}
                             enabled={{
                                 updateInputValue: setNewAskedOutstandingInput,
                                 innerId: {
                                     value: 'askedOutstandingInput',
                                     autofocus: true
                                 },
                                 tryComplete: () => {
                                     const newAskedOutstandingO = isValidNewAmountInput(currentAskedOutstanding, newAskedOutstandingInput);
                                     if (newAskedOutstandingO !== null) {
                                         setBuyerAskedOutstandingMutation.mutate({
                                             askedOutstanding: parseInputAmount(newAskedOutstandingInput),
                                             buyerId
                                         });
                                     }
                                 }
                             }}/>
            </div>

            <div className='p-vertical-center p-margin-top-5'>
                <ButtonMutation<void, IUpdateBuyerAskedOutstandingParams> id='updateAskedOutstanding'
                                                                          text='Mettre à jour'
                                                                          mutation={setBuyerAskedOutstandingMutation}
                                                                          displayFullError={factorContext.debug}
                                                                          valueO={isValidNewAmountInput(currentAskedOutstanding, newAskedOutstandingInput) ? ({
                                                                              value: ({
                                                                                  askedOutstanding: parseInputAmount(newAskedOutstandingInput),
                                                                                  buyerId
                                                                              })
                                                                          }) : null} />
            </div>
        </div>
    );
}

export default BuyerAskedOutstandingModalContent;