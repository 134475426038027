import TitledModal from "factor-lib/Modals/TitledModal";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {
    IDSInvoiceWithDetails, IInvoiceDetailsPaymentStatus,
    IInvoiceDetailsQueryResult,
    invoiceDetailsQueryKey,
    PaymentTypeCommingLing,
    PaymentTypeNonReporting
} from "../InvoiceDetails/InvoiceDetailsQuery";
import { updateInvoicesHeaderAndFiltersCountQueries, updateInvoiceInFilteredList } from "../Dashboard/Invoices/updateInvoicesQuery";
import { IInvoicesHeader } from "../Dashboard/Invoices/Header";
import { formatAmount } from "factor-lib/utils/amountUtils";
import { dateDiffDays, serverDateSerialization } from "factor-lib/utils/dateUtils";
import { IInvoicesFiltersCount } from "../Dashboard/Invoices/FiltersCount";
import DeclarePayment from 'factor-lib/DeclarePayment/DeclarePayment';
import { EligibleComputation, FinancedComputation, FinancingRequestedComputation, FinancingRequestRejectedComputation, NotEligibleComputation, PendingComputation } from "../Dashboard/Invoices/StatusFilters";
import { invoicesFilteredListQueryKey } from "../Dashboard/Invoices/InfiniteQueries";
import IDeclareBuyerPaymentParams from "../Dashboard/Invoices/InvoiceList/actions/IDeclareBuyerPaymentParams";


export interface IFinancingAndFinanced {
    isEligible: boolean | null;
    isFinancingRequested: {
        // one or the other
        isRejected: boolean;
        isFinanced: boolean;
    } | null;
}

const InvoiceAlreadyPaidPublicMessage: string = 'Facture payée hors financement';
const invoiceUnderLimitPublicMessage = (invoiceAmountLeft: number, minInvoiceAmount: number): string =>
    `Montant restant de la facture ${formatAmount(invoiceAmountLeft)} inférieur au seuil minimal de ${formatAmount(minInvoiceAmount)}€`;

export const updateInvoiceDetailsPayment = (
    oldInvoiceDetailsPayment: IInvoiceDetailsPaymentStatus,
    isFinanced: boolean,
    completePaidDateIfNotFinanced: string | null,
    paymentAmount: number,
    newInvoiceAmountLeft: number
): IInvoiceDetailsPaymentStatus =>
    ({
        ...oldInvoiceDetailsPayment,
        completePaidDate: isFinanced
            ? null
            : completePaidDateIfNotFinanced,
        partialAmountPaidByBuyer:
            oldInvoiceDetailsPayment.partialAmountPaidByBuyer + paymentAmount,
        payments: [
            ...oldInvoiceDetailsPayment.payments,
            {
                dateTime: serverDateSerialization(new Date()),
                amount: paymentAmount,
                isComplete: newInvoiceAmountLeft <= 0,
                base: {
                    type: isFinanced ? PaymentTypeCommingLing : PaymentTypeNonReporting
                }
            }
        ]
    });

const updateInvoiceDetails = (
    oldInvoiceDetails: IDSInvoiceWithDetails,
    completePaidDateIfNotFinanced: string | null,
    paymentAmount: number,
    newInvoiceAmountLeft: number,
    notEligibleReasonIfNotFinanced: string | null
): IDSInvoiceWithDetails =>
    ({
        ...oldInvoiceDetails,
        payment: updateInvoiceDetailsPayment(
            oldInvoiceDetails.payment,
            !!oldInvoiceDetails.status.financingRequest?.accepted,
            completePaidDateIfNotFinanced,
            paymentAmount,
            newInvoiceAmountLeft
        ),
        status: {
            ...oldInvoiceDetails.status,
            financingRequest: !!oldInvoiceDetails.status.financingRequest ? {
                ...oldInvoiceDetails.status.financingRequest,
                accepted: !!oldInvoiceDetails.status.financingRequest.accepted ? {
                    ...oldInvoiceDetails.status.financingRequest.accepted,
                    commingLingAmountToReturn: oldInvoiceDetails.status.financingRequest.accepted.commingLingAmountToReturn + paymentAmount
                } : null
            } : null,
            eligibility: !!notEligibleReasonIfNotFinanced && oldInvoiceDetails.status.eligibility!.isEligible !== false
                ? {
                    isEligible: false,
                    notEligibleReason: notEligibleReasonIfNotFinanced
                } : oldInvoiceDetails.status.eligibility
        }
    });

export const DeclareBuyerPaymentModalContent = (
    {
        action,
        invoiceId,
        invoiceAmountTTC,
        partialAmountPaidByBuyer,
        invoiceIssueDate,
        invoiceDueDate,
        invoiceStatus,
        minInvoiceAmount,
        closeModal
    }: {
        action: (p: IDeclareBuyerPaymentParams) => Promise<void>;
        invoiceId: string;
        invoiceAmountTTC: number;
        partialAmountPaidByBuyer: number;
        invoiceIssueDate: Date;
        invoiceDueDate: Date;
        invoiceStatus: IFinancingAndFinanced;
        minInvoiceAmount: number;
        closeModal: () => void;
    }
) => {
    const queryClient = useQueryClient();

    const amountRemainingDueByBuyer = invoiceAmountTTC - partialAmountPaidByBuyer;

    const declarePaymentInvoiceMutation: UseMutationResult<void, any, IDeclareBuyerPaymentParams> =
        useMutation<void, any, IDeclareBuyerPaymentParams>(
            action,
            ({
                onSuccess: (_, params) => {
                    // add the payment for the list of invoice and the invoice details (if already fetched)

                    const paymentAmount: number = params.partialAmount ?? amountRemainingDueByBuyer;
                    const newAmountRemainingDueByBuyer: number = amountRemainingDueByBuyer - paymentAmount;

                    const completePaidDateIfNotFinanced: string | null = newAmountRemainingDueByBuyer <= 0
                        ? serverDateSerialization(new Date())
                        : null;

                    const notEligibleReasonIfNotFinancingRequested: string | null = !!completePaidDateIfNotFinanced
                        ? InvoiceAlreadyPaidPublicMessage
                        : newAmountRemainingDueByBuyer < minInvoiceAmount
                            ? invoiceUnderLimitPublicMessage(newAmountRemainingDueByBuyer, minInvoiceAmount)
                            : null;

                    updateInvoicesHeaderAndFiltersCountQueries(
                        queryClient,
                        (oldHeader: IInvoicesHeader) => ({
                            ...oldHeader,
                            totalEligible: !invoiceStatus.isFinancingRequested && invoiceStatus.isEligible === true && !!notEligibleReasonIfNotFinancingRequested
                                ? oldHeader.totalEligible - invoiceAmountTTC
                                : oldHeader.totalEligible,
                            totalActive: (!!invoiceStatus.isFinancingRequested && !invoiceStatus.isFinancingRequested.isRejected && !invoiceStatus.isFinancingRequested.isFinanced)
                                ? oldHeader.totalActive - paymentAmount
                                : oldHeader.totalActive,
                            totalUpcoming: dateDiffDays(new Date(), invoiceDueDate) >= 0 && !invoiceStatus.isFinancingRequested?.isRejected && !invoiceStatus.isFinancingRequested?.isFinanced
                                ? oldHeader.totalUpcoming - paymentAmount
                                : oldHeader.totalUpcoming
                        }),
                        (oldFiltersCount: IInvoicesFiltersCount) => !invoiceStatus.isFinancingRequested && invoiceStatus.isEligible !== false && !!notEligibleReasonIfNotFinancingRequested ? ({
                            ...oldFiltersCount,
                            notEligible: oldFiltersCount.notEligible + 1,
                            eligible: invoiceStatus.isEligible === true
                                ? oldFiltersCount.eligible - 1
                                : oldFiltersCount.eligible,
                            pending: invoiceStatus.isEligible === null
                                ? oldFiltersCount.pending - 1
                                : oldFiltersCount.pending
                        }) : oldFiltersCount
                    );

                    const oldComputation = !!invoiceStatus.isFinancingRequested
                        ? invoiceStatus.isFinancingRequested.isFinanced
                            ? FinancedComputation
                            : invoiceStatus.isFinancingRequested.isRejected
                                ? FinancingRequestRejectedComputation
                                : FinancingRequestedComputation
                        : invoiceStatus.isEligible === true
                            ? EligibleComputation
                            : invoiceStatus.isEligible === false
                                ? NotEligibleComputation
                                : PendingComputation;

                    const newComputation = !invoiceStatus.isFinancingRequested && !!notEligibleReasonIfNotFinancingRequested
                        ? NotEligibleComputation
                        : oldComputation;

                    let r: Promise<void>[] = [];

                    if (oldComputation !== newComputation) {
                        r = [
                            queryClient.invalidateQueries(invoicesFilteredListQueryKey(oldComputation)),
                            queryClient.invalidateQueries(invoicesFilteredListQueryKey(newComputation))
                        ];
                    } else {
                        updateInvoiceInFilteredList(
                            queryClient,
                            oldComputation,
                            invoiceId,
                            (i) => !i.status.financingRequest?.accepted
                                ? /* commingLingBeforeFinancing -> not reporting */ {
                                    ...i,
                                    payment: {
                                        ...i.payment,
                                        completePaidDate: completePaidDateIfNotFinanced,
                                        partialAmountPaidByBuyer: i.payment.partialAmountPaidByBuyer + paymentAmount
                                    }
                                }
                                : /* commingLing -> montant à reverser */ {
                                    ...i,
                                    payment: {
                                        ...i.payment,
                                        partialAmountPaidByBuyer: i.payment.partialAmountPaidByBuyer + paymentAmount
                                    },
                                    status: {
                                        ...i.status,
                                        financingRequest: {
                                            ...i.status.financingRequest,
                                            accepted: {
                                                ...i.status.financingRequest.accepted,
                                                commingLingAmountToReturn: i.status.financingRequest.accepted.commingLingAmountToReturn + paymentAmount
                                            }
                                        }
                                    }
                                }
                        );
                    }

                    queryClient.setQueryData<IInvoiceDetailsQueryResult<IDSInvoiceWithDetails>>(
                        invoiceDetailsQueryKey(invoiceId),
                        (old: IInvoiceDetailsQueryResult<IDSInvoiceWithDetails> | undefined) => !!old ? ({
                            ...old,
                            invoiceDetails: updateInvoiceDetails(
                                old.invoiceDetails,
                                completePaidDateIfNotFinanced,
                                paymentAmount,
                                // financingRequestedAndFinanced,
                                amountRemainingDueByBuyer,
                                notEligibleReasonIfNotFinancingRequested
                            )
                        }) : undefined
                    );

                    // On vient juste de mofidier les montants à reverser, pas les encours

                    closeModal();

                    return Promise.all(r);
                }
            })
        );

    return (
        <DeclarePayment invoiceAmountTTC={invoiceAmountTTC}
                        amountRemainingDueByBuyer={amountRemainingDueByBuyer}
                        partiallyAlreadyPaidByBuyer={partialAmountPaidByBuyer}
                        minDateO={invoiceIssueDate}
                        ok={(date: Date, partialAmount: number | null) =>
                            declarePaymentInvoiceMutation.mutate({
                                date,
                                partialAmount
                            })
                        }
                        isLoading={declarePaymentInvoiceMutation.isLoading}
                        close={closeModal} />
    );
}

const DeclareBuyerPaymentModal = (
    {
        action,
        invoiceId,
        invoiceAmountTTC,
        partialAmountPaidByBuyer,
        invoiceIssueDate,
        invoiceDueDate,
        invoiceStatus,
        minInvoiceAmount,
        closeModal
    }: {
        action: (p: IDeclareBuyerPaymentParams) => Promise<void>;
        invoiceId: string;
        invoiceAmountTTC: number;
        partialAmountPaidByBuyer: number;
        invoiceIssueDate: Date;
        invoiceDueDate: Date;
        invoiceStatus: IFinancingAndFinanced;
        minInvoiceAmount: number;
        closeModal: () => void;
    }
) =>
    <TitledModal id='buyerPaymentModal'
                 close={closeModal}
                 active={true}
                 maxWidth='600px'
                 title='Déclarer un paiement de votre Acheteur'>
        <DeclareBuyerPaymentModalContent invoiceId={invoiceId}
                                         action={action}
                                         invoiceAmountTTC={invoiceAmountTTC}
                                         partialAmountPaidByBuyer={partialAmountPaidByBuyer}
                                         invoiceIssueDate={invoiceIssueDate}
                                         invoiceDueDate={invoiceDueDate}
                                         invoiceStatus={invoiceStatus}
                                         minInvoiceAmount={minInvoiceAmount}
                                         closeModal={closeModal}/>
    </TitledModal>;

export default DeclareBuyerPaymentModal;
