import {
    buildMeQueriesConfig,
    IMeCannotFinanceReason,
    IMeMinInvoiceAmount,
    MeCannotFinanceReasonProp,
    MeMinInvoiceAmountProp, mergeQueriesData
} from "../utils/Me";
import InvoiceDetailsPagePostRedirect from "./InvoiceDetailsPagePostRedirect";
import {Axios, AxiosRequestConfig} from "axios";
import {IDSInvoiceWithDetails} from "./InvoiceDetailsQuery";
import Loader from "factor-lib/Loader";
import {IRequestFinancingResponseDS, requestFinancingDSAsync} from "../utils/requestFinancingUtils";
import IDeclareBuyerPaymentParams from "../Dashboard/Invoices/InvoiceList/actions/IDeclareBuyerPaymentParams";
import {useQueries, UseQueryResult} from "@tanstack/react-query";
import {getFactorContext} from "../../IFactorContext";
import DataLoader from "dataloader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import reactQueryResultCombiner from "factor-lib/reactquery/reactQueryResultCombiner";
import ReactQueryResultWrapper from "factor-lib/reactquery/ReactQueryResultWrapper";

type IDSMe = IMeMinInvoiceAmount & IMeCannotFinanceReason;

const InvoiceDetailsPagePostRedirectDS = (
    {
        invoiceId,
        directSellerAxios
    }: {
        invoiceId: string;
        directSellerAxios: Axios;
    }
) => {

    const factorContext = getFactorContext();

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
            directSellerAxios,
            factorContext.logger,
            graphQLQueries
        )
    );

    const meQueries: UseQueryResult<any>[] = useQueries(
        buildMeQueriesConfig(
            graphQLDataLoader,
            [MeMinInvoiceAmountProp, MeCannotFinanceReasonProp]
        )
    );

    return (
        <ReactQueryResultWrapper result={reactQueryResultCombiner<IDSMe>(meQueries, () => mergeQueriesData(meQueries))}
                                 displayFullError={factorContext.debug}
                                 onLoading={() => <Loader />}
                                 onSuccess={(me: IDSMe) =>
                                     <InvoiceDetailsPagePostRedirect<IDSInvoiceWithDetails, IRequestFinancingResponseDS>
                                         invoiceId={invoiceId}
                                         graphQLDataLoader={graphQLDataLoader}
                                         sellerGraphQLSelection=''
                                         actionsProvider={() => ({
                                             canRequestFinancingO: {
                                                 cannotFinanceReasonO: me.cannotFinanceReason,
                                                 action: () =>
                                                     requestFinancingDSAsync(directSellerAxios, invoiceId),
                                                 financingImpactExtractor: (r: IRequestFinancingResponseDS) => ({
                                                     forBuyerO: r.financingImpact.forBuyer,
                                                     invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                                                 })
                                             },
                                             canDeclareBuyerPaymentInvoiceO: {
                                                 action: async (params: IDeclareBuyerPaymentParams) =>
                                                     (await directSellerAxios.post<void>(
                                                         `/directSellerInvoices/${invoiceId}/buyerPayment`,
                                                         params
                                                     )).data,
                                                 minInvoiceAmount: me.minInvoiceAmount
                                             },
                                             canUploadAdditionalFileO: {
                                                 action: async (file: File, config: AxiosRequestConfig) => {
                                                     const data = new FormData();
                                                     data.append('file', file);
                                                     return (await directSellerAxios.post<string>(
                                                         `/directSellerInvoiceFiles/${invoiceId}/additional`,
                                                         data,
                                                         config
                                                     )).data;
                                                 }
                                             }
                                         })} />
                                 } />
    );
}


export default InvoiceDetailsPagePostRedirectDS;