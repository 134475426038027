import {useState} from "react";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import ICurrentFileUpload from "factor-lib/forms/Upload/ICurrentFileUpload";
import UploadFileWrapper from "factor-lib/forms/Upload/UploadFileWrapper";
import IUrlWithContentType from "factor-lib/forms/Upload/IUrlWithContentType";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import { IMindeeConflictingField, IMindeePrediction } from "./processMindeePrediction";
import extractAxiosErrorMessage from 'factor-lib/serverUtils/extractAxiosErrorMessage';
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import {getFactorContext} from "../../IFactorContext";
import Modal from "factor-lib/Modal";
import Button from "factor-lib/Buttons/Button";

export interface IInvoiceFileAddedWithMindeeDto {
    file: IInvoiceFile;
    mindeePrediction: IMindeePrediction | null; // null -> mindee error
}

export interface IInvoiceFile {
    id: string;
    urlWithContentType: IUrlWithContentType;
}

const uploadFileAsync = async (
    axiosAction: (/* file: File, */ config: AxiosRequestConfig) => Promise<AxiosResponse<IInvoiceFileAddedWithMindeeDto>>,
    // directSellerAxios: Axios,
    file: File,
    setFileUpload: (fileUpload: ICurrentFileUpload | null) => void,
    setInvoiceFile: (invoiceFile: IInvoiceFile) => void,
    setError: (errorMessage: string) => void,
    processMindeePrediction: (mindeePrediction: IMindeePrediction) => void,
    debug: boolean
): Promise<void> => {
    // const data = new FormData();
    // data.append('file', file);

    setFileUpload({ filename: file.name, percentUpload: 0 });

    await axiosAction(
        // file,
        {
            onUploadProgress: (progressEvent) => setFileUpload({
                filename: file.name,
                percentUpload: progressEvent.total !== undefined ? Math.floor(progressEvent.loaded * 100 / progressEvent.total) : 50 /* TODO : what does it mean ? */
            }),
            timeout: FileUploadTimeoutMilliseconds
        }
    )/* directSellerAxios.post<IUploadInvoiceFileResponse>(
        `/directSellerInvoiceFiles`,
        data,
    ) */
        .finally(() => setFileUpload(null))
        .then((response) => {
            const mindeePrediction = response.data.mindeePrediction;
            if (!!mindeePrediction) {
                processMindeePrediction(mindeePrediction);
            }
            setInvoiceFile(response.data.file);
        })
        .catch((e: any) =>
            setError(extractAxiosErrorMessage(e, debug ? null : { displayShortDefaultError: false }))
        );
}

const ProgressBar = (
    {
        fileUpload
    }: {
        fileUpload: ICurrentFileUpload;
    }
) =>
    <div className='p-overlappingProgressBarWrapper'>
        <div className='p-overlappingProgressBar'
             style={{ width: `${fileUpload.percentUpload}%` }}
        />
        <div className='p-full-container p-both-center'>
            <div style={{zIndex: 2}}>{ fileUpload.filename }</div>
        </div>
    </div>;

const InvoiceUploadMindeeModal = (
    {
        conflictingFields,
        closeModal
    }: {
        conflictingFields: IMindeeConflictingField[];
        closeModal: () => void;
    }
) =>
    <Modal id='invoiceAddMindeeModal'
           maxWidth={null}
           fullMaxWidth={false}
           close={closeModal}>
        <div className='p-padding-4 p-vertical-center'>
            Il semblerait que les informations suivantes ne correspondent pas à ce que nous avons
            détecté sur la facture. Veuillez revérifier ces informations :

            <div className='p-margin-top-6 p-vertical-center'>
                <ul style={{listStyle: 'circle'}}>
                    { conflictingFields.map((conflictingField, index) => (
                        <li key={index}>
                            {conflictingField.fieldName} :
                            renseigné <span className='p-bold'>{conflictingField.informed}</span>
                            , détecté <span className='p-bold'>{conflictingField.detected}</span>
                        </li>
                    )) }
                </ul>
            </div>

            <Button id='invoiceAddMindeeModalClose'
                    className='p-margin-top-5'
                    text='Ok'
                    isLoading={false}
                    actionO={closeModal} />

        </div>
    </Modal>;

export const invoiceUploadFileId = 'invoiceAddUploadFile';

const InvoiceUploadFile = (
    {
        axiosAction,
        // directSellerAxios,
        invoiceFile,
        setInvoiceFile,
        processMindeePrediction
    }: {
        axiosAction: (file: File, config: AxiosRequestConfig) => Promise<AxiosResponse<IInvoiceFileAddedWithMindeeDto>>;
        // directSellerAxios: Axios;
        invoiceFile: IInvoiceFile | null;
        setInvoiceFile: (file: IInvoiceFile) => void;
        processMindeePrediction: (p: IMindeePrediction, s: (f: IMindeeConflictingField[]) => void) => void;
    }
) => {
    const factorContext = getFactorContext();
    const [fileUpload, setFileUpload] = useState<ICurrentFileUpload | null>(null);
    const [uploadErrors, setUploadErrors] = useState<string | null>(null);

    // TODO : remove, only keep the mindee response ?
    const [mindeeConflictingFields, setMindeeConflictingFields] = useState<IMindeeConflictingField[] | null>(null);

    return (
        <>
            { !!fileUpload
                ? <ProgressBar fileUpload={fileUpload} />
                : !!invoiceFile
                    ? <object data={invoiceFile.urlWithContentType.url}
                              className='p-flex-1'
                              type={invoiceFile.urlWithContentType.contentType}>
                        { /* TODO */ }
                    </object>
                    : <UploadFileWrapper id={invoiceUploadFileId}
                                         className='p-flex-1'
                                         linkId='InvoiceAddUploadFileLink'
                                         acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                                         externalInitialErrors={!!uploadErrors ? [uploadErrors] : null}
                                         selectFileAction={async (file: File) => await uploadFileAsync(
                                             (config: AxiosRequestConfig) => axiosAction(file, config),
                                             // directSellerAxios,
                                             file,
                                             setFileUpload,
                                             setInvoiceFile,
                                             setUploadErrors,
                                             (p) => processMindeePrediction(p, setMindeeConflictingFields),
                                             factorContext.debug
                                         )} />
            }

            
            { !!mindeeConflictingFields && mindeeConflictingFields.length > 0 &&
                <InvoiceUploadMindeeModal conflictingFields={mindeeConflictingFields}
                                          closeModal={() => setMindeeConflictingFields(null)} />
            }
        </>
    );
}

export default InvoiceUploadFile;
