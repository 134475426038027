import { QueryClient } from "@tanstack/react-query";
import { IInvoicesHeader, InvoicesHeaderQueryKey } from "./Header";
import { IDSInvoice } from "./FilteredInvoices";
import { IInvoicesFiltersCount, InvoicesFiltersCountQueryKey } from "./FiltersCount";
import { updateInfiniteQuery } from "factor-lib/utils/graphQLPagination";
import { invoicesFilteredListQueryKey } from "./InfiniteQueries";
import { IBaseComputation } from "./StatusFilters";

export const updateInvoiceInFilteredList = (
    queryClient: QueryClient,
    computation: IBaseComputation,
    invoiceId: string,
    updater: (i: IDSInvoice) => IDSInvoice
 ) =>
    updateInfiniteQuery(
        queryClient,
        invoicesFilteredListQueryKey(computation),
        (i) => i.id === invoiceId,
        updater
    );

export const updateInvoicesHeaderAndFiltersCountQueries = (
    queryClient: QueryClient,
    headerUpdater: (input: IInvoicesHeader) => IInvoicesHeader,
    filtersCountUpdater: (input: IInvoicesFiltersCount) => IInvoicesFiltersCount
) => {
    queryClient.setQueryData<IInvoicesHeader>(
        InvoicesHeaderQueryKey,
        old => !!old ? headerUpdater(old) : undefined
    );

    queryClient.setQueryData<IInvoicesFiltersCount>(
        InvoicesFiltersCountQueryKey,
        old => !!old ? filtersCountUpdater(old) : undefined
    )
}
