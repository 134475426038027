import Loader from "factor-lib/Loader";
import IAccessTokens from "../../Auth/IAccessTokens";
import useUrlQuery from "../../utils/useUrlQuery";
import {InvoiceAddPageBuyerIdQueryParamName, invoiceAddUrl} from "./invoiceAddUrl";
import {Navigate} from "react-router-dom";
import { isValidGuid } from "factor-lib/utils/guidUtils";
import InvoiceAddContentPostRedirectDS from "./InvoiceAddContentPostRedirectDS";
import InvoiceAddContentPostRedirectM from "./InvoiceAddContentPostRedirectM";

const InvoiceAddPage = (
    {
        className,
        accessTokenO
    }: {
        className?: string;
        accessTokenO: IAccessTokens | null;
    }
) => {
    const query = useUrlQuery();

    const queryBuyerIdStr: string | null =
        query.get(InvoiceAddPageBuyerIdQueryParamName);

    return (
        queryBuyerIdStr !== null && !isValidGuid(queryBuyerIdStr)
            ? <Navigate to={invoiceAddUrl}
                        replace={true} />
            : !!accessTokenO
                ? !!accessTokenO.authUser.directSellerIdO
                    ? <InvoiceAddContentPostRedirectDS className={className}
                                                       customerAxios={accessTokenO.customerAxios}
                                                       directSellerAxios={accessTokenO.customerSpecificAxios}
                                                       // accessToken={accessTokenO}
                                                       buyerPreSelectedId={queryBuyerIdStr} />
                : !!accessTokenO.authUser.marketplaceIdO
                        ? <InvoiceAddContentPostRedirectM className={className}
                                                          customerAxios={accessTokenO.customerAxios}
                                                          marketplaceAxios={accessTokenO.customerSpecificAxios}
                                                          // accessToken={accessTokenO}
                                                          // buyerPreSelectedId={queryBuyerIdStr}
                        />
                        : null /* TODO */
                : <div className={className}>
                    <Loader />
                </div>
    );
}

export default InvoiceAddPage;
