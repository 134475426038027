import EventBus, {GLOBAL_CLICK_EVENT} from "./EventBus";
import {ReactElement} from "react";

const GlobalClickWrapper = (
    {
        children
    }:
    {
        children: ReactElement
    }
) =>
    <div onClick={() => EventBus.dispatch(GLOBAL_CLICK_EVENT)}
         style={{
             display: 'flex',
             flexDirection: 'column',
             minHeight: '100vh' // for the page background
         }}>
        { children }
    </div>

export default GlobalClickWrapper;