export const InvoiceAddPageBuyerIdQueryParamName = 'buyerId';


// New Invoice

export const invoiceAddUrl = '/new-invoice';
export const invoiceAddForBuyerUrl = (buyerId: string): string => {
    const query = new URLSearchParams();

    query.set(InvoiceAddPageBuyerIdQueryParamName, buyerId);

    return `${invoiceAddUrl}?${query.toString()}`;
};
