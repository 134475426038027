import {CSSProperties, ReactNode, useState} from "react";
import { IBuyer } from "./BuyersTableDesktopQuery";
import Loader from "factor-lib/Loader";
import SortableTableTitle from '../SortableTableTitle';
import Modal from 'factor-lib/Modal';
import BuyerAskedOutstandingModalContent from "./BuyerAskedOutstandingModalContent";
import { Axios } from "axios";
import { BuyerSortProperty } from './buyersSortProperties';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { IPaginated, getNodes, hasNextPage } from 'factor-lib/utils/graphQLPagination';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { getFactorContext } from '../../../IFactorContext';
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper';
import BuyersRow from './BuyersRow';
import { ISort, SortOrder } from "factor-lib/utils/sortingUtils";
import FullPageRefreshing, {DefaultOpacityForReloadingStyle} from "../../utils/FullPageRefreshing";


interface IModifyAskedOutstandingParams {
    buyerId: string;
    currentAskedOutstanding: number | null;
}

const BuyersListTable = (
    {
        sort,
        setSort,
        children
    }: {
        sort: ISort<BuyerSortProperty>;
        setSort: (newSort: ISort<BuyerSortProperty>) => void;
        children?: ReactNode;
    }
) =>
    <table className='table is-striped p-table is-fullwidth'>
        <thead>
            <tr>
                <th>
                    <SortableTableTitle name='Nom'
                                        alignRight={false}
                                        upSortClickHandler={sort.property !== BuyerSortProperty.Name || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: BuyerSortProperty.Name, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== BuyerSortProperty.Name || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: BuyerSortProperty.Name, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th align='right'>
                    Encours demandé
                </th>
                <th align='right'>
                    Encours client / Limite
                </th>
                <th>
                    <SortableTableTitle name='Factures financées'
                                        alignRight={true}
                                        upSortClickHandler={sort.property !== BuyerSortProperty.FinancedCount || sort.order !== SortOrder.Asc
                                            ? (() => setSort({ property: BuyerSortProperty.FinancedCount, order: SortOrder.Asc }))
                                            : null
                                        }
                                        downSortClickHandler={sort.property !== BuyerSortProperty.FinancedCount || sort.order !== SortOrder.Desc
                                            ? (() => setSort({ property: BuyerSortProperty.FinancedCount, order: SortOrder.Desc }))
                                            : null
                                        } />
                </th>
                <th>
                    Éligibilité
                </th>
            </tr>
        </thead>
        { children }
    </table>;

export const BuyerListLoadedId = 'buyerListId';

const BuyersListLoaded = (
    {
        style,
        buyers,
        fetchNextPage,
        isIncrementalLoading,
        setModifyAskedOutstanding,
        sort,
        setSort
    }: {
        style?: CSSProperties;
        buyers: InfiniteData<IPaginated<IBuyer>>;
        fetchNextPage: () => void;
        isIncrementalLoading: boolean;
        setModifyAskedOutstanding: (b: IBuyer) => void;
        sort: ISort<BuyerSortProperty>;
        setSort: (newSort: ISort<BuyerSortProperty>) => void;
    }
) => {
    const buyersHasNextPage = hasNextPage(buyers);

    const [sentryRef] = useInfiniteScroll({
        loading: isIncrementalLoading,
        hasNextPage: buyersHasNextPage,
        onLoadMore: fetchNextPage,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <div id={BuyerListLoadedId} style={style}>
            <BuyersListTable sort={sort}
                             setSort={setSort}>
                <tbody>
                    { getNodes<IBuyer>(buyers)
                        .map((b, index) =>
                            <BuyersRow key={`buyer-${index}`}
                                       buyer={b}
                                       setModifyAskedOutstanding={() => setModifyAskedOutstanding(b)} />
                        )
                    }
                </tbody>
            </BuyersListTable>

            {/* https://github.com/onderonur/react-infinite-scroll-hook#simple-example */}
            { (isIncrementalLoading || buyersHasNextPage) &&
                <div ref={sentryRef}>
                    <Loader />
                </div>
            }
        </div>
    );
}


const BuyersList = (
    {
        directSellerAxios,
        query,
        sort,
        setSort
    }: {
        directSellerAxios: Axios;
        query: UseInfiniteQueryResult<IPaginated<IBuyer>>;
        sort: ISort<BuyerSortProperty>;
        setSort: (newSort: ISort<BuyerSortProperty>) => void;
    }
) => {
    const factorContext = getFactorContext();

    const [modifyAskedOutstanding, setModifyAskedOutstanding] = useState<IModifyAskedOutstandingParams | null>(null);

    return (
        <div>
            <ReactQueryResultWrapper displayFullError={factorContext.debug}
                                     result={query}
                                     onLoading={() =>
                                         <div>
                                             <BuyersListTable sort={sort}
                                                              setSort={setSort}/>
                                             <Loader/>
                                         </div>
                                     }
                                     onReloading={(oldData: InfiniteData<IPaginated<IBuyer>>) =>
                                         <FullPageRefreshing>
                                             <BuyersListLoaded style={DefaultOpacityForReloadingStyle}
                                                               buyers={oldData}
                                                               fetchNextPage={() => query.fetchNextPage()}
                                                               isIncrementalLoading={false}
                                                               setModifyAskedOutstanding={(b: IBuyer) => setModifyAskedOutstanding({
                                                                   buyerId: b.id,
                                                                   currentAskedOutstanding: b.askedOutstanding
                                                               })}
                                                               sort={sort}
                                                               setSort={setSort} />
                                         </FullPageRefreshing>
                                     }
                                     onSuccess={(buyers: InfiniteData<IPaginated<IBuyer>>, isIncrementalLoading: boolean) =>
                                         <BuyersListLoaded buyers={buyers}
                                                           fetchNextPage={() => query.fetchNextPage()}
                                                           isIncrementalLoading={isIncrementalLoading}
                                                           setModifyAskedOutstanding={(b: IBuyer) => setModifyAskedOutstanding({
                                                               buyerId: b.id,
                                                               currentAskedOutstanding: b.askedOutstanding
                                                           })}
                                                           sort={sort}
                                                           setSort={setSort} />
                                     } />

            { !!modifyAskedOutstanding &&
                <Modal id='setAskedOutstanding-Modal'
                       active={true}
                       fullMaxWidth={false}
                       close={() => setModifyAskedOutstanding(null)}>
                    <BuyerAskedOutstandingModalContent directSellerAxios={directSellerAxios}
                                                       buyerId={modifyAskedOutstanding.buyerId}
                                                       currentAskedOutstanding={modifyAskedOutstanding.currentAskedOutstanding}
                                                       closeModal={() => setModifyAskedOutstanding(null)} />
                </Modal>
            }
        </div>
    );
}

export default BuyersList;
