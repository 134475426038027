import InvoiceDetailsPagePostRedirect from "./InvoiceDetailsPagePostRedirect";
import {Axios} from "axios";
import {IDSInvoiceWithDetails} from "./InvoiceDetailsQuery";
import {
    requestFinancingMAsync
} from "../utils/requestFinancingUtils";
import {IRequestFinancingResponseM} from "../InvoiceAdd/InvoiceAddContentPostRedirectM";
import DataLoader from "dataloader";
import graphQLBatchLoadFunction from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import {getFactorContext} from "../../IFactorContext";

const SellerGraphQLSelection =
    `seller { cannotFinanceReason } `;

export interface IMInvoiceWithDetails extends IDSInvoiceWithDetails {
    seller: {
        cannotFinanceReason: string | null;
    }
}

const InvoiceDetailsPagePostRedirectM = (
    {
        invoiceId,
        marketplaceAxios
    }: {
        invoiceId: string;
        marketplaceAxios: Axios;
    }
) => {

    const factorContext = getFactorContext();

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunction(
            marketplaceAxios,
            factorContext.logger,
            graphQLQueries
        )
    );

    return (
        <InvoiceDetailsPagePostRedirect<IMInvoiceWithDetails, IRequestFinancingResponseM>
            graphQLDataLoader={graphQLDataLoader}
            invoiceId={invoiceId}
            sellerGraphQLSelection={SellerGraphQLSelection}
            actionsProvider={(invoiceDetails: IMInvoiceWithDetails) => ({
                canRequestFinancingO: {
                    cannotFinanceReasonO: invoiceDetails.seller.cannotFinanceReason,
                    action: () =>
                        requestFinancingMAsync(marketplaceAxios, invoiceId),
                    financingImpactExtractor: (r: IRequestFinancingResponseM) => ({
                        forBuyerO: null,
                        invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                    })
                },
                canDeclareBuyerPaymentInvoiceO: null,
                canUploadAdditionalFileO: null
            })}/>
    );
}

export default InvoiceDetailsPagePostRedirectM;