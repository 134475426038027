import { QueryClient } from "@tanstack/react-query";
import { Axios } from "axios";
import { updateInvoicesHeaderAndFiltersCountQueries } from "../Dashboard/Invoices/updateInvoicesQuery";
import { IInvoicesHeader } from "../Dashboard/Invoices/Header";
import { serverDateSerialization } from "factor-lib/utils/dateUtils";
import { CallWithDimplScoreInBackTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import {
    IDSInvoiceWithDetails,
    IInvoiceDetailsQueryResult,
    invoiceDetailsQueryKey
} from "../InvoiceDetails/InvoiceDetailsQuery";
import IInvoiceStatus, { IFinancingRequestAcceptedStatus } from "../InvoiceStatus/IInvoiceStatus";
import { IInvoicesFiltersCount } from "../Dashboard/Invoices/FiltersCount";
import { sum } from "factor-lib/utils/utils";
import { IMeOutstanding, MeOutstandingProp, meQueryKey } from "./Me";
import { invoicesFilteredListQueryKey } from "../Dashboard/Invoices/InfiniteQueries";
import { EligibleComputation, FinancingRequestedComputation, NotEligibleComputation, PendingComputation } from "../Dashboard/Invoices/StatusFilters";
import { updateBuyerInList } from "../Dashboard/Buyers/updateBuyersQuery";
import {IInvoicesWhichBecomeUneligible, BuyerFinancingImpact} from "../InvoiceFinancingImpact";
import {IFinancingImpactDS} from "../InvoiceAdd/InvoiceAddContentPostRedirectDS";
import {IRequestFinancingResponseM, requestFinancingMUrl} from "../InvoiceAdd/InvoiceAddContentPostRedirectM";


export interface IRequestFinancingResponseDS {
    financingImpact: IFinancingImpactDS;
}

export const requestFinancingDSUrl = (invoiceId: string) =>
    `/directSellerInvoices/${invoiceId}/requestFinancing`;

export const requestFinancingDSAsync = async (directSellerAxios: Axios, invoiceId: string): Promise<IRequestFinancingResponseDS> =>
    (await directSellerAxios.post<IRequestFinancingResponseDS>(
        requestFinancingDSUrl(invoiceId),
        null,
        {timeout: CallWithDimplScoreInBackTimeoutMilliseconds}
    )).data;

export const requestFinancingMAsync = async (marketplaceAxios: Axios, invoiceId: string): Promise<IRequestFinancingResponseM> =>
    (await marketplaceAxios.post<IRequestFinancingResponseM>(
        requestFinancingMUrl(invoiceId),
        null,
        {timeout: CallWithDimplScoreInBackTimeoutMilliseconds}
    )).data;

const updateFinancingRequestStatus = <A extends IFinancingRequestAcceptedStatus>(oldStatus: IInvoiceStatus<A>): IInvoiceStatus<A> => ({
    ...oldStatus,
    financingRequest: {
        dateTime: serverDateSerialization(new Date()),
        accepted: null,
        rejectedDate: null
    },
    eligibility: {
        isEligible: false,
        notEligibleReason: null
    }
});

// TODO : only invalidate ?
export const requestFinancingUpdateQueriesAsync = async <I extends IDSInvoiceWithDetails> (
    queryClient: QueryClient,
    invoiceId: string,
    invoiceAmountTTC: number,
    partialAmountPaidByBuyer: number,
    invoiceFinancingImpactBuyerO: BuyerFinancingImpact | null, // On for direct seller, when we update buyer impact
    invoicesWhichBecomeUneligible: IInvoicesWhichBecomeUneligible[]
): Promise<void> => {
    updateInvoicesHeaderAndFiltersCountQueries(
        queryClient,
        (oldHeader: IInvoicesHeader) => ({
            ...oldHeader,
            totalEligible:  oldHeader.totalEligible - invoiceAmountTTC - sum(invoicesWhichBecomeUneligible.map((i) => i.amountTTC)),
            totalActive: oldHeader.totalActive + invoiceAmountTTC - partialAmountPaidByBuyer
        }),
        (oldFiltersCount: IInvoicesFiltersCount) => ({
            ...oldFiltersCount,
            eligible: oldFiltersCount.eligible - 1,
            financingRequested: oldFiltersCount.financingRequested + 1
        })
    );

    let r: Promise<void>[] = [
        queryClient.invalidateQueries(invoicesFilteredListQueryKey(EligibleComputation)),
        queryClient.invalidateQueries(invoicesFilteredListQueryKey(FinancingRequestedComputation))
    ];

    if (invoicesWhichBecomeUneligible.length > 0) {
        r = [
            ...r,
            queryClient.invalidateQueries(invoicesFilteredListQueryKey(NotEligibleComputation)),
            queryClient.invalidateQueries(invoicesFilteredListQueryKey(PendingComputation))
        ];
    }

    // queryClient.setQueryData<InfiniteData<IPaginated<IDSInvoice>>>(
    //     invoicesFilteredListQueryKey2(EligibleComputation, 'number', true, ''),
    //     (old) => !!old ? ({
    //         ...old,
    //         pages: old.pages.map((p) => ({
    //             ...p,
    //             edges: p.edges.map((e) => ({
    //                 ...e,
    //                 node: {
    //                     ...e.node,
    //                     status: updateFinancingRequestStatus(e.node.status)
    //                 }
    //             }))
    //         }))
    //     }) : undefined
    // );

    queryClient.setQueryData<IInvoiceDetailsQueryResult<I>>(
        invoiceDetailsQueryKey(invoiceId),
        (old: IInvoiceDetailsQueryResult<I> | undefined) => !!old ? ({
            ...old,
            invoiceDetails: {
                ...old.invoiceDetails,
                status: updateFinancingRequestStatus(old.invoiceDetails.status)
            }
        }) : undefined
    );

    if (!!invoiceFinancingImpactBuyerO) {
        updateBuyerInList(
            queryClient,
            invoiceFinancingImpactBuyerO.id,
            (b) => ({
                ...b,
                outstandings: {
                    ...b.outstandings,
                    outstanding: invoiceFinancingImpactBuyerO.newOutstanding
                }
            })
        );
    }

    queryClient.setQueryData<IMeOutstanding>(
        meQueryKey(MeOutstandingProp),
        (old: IMeOutstanding | undefined) => !!old ? ({
            outstanding: old.outstanding + invoiceAmountTTC - partialAmountPaidByBuyer
        }) : undefined
    );

    await Promise.all(r);
};