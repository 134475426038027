import TagManager from 'react-gtm-module';
import IGoogleTagManager from './IGoogleTagManager';

const appendProperties = (event: any, properties?: any): any => {
    let f: any = ({
      event
    });
    if (!!properties) {
        f = ({
            ...f,
            ...properties
        });
    }
    return f;
};

export default class GoogleTagManager implements IGoogleTagManager {

    private static datalayerInt(dataLayer: any): void {
          TagManager.dataLayer({dataLayer});
    }

    private readonly googleTagManagerId: string;

    constructor(googleTagManagerId: string) {
        this.googleTagManagerId = googleTagManagerId;
    }

    public init(userId: string | null): void {

        // TODO : switch to
        // But problem with typescript
        // https://www.npmjs.com/package/vue-gtm
        // Vue.use(VueGtm, {
        //     id: 'googleTagManagerId', // Your GTM ID
        //     enabled: true, // defaults to true.
        //     Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        //     debug: true, // Whether or not display console logs debugs (optional)
        //     vueRouter: Router, // Pass the router instance to automatically sync with router (optional)
        //     // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive)(optional)
        // });


        /*
        const dataLayer: any = {};
        const utmSource = getUrlParameter('utm_source');
        if (utmSource) {
            dataLayer.utmSource = utmSource;
        }
         */

        // this.logPage(params.currentPageName, params.currentPagePath);

        const dataLayer: any = {};

        if (!!userId) {
            dataLayer.userId  = userId;
        }

        TagManager.initialize({
            gtmId: this.googleTagManagerId,
            dataLayer
        });
    }

    public login(userId: string): void {
        GoogleTagManager.datalayerInt({userId});
    }

    public logout(): boolean {
        GoogleTagManager.datalayerInt({userId : null});
        return false;
    }

    public event(event: string, properties?: any) {
        GoogleTagManager.datalayerInt(appendProperties(event, properties));
    }

    public newPage(name: string, path: string, properties?: any): void {
        // Looks category not supported
        GoogleTagManager.datalayerInt(appendProperties({
            event: 'Pageview',
            pagePath: path,
            pageTitle: name
        }, properties));
    }
}
