import { buildInvoicesGraphQLQueryProvider, IDSInvoice } from "./FilteredInvoices";
import {
    IMeCannotFinanceReason,
    IMeMinInvoiceAmount,
    IMeOutstandingLimit,
    MeCannotFinanceReasonProp,
    MeMinInvoiceAmountProp,
    MeOutstandingLimitProp
} from "../../utils/Me";
import InvoicesTableDesktopContentPostRedirect from "./InvoicesTableDesktopContentPostRedirect";
import { Axios } from "axios";
import { buildInvoicesSortWoSeller } from "./InvoiceList/invoicesComparators";
import { getFactorContext } from "../../../IFactorContext";
import DataLoader from "dataloader";
import ILogger from "factor-lib/services/logger/ILogger";
import dataLoader from "factor-lib/dataLoader/dataLoader";
import graphQLBatchLoadFunctionAsync from "factor-lib/dataLoader/graphQLBatchLoadFunctionAsync";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";
import { buildSortFinal } from "factor-lib/utils/graphQLPagination";
import { InvoiceSortProperty } from "./invoicesSortProperties";
import { ISort } from "factor-lib/utils/sortingUtils";
import {IRequestFinancingResponseDS, requestFinancingDSAsync} from "../../utils/requestFinancingUtils";

type IDSMe = IMeOutstandingLimit & IMeMinInvoiceAmount & IMeCannotFinanceReason;


const InvoicesTableDesktopContentPostRedirectDS = (
    {
        directSellerAxios,
        statusFilters,
        setStatusFilters,
        searchFilter,
        setSearchFilter,
        sort,
        setSort
    }: {
        directSellerAxios: Axios;
        statusFilters: number[];
        setStatusFilters: (newStatusFilters: number[]) => void;
        searchFilter: string;
        setSearchFilter: (newSearchFilter: string) => void;
        sort: ISort<InvoiceSortProperty>;
        setSort: (newSort: ISort<InvoiceSortProperty>) => void;
    }
) => {
    const factorContext = getFactorContext();
    const logger: ILogger = factorContext.logger;

    const graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any> = dataLoader<IGraphQLQueryWithKeyAndParams, any>(
        (graphQLQueries: ReadonlyArray<IGraphQLQueryWithKeyAndParams>) => graphQLBatchLoadFunctionAsync(
            directSellerAxios,
            logger,
            graphQLQueries
        )
    );

    const invoicesSorterFinal = buildSortFinal(
        buildInvoicesSortWoSeller(sort.property),
        sort.order,
        (i) => i.id
    );

    return (
        <InvoicesTableDesktopContentPostRedirect<IDSInvoice, IDSMe, IRequestFinancingResponseDS>
            graphQLDataLoader={graphQLDataLoader}
            statusFilters={statusFilters}
            setStatusFilters={setStatusFilters}
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            sort={sort}
            setSort={setSort}
            meProps={[MeOutstandingLimitProp, MeCannotFinanceReasonProp, MeMinInvoiceAmountProp]}
            actionsProvider={(me: IDSMe) => ({
                canRequestFinancingO: {
                    cannotFinanceReasonO: (_) => me.cannotFinanceReason,
                    action: (invoice2: IDSInvoice) =>
                        requestFinancingDSAsync(directSellerAxios, invoice2.id),
                    financingImpactExtractor: (r: IRequestFinancingResponseDS) => ({
                        forBuyerO: r.financingImpact.forBuyer,
                        invoicesWhichBecomeUneligible: r.financingImpact.invoicesWhichBecomeUneligible
                    })
                },
                canDeclareBuyerPaymentInvoiceO: {
                    action: async (invoiceId: string, params) =>
                        (await directSellerAxios.post<void>(
                            `/directSellerInvoices/${invoiceId}/buyerPayment`,
                            params
                        )).data,
                    minInvoiceAmount: me.minInvoiceAmount
                }
            })}
            sellerExtractorO={null}
            graphQLQueryProvider={buildInvoicesGraphQLQueryProvider('')}
            sorter={invoicesSorterFinal} />
    );
}

export default InvoicesTableDesktopContentPostRedirectDS;