import {ReactElement, useEffect} from "react";
import IAuthUser from "./IAuthUser";
import {useAccount, useMsal} from "@azure/msal-react";
import CurrentEnv from "../envs/CurrentEnv";
import {Axios} from "axios";
import buildDefaultAuthAxios, {ensureOneAuthUser} from "factor-lib/serverUtils/buildDefaultAuthAxios";
import {AccountInfo, InteractionStatus} from "@azure/msal-browser";
import ILogger from "factor-lib/services/logger/ILogger";
import {SeverityLevel} from "@microsoft/applicationinsights-web";
import IAccessTokens from "./IAccessTokens";
import {IBackend} from "../envs/IEnvConfig";
import msalInstance from "../msalInstance";
import ICustomerType from "../ICustomerType";
import {useQuery} from "@tanstack/react-query";
import ReactQueryResultWrapper from 'factor-lib/reactquery/ReactQueryResultWrapper'
import {getFactorContext} from "../IFactorContext";
import {ReactQueryNoNeedRefresh} from "factor-lib/reactquery/utils";

// const claims = 'oid'; // 'http://schemas.microsoft.com/identity/claims/objectidentifier';

const getAuthUserAsync = async (customerAxios: Axios): Promise<IAuthUser> =>
    (await customerAxios.get<IAuthUser>(
        `${CurrentEnv.backends.commonCustomer.baseUrl}/auth`
    )).data;

const fullScopeName = (scope: string) =>
    `https://${CurrentEnv.azureAD.domain}.onmicrosoft.com/${scope}`;

const customerScopes: string[] = [fullScopeName(CurrentEnv.backends.commonCustomer.adScope)];

const blankSilentLoginUrl = `${window.location.protocol}//${window.location.host}/blank-silent-login.html`;

const buildAxios = (backend: IBackend, account: AccountInfo): Axios =>
    buildDefaultAuthAxios(
        backend.baseUrl,
        getFactorContext().logger,
        msalInstance,
        account,
        [fullScopeName(backend.adScope)],
        blankSilentLoginUrl/* ,
            CurrentEnv.azureAD.clientId */
    );

const RequestCustomerSpecificAccessTokenComponentWrapper = (
    {
        customerAxios,
        authUser,
        child,
        account
    }: {
        customerAxios: Axios;
        authUser: IAuthUser;
        child: (accessTokenO: IAccessTokens | null) => ReactElement;
        account: AccountInfo;
    }
) =>
    // Pas besoin de check sur in progress : si on est arrive la, on a deja fait un call
    child(!!authUser.directSellerIdO ? ({
        customerAxios,
        customerType: ICustomerType.DirectSeller,
        customerSpecificAxios: buildAxios(CurrentEnv.backends.directSeller, account),
        authUser
    }) : ({
        customerAxios,
        customerType: ICustomerType.Marketplace,
        customerSpecificAxios: buildAxios(CurrentEnv.backends.marketplace, account),
        authUser
    }));

// Need a separate class to avoid infinite loops because of hooks
const AuthUserWrapperCompletedWithAccount = (
    {
        customerAxios,
        child,
        account
    }: {
        customerAxios: Axios;
        child: (accessTokenO: IAccessTokens | null) => ReactElement;
        account: AccountInfo;
    }
) =>
    <ReactQueryResultWrapper<IAuthUser> result={useQuery(
                                            ['authuser'],
                                            () => getAuthUserAsync(customerAxios),
                                            // should not change
                                            ReactQueryNoNeedRefresh
                                        )}
                                        onLoading={() => child(null /* loading */)}
                                        onError={(errorMessage) =>
                                            <div className='p-vertical-center p-margin-top-5'>
                                                { errorMessage }
                                            </div>
                                        }
                                        displayFullError={getFactorContext().debug}
                                        onSuccess={(authUser) =>
                                            <RequestCustomerSpecificAccessTokenComponentWrapper customerAxios={customerAxios}
                                                                                                authUser={authUser}
                                                                                                child={child}
                                                                                                account={account}/>
                                        }/>;

const AuthUserWrapperCompleted = (
    {
        child
    }: {
        child: (accessTokenO: IAccessTokens | null) => ReactElement;
    }
) => {

    const { instance, accounts } = useMsal();
    const logger: ILogger = getFactorContext().logger;

    // Apparemment il faut ajouter le useAccount hook :
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token
    // pas dans l'exemple router
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/utils/MsGraphApiCall.js
    // mais le 1er a l'air plus officiel
    const account: AccountInfo = useAccount(ensureOneAuthUser(instance, logger, accounts/* , clientId */))!;

    const customerAxios: Axios = buildDefaultAuthAxios(
        CurrentEnv.backends.commonCustomer.baseUrl,
        logger,
        instance,
        account,
        customerScopes,
        blankSilentLoginUrl
        // CurrentEnv.azureAD.clientId
    );

    return <AuthUserWrapperCompletedWithAccount customerAxios={customerAxios}
                                                child={child}
                                                account={account} />;
}

const AuthUserWrapperPending = (
    {
        child
    }: {
        child: (accessTokenO: IAccessTokens | null) => ReactElement;
    }
) => {
    const logger: ILogger = getFactorContext().logger;
    useEffect(() => {
        logger.trackTrace(SeverityLevel.Information, "Logging in progress");
    })
    return child(null);
}

const AuthUserWrapper = (
    {
        child
    }: {
        child: (accessTokenO: IAccessTokens | null) => ReactElement;
    }
) => {
    const { inProgress } = useMsal();

    // useMsal will trigger 2 hooks : one with instance.initialized = false, another one initialized = true.
    // Don't create twice the axios and call the child twice with another similar axios, or we may hit PRED-2103
    return (
        inProgress === InteractionStatus.None /* Check we're done. */
            ? <AuthUserWrapperCompleted child={child}/>
            : <AuthUserWrapperPending child={child}/>
    );
}

export default AuthUserWrapper;
