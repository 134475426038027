import Loader from "factor-lib/Loader";
import IAccessTokens from "../../../Auth/IAccessTokens";
import InvoicesTableDesktopContent from "./InvoicesTableDesktopContent";

const InvoicesTableDesktopSection = (
    {
        accessTokenO
    }: {
        accessTokenO: IAccessTokens | null;
    }
) =>
    !!accessTokenO
        ? <InvoicesTableDesktopContent axios={accessTokenO.customerSpecificAxios}
                                       customerType={accessTokenO.customerType}/>
        : <Loader />;

export default InvoicesTableDesktopSection;
