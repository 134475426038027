import { InvoicesBaseQueryKey } from "./AllInvoicesQuery";
import { IGraphQLParams } from "factor-lib/serverUtils/graphQLQueryAsync";
import DataLoader from "dataloader";
import IGraphQLQueryWithKeyAndParams from "factor-lib/dataLoader/IGraphQLQueryWithKeyAndParams";

export const InvoicesHeaderQueryKey = [InvoicesBaseQueryKey, 'header'];

const afterTodayFilter = 'isAfterToday: true';
const amountTTCGraphQLFields = 'amountTTC';
const remainingToPayGraphQLFields = 'remainingToPay';

export interface IInvoicesHeader {
    // everything is amount
    totalEligible: number;
    totalActive: number; // TODO: redundant with me.outstanding
    totalUpcoming: number;
}

const invoicesHeaderQuery: IGraphQLParams =
    {
        query: `query {
            invoicesSummary {
                eligible { ${amountTTCGraphQLFields} }
                financingRequested { ${remainingToPayGraphQLFields} }
                financingActive { ${remainingToPayGraphQLFields} }

                eligibleAfterToday: eligible(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
                notEligibleAfterToday: notEligible(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
                pendingAfterToday: pending(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
                financingRequestedAfterToday: financingRequested(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
                financingActiveAfterToday: financingActive(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
                financingSettledAfterToday: financingSettled(${afterTodayFilter}) { ${remainingToPayGraphQLFields} }
            }
        }`
    };

interface IRemainingToPayWrapper {
    remainingToPay: number;
}

interface IAmountTTCWrapper {
    amountTTC: number;
}

interface IInvoicesHeaderQueryResult {
    invoicesSummary: {
        eligible: IAmountTTCWrapper;
        financingRequested: IRemainingToPayWrapper;
        financingActive: IRemainingToPayWrapper;
    
        eligibleAfterToday: IRemainingToPayWrapper;
        notEligibleAfterToday: IRemainingToPayWrapper;
        pendingAfterToday: IRemainingToPayWrapper;
        financingRequestedAfterToday: IRemainingToPayWrapper;
        financingActiveAfterToday: IRemainingToPayWrapper;
        financingSettledAfterToday: IRemainingToPayWrapper;
    };
}

export const invoicesHeaderQueryFn = async (
    graphQLDataLoader: DataLoader<IGraphQLQueryWithKeyAndParams, any>,
    queryKey: string[],
    signal?: AbortSignal
): Promise<IInvoicesHeader> => {
    const { invoicesSummary } =
        await graphQLDataLoader.load({
            query: invoicesHeaderQuery,
            queryKey,
            signal
        }) as IInvoicesHeaderQueryResult;

    return ({
        totalEligible:
            invoicesSummary.eligible.amountTTC, // TODO: do we really want to not include partial payments
        totalActive:
            invoicesSummary.financingActive.remainingToPay +
            invoicesSummary.financingRequested.remainingToPay,
        totalUpcoming: // does not include financing request rejected
            invoicesSummary.eligibleAfterToday.remainingToPay +
            invoicesSummary.notEligibleAfterToday.remainingToPay +
            invoicesSummary.pendingAfterToday.remainingToPay +
            invoicesSummary.financingRequestedAfterToday.remainingToPay +
            invoicesSummary.financingActiveAfterToday.remainingToPay +
            invoicesSummary.financingSettledAfterToday.remainingToPay
    });
}
