import { IIntCompanyIdentifier } from "factor-lib/Company/IIntCompanyIdentifier";
import { IntCompanyIdentifierGraphQLFields } from "factor-lib/Company/IIntCompanyIdentifier";

export const BuyersQueryKey = ['buyers', 'list'];

export const BuyerGraphQLQuerySelection =
    `
        id
        identifier { ${IntCompanyIdentifierGraphQLFields} }
        name
        askedOutstanding
        outstandings {
            outstanding
            outstandingLimit
            nonPending {
                notEligibleReason
            }
        }
        financedCount
    `;

export interface IBuyer {
    id: string; // company id
    identifier: IIntCompanyIdentifier;
    name: string;
    askedOutstanding: number | null;
    outstandings: IBuyerOutstandings;
    financedCount: number;
}

export interface IBuyerOutstandings {
    outstanding: number;
    outstandingLimit: number | null;
    nonPending: {
        notEligibleReason: string | null;
    } | null;
}
