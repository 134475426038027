import { formatDateInput, parseDateInput } from "factor-lib/forms/DateInput/DateInput";
import { IInvoiceBaseInfosInput, IInvoiceBaseInfosInputEnabled } from "factor-lib/AddInvoice/InvoiceAddBaseInfos";
import { formatAmountToInput, isValidAmount, parseInputAmount } from "factor-lib/forms/Inputs/InputAmount";
import {dateDiffDays, serverDateDeserialization, userFormatDate } from "factor-lib/utils/dateUtils";
import { formatAmount } from "factor-lib/utils/amountUtils";


export interface IMindeePrediction {
    number: string | null;
    issueDate: string | null;
    dueDate: string | null;
    amountWoTax: number | null;
    amountTax: number | null;
}

export interface IMindeeConflictingField {
    fieldName: string;
    informed: string;
    detected: string; // by mindee
}

const processMindeeStringPrediction = (
    fieldName: string,
    stringPrediction: string | null,
    stringInput: string,
    updateStringInput: ((input: string) => void) | null,
    conflictingFields: IMindeeConflictingField[]
) => {
    if (stringPrediction !== null) {
        if (stringInput.length === 0) {
            if (!!updateStringInput) {
                updateStringInput(stringPrediction);
            }
        } else if (stringPrediction.toLocaleLowerCase() !== stringInput.toLocaleLowerCase()) {
            conflictingFields.push({
                fieldName: fieldName,
                informed: stringInput,
                detected: stringPrediction
            });
        }
    }
};

const processMindeeDatePrediction = (
    fieldName: string,
    datePredictionStr: string | null,
    dateInput: string,
    updateDateInput: ((input: string) => void) | null,
    conflictingFields: IMindeeConflictingField[]
) => {
    if (datePredictionStr !== null) {
        const datePrediction: Date | null = serverDateDeserialization(datePredictionStr);
        const dateInputValue: Date | null = parseDateInput(dateInput);
        if (dateInput.length === 0) {
            if (!!updateDateInput) {
                updateDateInput(formatDateInput(datePrediction));
            }
        } else if (!!dateInputValue && dateDiffDays(datePrediction, dateInputValue) !== 0) {
            // We dont declare conflict if date input is invalid
            conflictingFields.push({
                fieldName: fieldName,
                informed: userFormatDate(dateInputValue),
                detected: userFormatDate(datePrediction)
            });
        }
    }
};

const processMindeeAmountPrediction = (
    fieldName: string,
    amountPrediction: number | null,
    amountInput: string,
    updateAmountInput: ((input: string) => void) | null,
    conflictingFields: IMindeeConflictingField[]
) => {
    if (amountPrediction !== null) {
        if (amountInput.length === 0) {
            if (!!updateAmountInput) {
                updateAmountInput(formatAmountToInput(amountPrediction));
            }
        } else if (!isValidAmount(amountInput) || amountPrediction !== parseInputAmount(amountInput)) {
            conflictingFields.push({
                fieldName: fieldName,
                informed: isValidAmount(amountInput) ? formatAmount(parseInputAmount(amountInput)) : amountInput,
                detected: formatAmount(amountPrediction)
            });
        }
    }
};

export const processMindeePrediction = (
    prediction: IMindeePrediction,
    baseInfosInput: IInvoiceBaseInfosInput,
    baseInfosInputEnabled: IInvoiceBaseInfosInputEnabled | null,
    setMindeeConflictingFields: (conflictingFields: IMindeeConflictingField[]) => void
) => {
    const conflictingFields: IMindeeConflictingField[] = [];

    processMindeeStringPrediction(
        'Numéro de facture',
        prediction.number,
        baseInfosInput.number,
        !!baseInfosInputEnabled ? baseInfosInputEnabled.updateNumber : null,
        conflictingFields
    );

    processMindeeDatePrediction(
        'Date d\'émission',
        prediction.issueDate,
        baseInfosInput.issueDate,
        !!baseInfosInputEnabled ? baseInfosInputEnabled.updateIssueDate : null,
        conflictingFields
    );

    processMindeeDatePrediction(
        'Date d\'échéance',
        prediction.dueDate,
        baseInfosInput.dueDate,
        !!baseInfosInputEnabled ? baseInfosInputEnabled.updateDueDate : null,
        conflictingFields
    );

    processMindeeAmountPrediction(
        'Montant Hors Taxes',
        prediction.amountWoTax,
        baseInfosInput.amountWoTax,
        !!baseInfosInputEnabled ? baseInfosInputEnabled.updateAmountWoTax : null,
        conflictingFields
    );

    processMindeeAmountPrediction(
        'Montant des Taxes',
        prediction.amountTax,
        baseInfosInput.amountTax,
        !!baseInfosInputEnabled ? baseInfosInputEnabled.updateAmountTax : null,
        conflictingFields
    );

    setMindeeConflictingFields(conflictingFields);
};
