import IUrlWithContentType from "factor-lib/forms/Upload/IUrlWithContentType";
import { InvoicesBaseQueryKey } from "../Dashboard/Invoices/AllInvoicesQuery";
import { IInvoicePayment } from 'factor-lib/utils/invoicePayment';
import { IFinancingRequestAcceptedStatus, IGDSInvoice } from "../InvoiceStatus/IInvoiceStatus";

export const invoiceDetailsQueryKey = (invoiceId: string) =>
    [InvoicesBaseQueryKey, 'detail', invoiceId];

export interface IUrlWithContentTypeAndFileName extends IUrlWithContentType {
    fileName: string;
}

interface IInvoiceAdditionalDocument {
    creationDateTime: string;
    blobFile: {
        fileName: string;
    };
}

export const PaymentTypeCommingLing = 1;
export const PaymentTypeCommingLingRecup = 2;
export const PaymentTypeNonReporting = 3;

interface IInvoiceDetailsBasePayment {
    type: number; // 1 -> commingLing; 2 -> commingling recup; 3 -> non Reporting
}

interface IInvoiceDetailsPayment extends IInvoicePayment<IInvoiceDetailsBasePayment> {
    dateTime: string;
}

export interface IInvoiceDetailsPaymentStatus {
    completePaidDate: string | null;
    partialAmountPaidByBuyer: number;
    payments: IInvoiceDetailsPayment[];
}

export interface IFinancingRequestAcceptedStatusWithDetails extends IFinancingRequestAcceptedStatus {
    effectiveNbOfDaysFinanced: number; /* float */
}

export interface IDSInvoiceWithDetails extends IGDSInvoice<IFinancingRequestAcceptedStatusWithDetails> {
    file: IUrlWithContentTypeAndFileName;
    buyer: IInvoiceBuyer;
    payment: IInvoiceDetailsPaymentStatus;
    creationDateTime: string;
    additionalDocuments:  IInvoiceAdditionalDocument[];
    issueDate: string;
}

export const graphQLQuery = (sellerGraphQLSelection: string) =>
    `query Q($invoiceId: Guid!) {
        invoiceDetails(id: $invoiceId)  {
            id
            number
            issueDate
            dueDate
            amountWoTax
            amountTax
            creationDateTime
            buyer {
                id
                name
                emails
                phones
            }
            ${sellerGraphQLSelection}
            payment {
                completePaidDate
                partialAmountPaidByBuyer
                payments { 
                    dateTime amount isComplete
                    base { type } 
                }
            }
            status {
                eligibility { isEligible notEligibleReason }
                financingRequest {
                    dateTime
                    accepted {
                        dateTime
                        settledDateTime
                        amount
                        commingLingAmountToReturn
                        effectiveNbOfDaysFinanced
                    }
                    rejectedDate
                }
            }
            file { url contentType fileName }
            additionalDocuments {
                creationDateTime
                blobFile { fileName }
            }
        }
    }`;

export interface IInvoiceDetailsQueryResult<I extends IDSInvoiceWithDetails> {
    invoiceDetails: I;
}

export interface IInvoiceBuyer {
    emails: string[];
    phones: string[];
    name: string;
    id: string; // useless but needed for the inheritance from IInvoice
}
