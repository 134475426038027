import { Navigate, NavigateFunction } from "react-router-dom";
import {
    dashboardBuyersRoute,
    DashboardBuyersDefaultSort,
    DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME,
    DASHBOARD_SORT_ORDER_URL_PARAM_NAME,
    DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME
} from "../dashboardUrlsConsts";
import BuyersTableDesktopContentPostRedirect from "./BuyersTableDesktopContentPostRedirect";
import { Axios } from "axios";
import useUrlQuery from "../../../utils/useUrlQuery";
import { useContext } from "react";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import { BuyerSortProperty } from "./buyersSortProperties";
import { ISort, SortOrder } from "factor-lib/utils/sortingUtils";

const BuyersTableDesktopContent = (
    {
        customerAxios,
        directSellerAxios
    }: {
        customerAxios: Axios;
        directSellerAxios: Axios;
    }
) => {

    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;

    const query = useUrlQuery();

    const searchFilter: string =
        query.get(DASHBOARD_SEARCH_FILTER_URL_PARAM_NAME) ?? '';

    const sortPropertyUnchecked: string | null =
        query.get(DASHBOARD_SORT_PROPERTY_URL_PARAM_NAME);

    const sortProperty: BuyerSortProperty | null =
        sortPropertyUnchecked === BuyerSortProperty.CreationDate ||
        sortPropertyUnchecked === BuyerSortProperty.Name ||
        sortPropertyUnchecked === BuyerSortProperty.FinancedCount
            ? sortPropertyUnchecked as BuyerSortProperty
            : null;

    const sortOrderUnchecked  =
        query.get(DASHBOARD_SORT_ORDER_URL_PARAM_NAME);
    
    const sortOrder: SortOrder | null =
        sortOrderUnchecked === SortOrder.Asc ||
        sortOrderUnchecked === SortOrder.Desc
            ? sortOrderUnchecked as SortOrder
            : null;

    if (sortProperty === null || sortOrder === null) {
        return (
            <Navigate to={
                          dashboardBuyersRoute(
                              {
                                  property: sortProperty ?? DashboardBuyersDefaultSort.property,
                                  order: sortOrder ?? DashboardBuyersDefaultSort.order
                              },
                              searchFilter
                          )
                      }
                      replace={true} />
        );
    }

    const sort: ISort<BuyerSortProperty> = {
        property: sortProperty,
        order: sortOrder
    };

    const setSearchFilter = (newSearchFilter: string) => navigate(
        dashboardBuyersRoute(
            sort,
            newSearchFilter
        )
    );

    const setSort = (newSort: ISort<BuyerSortProperty>) => navigate(
        dashboardBuyersRoute(
            newSort,
            searchFilter
        )
    );

    return (
        <BuyersTableDesktopContentPostRedirect customerAxios={customerAxios}
                                               directSellerAxios={directSellerAxios}
                                               searchFilter={searchFilter}
                                               setSearchFilter={setSearchFilter}
                                               sort={sort}
                                               setSort={setSort} />
    );
}

export default BuyersTableDesktopContent;
