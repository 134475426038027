import { Axios } from "axios";
import { searchBySirenAsync } from "factor-lib/CompanySearcher/CompanySearcher";
import ISearchCompaniesByNameResponse from "factor-lib/CompanySearcher/ISearchCompaniesByNameResponse";
import { isValidSiren } from "factor-lib/forms/Inputs/sirenUtils";
import ILogger from "factor-lib/services/logger/ILogger";

export const buyerCompanySearchAsync = async (
    inputCompanySearch: string,
    customerSpecificAxios: Axios,
    customerAxios: Axios,
    logger: ILogger
): Promise<ISearchCompaniesByNameResponse> => {
    if (isValidSiren(inputCompanySearch)) {
        return await searchBySirenAsync(
            customerSpecificAxios,
            inputCompanySearch,
            logger,
            ['company', 'search', 'siren', inputCompanySearch /* valid siren if used */]
        );
    } // else search by name
    try {
        return (await customerAxios.get<ISearchCompaniesByNameResponse>(
            `/customerCompanies/search?name=${encodeURI(inputCompanySearch)}`
        )).data;
    } catch (_) {
        /* exception is always logged */
        // no result : leave default 'nice' message when we have no results, that INSEE may be down
        return ({
            companies: [],
            complete: true
        })
    }
};