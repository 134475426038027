import DeclareBuyerPaymentModal from "../../../utils/DeclareBuyerPaymentModal";
import {ICBaseInvoicePaymentStatus} from "../FilteredInvoices";
import { ReactElement, useState } from "react";
import { serverDateDeserialization } from "factor-lib/utils/dateUtils";
import IDeclareBuyerPaymentParams from "./actions/IDeclareBuyerPaymentParams";
import IInvoiceStatus, {IFinancingRequestAcceptedStatus} from "../../../InvoiceStatus/IInvoiceStatus";

export const alreadyPaidInvoice: string = 'Vous avez déclaré avoir été entièrement payé pour cette facture. Vous ne pouvez donc pas la financer';

export interface IPaymentDeclarableInvoice {
    id: string;
    amountWoTax: number;
    amountTax: number;
    issueDate: string;
    dueDate: string;
    payment: ICBaseInvoicePaymentStatus;
    status: IInvoiceStatus<IFinancingRequestAcceptedStatus>
}

const WithDeclarationPayment = (
    {
        action,
        minInvoiceAmount,
        child
    }: {
        action: (invoiceId: string, p: IDeclareBuyerPaymentParams) => Promise<void>;
        minInvoiceAmount: number;
        child: (setCurrentDeclareBuyerPaymentInvoice: (i: IPaymentDeclarableInvoice) => void) => ReactElement;
    }
) => {
    const [currentDeclareBuyerPaymentInvoice, setCurrentDeclareBuyerPaymentInvoice] = useState<IPaymentDeclarableInvoice | null>(null);

    return (
        (!!currentDeclareBuyerPaymentInvoice ?
            <>
                <DeclareBuyerPaymentModal invoiceId={currentDeclareBuyerPaymentInvoice.id}
                                          action={(p: IDeclareBuyerPaymentParams) => action(currentDeclareBuyerPaymentInvoice.id, p)}
                                          invoiceAmountTTC={currentDeclareBuyerPaymentInvoice.amountWoTax + currentDeclareBuyerPaymentInvoice.amountTax}
                                          partialAmountPaidByBuyer={currentDeclareBuyerPaymentInvoice.payment.partialAmountPaidByBuyer}
                                          invoiceIssueDate={serverDateDeserialization(currentDeclareBuyerPaymentInvoice.issueDate)}
                                          invoiceDueDate={serverDateDeserialization(currentDeclareBuyerPaymentInvoice.dueDate)}
                                          invoiceStatus={{
                                              isEligible: currentDeclareBuyerPaymentInvoice.status.eligibility?.isEligible ?? null,
                                              isFinancingRequested: !!currentDeclareBuyerPaymentInvoice.status.financingRequest
                                                  ? {
                                                      isRejected: !!currentDeclareBuyerPaymentInvoice.status.financingRequest.rejectedDate,
                                                      isFinanced: !!currentDeclareBuyerPaymentInvoice.status.financingRequest.accepted
                                                  }
                                                  : null
                                          }}
                                          minInvoiceAmount={minInvoiceAmount}
                                          closeModal={() => setCurrentDeclareBuyerPaymentInvoice(null)} />
                { child(setCurrentDeclareBuyerPaymentInvoice) }
            </> : child(setCurrentDeclareBuyerPaymentInvoice))
    );
}

export default WithDeclarationPayment;