import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import {Axios} from "axios";
import ButtonMutationEnabled from "factor-lib/Buttons/ButtonMutationEnabled";
import { KIND_PRIMARY, SIZE_FIXED } from "factor-lib//Buttons/Button";
import Modal from "factor-lib/Modal";
import { IMeNewPricingAcceptationRequired, MeNewPricingAcceptationRequiredProp, meQueryKey } from "./Me";

const NewPricingModalContent = (
    {
        customerAxios,
        companyName,
        companySiren,
        companyEmail 
    }: {
        customerAxios: Axios;
        companyName: string;
        companySiren: string;
        companyEmail: string;
    }
) => {
    const queryClient = useQueryClient();

    const mutation: UseMutationResult<void, any, null, null> =
        useMutation<void, any, null, null>(
            async () => {
                await customerAxios.put<void>(
                    'customerCustomer/accept-new-pricing'
                );
            },
            ({
                onSuccess: () => {
                    queryClient.setQueryData<IMeNewPricingAcceptationRequired>(
                        meQueryKey(MeNewPricingAcceptationRequiredProp),
                        (old: IMeNewPricingAcceptationRequired | undefined) => !!old ? {
                            newPricingAcceptationRequired: false
                        } : undefined
                    );
                }
            })
        );

    return (
        <div className='container p-both-center p-padding-4'>
            <div style={{width: '1200px'}}>
                <div>
                    <div className="title is-2 p-vertical-center">AVENANT au Contrat de Pr&ecirc;ts Participatifs DIMPL</div>
                    <div className="title is-6">Pr&ecirc;teur</div>
                    <div className="p-margin-top-5">KETHER, Soci&eacute;t&eacute; par actions simplifi&eacute;e, dont le si&egrave;ge social est situ&eacute; 57, rue Charles Laffitte, 92200 Neuilly-sur-Seine, inscrite au Registre du Commerce et des Soci&eacute;t&eacute;s de Nanterre sous le num&eacute;ro 839 912 300, dont la marque commerciale est DIMPL.</div>
                    <div >Adresse mail pour les besoins du contrat de pr&ecirc;t : ops@dimpl.io</div>
                    <div className="title is-6 p-margin-top-5">Emprunteur</div>
                    <div className="p-margin-top-5">La soci&eacute;t&eacute; {companyName}, immatricul&eacute;e au RCS, num&eacute;ro {companySiren}.</div>
                    <div className="">Adresse mail : {companyEmail}</div>
                    <div className="title is-4 p-margin-top-5">IL EST PREALABLEMENT RAPPELE CE QUI SUIT :</div>
                    <div className="block">La Pr&ecirc;teur et l&rsquo;Emprunteur ont conclu un contrat de pr&ecirc;t participatif. Le Pr&ecirc;teur proc&egrave;de &agrave; une augmentation de ses tarifs, le pr&eacute;sent avenant a en cons&eacute;quence pour objet de modifier les sections <strong>Taux d&rsquo;int&eacute;r&ecirc;t</strong> et <strong>Remboursement</strong> du contrat (l&rsquo;&laquo; <strong>Avenant</strong> &raquo;).</div>
                    <div className="title is-4">IL A ETE CONVENU CE QUI SUIT :</div>
                    <div className="title is-5">ARTICLE 1 &ndash; MODIFICATION DU TAUX D&rsquo;INTERET</div>
                    <div className="title is-6">Taux d&rsquo;int&eacute;r&ecirc;t</div>
                    <div className="block">Chaque pr&ecirc;t participatif ayant fait l&rsquo;objet d&rsquo;une Tranche portera int&eacute;r&ecirc;t au taux fixe de<strong> 0,05% par jour</strong> sur l&rsquo;encours en principal de la Tranche de la date de mise &agrave; disposition de la Tranche &agrave; l&rsquo;Emprunteur jusqu&rsquo;&agrave; la date de son remboursement au Pr&ecirc;teur pour l&rsquo;int&eacute;gralit&eacute; de son montant.</div>
                    <div className="block">Les int&eacute;r&ecirc;ts seront payables mensuellement le dernier Jour Ouvr&eacute; de chaque mois calendaire et pour la derni&egrave;re p&eacute;riode &agrave; la date de remboursement de la Tranche au Pr&ecirc;teur pour l&rsquo;int&eacute;gralit&eacute; de son montant.</div>
                    <div className="block">Pour satisfaire aux dispositions de l&rsquo;article L. 313-4 du CMF et des articles L. 314-1 &agrave; L. 314-9 du C. conso., le Pr&ecirc;teur informe l&rsquo;Emprunteur que le taux effectif global annuel (&laquo; <strong>TEG</strong> &raquo;) d&rsquo;une Tranche d&rsquo;un montant correspondant au Montant Maximum de Financement et d&rsquo;une dur&eacute;e courant jusqu&rsquo;&agrave; la Date d&rsquo;Ech&eacute;ance est de 18,25% par an, calcul&eacute; sur la base d&rsquo;une ann&eacute;e de 365 jours. Le TEG int&egrave;gre les int&eacute;r&ecirc;ts, frais, commissions ou r&eacute;mun&eacute;rations de toute nature, directs ou indirects, li&eacute;s &agrave; la mise en place de la Tranche. Ce taux effectif global est donn&eacute; &agrave; titre indicatif, sur la base des hypoth&egrave;ses susvis&eacute;es, et ne saurait lier le Pr&ecirc;teur pour l&rsquo;avenir.</div>
                    <div className="title is-6">Remboursement</div>
                    <div className="block">Chaque Tranche sera remboursable par l&rsquo;Emprunteur au Pr&ecirc;teur &agrave; la date d&rsquo;&eacute;ch&eacute;ance de la facture de l&rsquo;Emprunteur sur un client dont il a demand&eacute; le financement au Pr&ecirc;teur via une Tranche.</div>
                    <div className="block">L&rsquo;Emprunteur s&rsquo;est engag&eacute; aux termes d&rsquo;un acte de cession &agrave; c&eacute;der au Pr&ecirc;teur conform&eacute;ment aux articles 1321 et suivants du Code civil toute cr&eacute;ance pr&eacute;sente ou future correspondant &agrave; toute facture sur tout client qui n&rsquo;est pas r&eacute;gl&eacute;e &agrave; la date d&rsquo;&eacute;ch&eacute;ance indiqu&eacute;e sur la facture pour une raison ne tenant pas &agrave; un litige commercial (notamment mais non exclusivement en raison d&rsquo;une marchandise non conforme ou d&eacute;fectueuse) ou &agrave; l&rsquo;inexistence de la cr&eacute;ance correspondant &agrave; la facture (notamment mais non exclusivement en cas de fraude) (les &laquo; Cr&eacute;ances Acquises &raquo;).&nbsp;</div>
                    <div className="block">L&rsquo;Emprunteur transmettra au Pr&ecirc;teur chaque facture non pay&eacute;e &agrave; sa date d&rsquo;&eacute;ch&eacute;ance.&nbsp;</div>
                    <div className="block">La cession des Cr&eacute;ances Acquises pr&eacute;sentes ou futures par l&rsquo;Emprunteur au Pr&ecirc;teur donnera lieu au paiement d&rsquo;un prix de cession par le Pr&ecirc;teur &agrave; l&rsquo;Emprunteur pour un montant &eacute;gal, pour chaque Cr&eacute;ance Acquise, &agrave; la valeur nominale TTC de la facture correspondant &agrave; la Cr&eacute;ance Acquise.&nbsp;</div>
                    <div className="block">Le paiement du prix de cession de chaque Cr&eacute;ance Acquise par le Pr&ecirc;teur &agrave; l&rsquo;Emprunteur s&rsquo;effectuera par compensation avec le remboursement en principal de la Tranche correspondant &agrave; cette facture d&ucirc; par l&rsquo;Emprunteur au Pr&ecirc;teur de sorte que les obligations respectives du Pr&ecirc;teur au titre du paiement du prix de cession et de l&rsquo;Emprunteur au titre du remboursement de la Tranche seront &eacute;teintes de plein droit.</div>
                    <div className="block">L&rsquo;Emprunteur restera redevable envers le Pr&ecirc;teur des int&eacute;r&ecirc;ts dus au titre de la Tranche correspondant &agrave; cette facture qui aura &eacute;t&eacute; rembours&eacute;e aussi longtemps que le Pr&ecirc;teur n&rsquo;aura pas &eacute;t&eacute; pay&eacute; par le client concern&eacute; de la valeur nominale TTC de la facture correspondant &agrave; la Cr&eacute;ance Acquise.</div>
                    <div className="block">Le montant cumul&eacute; des int&eacute;r&ecirc;ts dus au titre de la Tranche correspondant &agrave; une facture :</div>
                    <div className="block">-&nbsp; n&rsquo;exc&egrave;dera pas 7,5% de la valeur nominale TTC de la facture correspondant &agrave; la Cr&eacute;ance Acquise</div>
                    <div className="block">-&nbsp; ne sera pas inf&eacute;rieur &agrave; 1,5% de la valeur nominale TTC de la facture correspondant &agrave; la Cr&eacute;ance Acquise</div>
                    <div className="block">Les clients, d&eacute;biteurs des Cr&eacute;ances Acquises, devront verser les sommes dues au titre de la facture sur le Compte Bancaire Fiduciaire.</div>
                    <div className="title is-5">ARTICLE 2 &ndash; EFFETS DE L&rsquo;AVENANT</div>
                    <div className="block">Les modifications apport&eacute;es par l&rsquo;Avenant entrent en vigueur &agrave; compter du 15 mars 2023.</div>
                    <div className="block">Les Parties conviennent que toutes les stipulations du Contrat de Pr&ecirc;t participatifs resteront applicables et inchang&eacute;es, sous r&eacute;serve des stipulations du pr&eacute;sent Avenant.</div>
                </div>
                <div className='p-margin-top-4 p-vertical-center'>
                    <ButtonMutationEnabled id='acceptNewPricingButtonId'
                                           kind={KIND_PRIMARY}
                                           size={SIZE_FIXED}
                                           text='Lu et approuvé'
                                           mutation={mutation}
                                           displayFullError={true}
                                           value={null} />

                </div>
            </div>
        </div>
    );
}

const NewPricingModal = (
    {
        customerAxios,
        companyName,
        companySiren,
        companyEmail
    }: {
        customerAxios: Axios;
        companyName: string;
        companySiren: string;
        companyEmail: string;
    }
) => (
    <Modal id='buyerLimitsModal'
           maxWidth={null}
           fullMaxWidth={true}
           close={undefined}>
        <NewPricingModalContent customerAxios={customerAxios}
                                companyEmail={companyEmail}
                                companyName={companyName}
                                companySiren={companySiren} />
    </Modal>
);

export default NewPricingModal;