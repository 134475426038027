import { IFinancingRequestAcceptedStatus, IGDSInvoice } from "../../InvoiceStatus/IInvoiceStatus";

export interface IBuyer {
    id: string; // company id
    name: string;
}


export interface ICBaseInvoicePaymentStatus {
    completePaidDate: string | null;
    partialAmountPaidByBuyer: number;
}

export interface IDSInvoice extends IGDSInvoice<IFinancingRequestAcceptedStatus> {
    creationDateTime: string;
    buyer: IBuyer;
}

export interface IInvoicesGraphQLQueryProviderParams {
    eligibilityO: {
        fetch: boolean;
    } | null;
}

export const buildInvoicesGraphQLQueryProvider = (sellerSelection: string /* can be empty */ /* withSeller: boolean */) =>
    (p: IInvoicesGraphQLQueryProviderParams) =>
        `
            id
            creationDateTime
            number
            dueDate
            issueDate
            amountWoTax
            amountTax
            buyer { id name }
            ${sellerSelection}
            payment {
                completePaidDate
                partialAmountPaidByBuyer
            }
            ${!!p.eligibilityO
                ? p.eligibilityO.fetch
                    ? `status { eligibility { isEligible notEligibleReason } }`
                    : ''
                : `status {
                    financingRequest {
                        dateTime
                        accepted {
                            dateTime
                            settledDateTime
                            amount
                            commingLingAmountToReturn
                        }
                        rejectedDate
                    }
                }`
            }
        `;
