import { formatAmount } from 'factor-lib/utils/amountUtils';
import AmountWithTaxes, {amountWithTaxesKind} from "../utils/AmountWithTaxes";

const BuyerInvoiceInfosPartialPayment = (
    {
        amountWoTax,
        amountTax,
        partialAmountPaidByBuyer,
        commingLingAmountToReturn
    }: {
        amountWoTax: number;
        amountTax: number;
        partialAmountPaidByBuyer: number;
        commingLingAmountToReturn: number;
    }
) =>
    <div>
        <div className='p-horizontal-container p-padding-top-4'>
            <div>
                Montant reçu :
            </div>
            <div>
                <span className='p-very-light p-size-9'>(TTC)</span>&nbsp;
                <span className='p-ok-text p-bold p-size-6'>
                    { formatAmount(partialAmountPaidByBuyer) }
                </span>
            </div>
        </div>
        <div className='p-size-10 p-very-light p-padding-bottom-4'>
            Déjà payé par votre Acheteur :
        </div>
        <div className='p-warn-border p-border p-warn-header p-padding-6'>
            <div className='p-horizontal-container p-margin-bottom-4'>
                <div className='p-horizontal-center p-margin-bottom-5'>
                    <span className='p-icon p-warn-border p-warn-icon p-margin-right-7'>! </span>
                    Montant initial de la facture :
                </div>
                <AmountWithTaxes amountTTC={amountWoTax + amountTax}
                                 amountWoTax={amountWoTax}
                                 kind={amountWithTaxesKind.KIND_LARGE} />
            </div>
            <div className='level'>
                <div className='p-horizontal-center'>
                    <span className='p-icon p-warn-border p-warn-icon p-margin-right-7'>! </span>
                    Reste à payer :
                </div>
                <div>
                    <span className='p-very-light p-size-9'>(TTC)</span>&nbsp;
                    <span className='p-bold p-size-6'>
                        { formatAmount(amountWoTax + amountTax - partialAmountPaidByBuyer) }
                    </span>
                </div>
            </div>
            { commingLingAmountToReturn > 0 &&
                <div className='level'>
                    <div className='p-horizontal-center'>
                        <span className='p-icon p-warn-border p-warn-icon p-margin-right-7'>! </span>
                        À reverser :
                    </div>
                    <div>
                        <span className='p-very-light p-size-9'>(TTC)</span>&nbsp;
                        <span className='p-bold p-size-6'>
                            { formatAmount(commingLingAmountToReturn) }
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>;

export default BuyerInvoiceInfosPartialPayment;
