import IEnvConfig from "./IEnvConfig";
import TelemetryLogger from 'factor-lib/services/logger/TelemetryLogger';
import { TelemetryCustomerInstrumentationKey } from 'factor-lib/envs/demoConfigFactory';
import ILogger from "factor-lib/services/logger/ILogger";
import DevEnv from "./DevEnv";
import GtmLogging from "../services/GTM/GtmLogging";
import loadIntercom from "factor-lib/services/Intercom/loadIntercom";

const DemoEnv: IEnvConfig = {
    // title: 'demo',

    logger: (): ILogger => new TelemetryLogger(TelemetryCustomerInstrumentationKey),

    baseUrl: 'https://client.sandbox.dimpl.io', //https://zealous-beach-020273c03.1.azurestaticapps.net'
    backends: {
        commonCustomer: {
            baseUrl: 'https://factor-demo-customer-api.azurewebsites.net',
            adScope: DevEnv.backends.commonCustomer.adScope
        },
        directSeller: {
            baseUrl: 'https://factor-demo-directseller-api.azurewebsites.net',
            adScope: DevEnv.backends.directSeller.adScope
        },
        marketplace: {
            baseUrl: 'https://factor-demo-marketplaces-api.azurewebsites.net',
            adScope: DevEnv.backends.marketplace.adScope
        }
    },
    azureAD: DevEnv.azureAD,
    debug: false,

    gtm: () => new GtmLogging(),

    intercom: loadIntercom,
    logMsalInfo: false
};

export default DemoEnv;
