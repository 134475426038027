import {MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal} from "@azure/msal-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AccountInfo, AuthError, InteractionStatus, InteractionType, RedirectRequest} from "@azure/msal-browser";
import {configureCustomNavigationClient} from 'factor-lib/auth/CustomNavigationClient';
import GTMAndIntercomWrapper from "./GTMAndIntercomWrapper";
import msalInstance from "./msalInstance";
import {ReactElement, useContext, useEffect} from "react";
import ILogger from "factor-lib/services/logger/ILogger";
import { ensureOneAuthUser } from "factor-lib/serverUtils/buildDefaultAuthAxios";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import NavigateContextContext from "factor-lib/navigationHack/NavigateContextContext";
import {getFactorContext} from "./IFactorContext";

const loginRequest: RedirectRequest = {
    scopes: ['openid', 'profile' /* on pourrait ne meme pas en avoir besoin */]
    // claims: 'email'
    // loginHint: undefined /* TODO ? */
};

const AuthErrorC =  (props: {error: AuthError | null}) => {
    const { error } = props;
    return (
        <div>Une erreur est survenue pendant votre authentification : {error ? `${error.errorMessage}${!!error.subError ? ` - ${error.subError}` : null} - ` : "<<inconnue>>"}</div>
    );
}

const TopBarSetAccountCompleted = (
    {
        setAccount,
        children
    }: {
        setAccount: (account: AccountInfo) => void;
        children: any; // What's the type of this ?
    }
) => {
    const { instance, accounts } = useMsal();
    const logger: ILogger = getFactorContext().logger;
    // // Apparemment il faut ajouter le useAccount hook :
    // // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token
    // // pas dans l'exemple router
    // // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/utils/MsGraphApiCall.js
    // // mais le 1er a l'air plus officiel
    const account: AccountInfo = useAccount(ensureOneAuthUser(instance, logger, accounts/* , clientId */))!;
    //
    useEffect(() => {
        setAccount(account);
    }, [account, setAccount]);

    return children;
}

const TopBarSetAccount = (
    {
        setAccount,
        children
    }: {
        setAccount: (account: AccountInfo) => void;
        children: any; // What's the type of this ?
    }
) => {
    const { inProgress } = useMsal();

    return (
        inProgress === InteractionStatus.None /* Check we're done. 'Non done' status can happen during logout */
            ? <TopBarSetAccountCompleted setAccount={setAccount}>
                { children }
            </TopBarSetAccountCompleted>
            : children
    );
}

const MsalProviderWrapper = (
    {
        setAccount,
        child
    }: {
        setAccount: (account: AccountInfo) => void;
        child : (isMsalAuthenticationComplete: boolean) => ReactElement;
    }
) => {

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/react-router-sample/src/App.js
    const navigate: NavigateFunction = useContext<NavigateFunction | undefined>(NavigateContextContext)!;
    configureCustomNavigationClient(msalInstance, navigate);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect /* PRED-1918 : many issues with popup */}
                                        authenticationRequest={loginRequest}
                                        errorComponent={AuthErrorC}
                                        loadingComponent={() => child(false)} >
                <TopBarSetAccount setAccount={setAccount}>
                    {/* we should not wait : PRED 2241*/}
                    <GTMAndIntercomWrapper />
                    { child(true) }
                </TopBarSetAccount>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default MsalProviderWrapper;