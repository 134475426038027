import extractAxiosErrorMessage from "factor-lib/serverUtils/extractAxiosErrorMessage";
import InvoiceRow from "./InvoiceRow";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {
    requestFinancingUpdateQueriesAsync
} from "../../../utils/requestFinancingUtils";
import {IDSInvoice} from "../FilteredInvoices";
import {getFactorContext} from "../../../../IFactorContext";
import {IFinancingImpact} from "../../../InvoiceFinancingImpact";
import {ISeller} from "../FilteredInvoicesM";

const InvoiceRowWithRequestFinancingWrapperEnabled = <I extends IDSInvoice, R>(
    {
        invoice,
        action,
        financingImpactExtractor,
        sellerExtractorO,
        setCurrentDeclareBuyerPaymentO
    }: {
        invoice: I;
        action: (invoice: I) => Promise<R>;
        financingImpactExtractor: (r: R) => IFinancingImpact;
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        setCurrentDeclareBuyerPaymentO: (() => void) | null; // null -> cannot
    }
) => {
    const factorContext = getFactorContext();
    const queryClient = useQueryClient();

    const requestFinancingMutation: UseMutationResult<R, any, I> =
        useMutation<R, any, I>(
            action,
            ({
                onSuccess: async (response, invoice2) => {
                    const impact = financingImpactExtractor(response);
                    await requestFinancingUpdateQueriesAsync(
                        queryClient,
                        invoice2.id,
                        invoice2.amountWoTax + invoice2.amountTax,
                        invoice2.payment.partialAmountPaidByBuyer,
                        impact.forBuyerO,
                        impact.invoicesWhichBecomeUneligible
                    );
                }
            })
        );

    return (
        <InvoiceRow invoice={invoice}
                    sellerExtractorO={sellerExtractorO}
                    setCurrentDeclareBuyerPaymentO={setCurrentDeclareBuyerPaymentO}
                    canRequestFinanceO={{
                        action: () => requestFinancingMutation.mutate(invoice),
                        isRequestingFinance: requestFinancingMutation.isLoading,
                        financingRequestError: !!requestFinancingMutation.error
                            ? extractAxiosErrorMessage(requestFinancingMutation.error, factorContext.debug ? null : { displayShortDefaultError: true })
                            : null
                    }} />
    );
}

const InvoiceRowWithRequestFinancingWrapper = <I extends IDSInvoice, R>(
    {
        invoice,
        sellerExtractorO,
        setCurrentDeclareBuyerPaymentO,
        displayCannotFinanceModal,
        action,
        financingImpactExtractor
    }: {
        invoice: I;
        sellerExtractorO: ((i: I) => ISeller) | null; // null -> no seller
        setCurrentDeclareBuyerPaymentO: (() => void) | null; // null -> cannot
        displayCannotFinanceModal: (() => void) | null; // null -> can finance
        action: (invoice: I) => Promise<R>;
        financingImpactExtractor: (r: R) => IFinancingImpact;
    }
) =>
    !!displayCannotFinanceModal
        ? <InvoiceRow invoice={invoice}
                      sellerExtractorO={sellerExtractorO}
                      setCurrentDeclareBuyerPaymentO={setCurrentDeclareBuyerPaymentO}
                      canRequestFinanceO={{
                          action: displayCannotFinanceModal,
                          isRequestingFinance: false,
                          financingRequestError: null
                      }} />
        : <InvoiceRowWithRequestFinancingWrapperEnabled<I, R> invoice={invoice}
                                                              action={action}
                                                              financingImpactExtractor={financingImpactExtractor}
                                                              sellerExtractorO={sellerExtractorO}
                                                              setCurrentDeclareBuyerPaymentO={setCurrentDeclareBuyerPaymentO} />;

export default InvoiceRowWithRequestFinancingWrapper;