import InvoiceInfosCard from "./InvoiceInfosCard";
import DeclareDirectRecuperationButton from "./DeclareDirectRecuperationButton";
import InvoiceActivitesSection from "./InvoiceActivitiesSection";
import { IDSInvoiceWithDetails, IInvoiceDetailsQueryResult, invoiceDetailsQueryKey } from "./InvoiceDetailsQuery";
import Upload from "factor-lib/forms/Upload/Upload";
import { PDF_OR_JPEG_OR_PNG_SET } from "factor-lib/forms/Upload/uploadUtils";
import {AxiosRequestConfig} from "axios";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { comparingDate } from "factor-lib/utils/dateUtils";
import { serverDateDeserialization, serverDateSerialization } from "factor-lib/utils/dateUtils";
import {useState} from "react";
import extractAxiosErrorMessage from "factor-lib/serverUtils/extractAxiosErrorMessage";
import { FileUploadTimeoutMilliseconds } from "factor-lib/serverUtils/axiosConfigUtils";
import FinancingRequestButton from "./FinancingRequestButton";
import { canDeclareBuyerPayment } from "../utils/delcareBuyerPaymentUtils";
import {getFactorContext} from "../../IFactorContext";
import IInvoiceActions from "./IInvoiceActions";

const uploadAdditionalFileAsync = async (
    action: (config: AxiosRequestConfig) => Promise<string>,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent) => void,
    setAdditionalFileError: (errorText: string) => void,
    invoiceId: string,
    queryClient: QueryClient,
    displayFullError: boolean
): Promise<void> => {
    await action(
        {
            onUploadProgress,
            timeout: FileUploadTimeoutMilliseconds
        }
    )
    .then(() => {
        queryClient.setQueryData<IInvoiceDetailsQueryResult<IDSInvoiceWithDetails>>(
            invoiceDetailsQueryKey(invoiceId),
            (old: IInvoiceDetailsQueryResult<IDSInvoiceWithDetails> | undefined) => ({
                ...old!,
                invoiceDetails: {
                    ...old!.invoiceDetails,
                    additionalDocuments: [
                        ...old!.invoiceDetails.additionalDocuments,
                        ({
                            creationDateTime: serverDateSerialization(new Date()),
                            blobFile: {
                                fileName: file.name
                            }
                        })
                    ]
                }
            })
        );
    })
    .catch((e) => setAdditionalFileError(extractAxiosErrorMessage(e, displayFullError ? null : { displayShortDefaultError: false })));
}

export const InvoiceDetailsSectionId = 'invoiceDetails'

const InvoiceDetailsPageLoaded = <I extends IDSInvoiceWithDetails, R> (
    {
        className,
        invoiceDetails,
        actions

    }: {
        className?: string;
        invoiceDetails: I;
        actions: IInvoiceActions<R>;
    }
) => {
    const factorContext = getFactorContext();
    const queryClient = useQueryClient();
    const [additionalFileUploadError, setAdditionalFileUploadError] = useState<string | null>(null)

    const { payment, status, additionalDocuments } = invoiceDetails;
    const invoiceAmountTTC = invoiceDetails.amountWoTax + invoiceDetails.amountTax;

    const canDeclareDirectRecuperation = !!actions.canDeclareBuyerPaymentInvoiceO && canDeclareBuyerPayment(
        invoiceDetails.status.financingRequest?.accepted?.settledDateTime ?? payment.completePaidDate,
        invoiceAmountTTC,
        payment.partialAmountPaidByBuyer
    );

    const canRequestFinancing = !!actions.canRequestFinancingO &&
        !invoiceDetails.status.financingRequest &&
        invoiceDetails.status.eligibility!.isEligible === true;

    return (
        <div id={InvoiceDetailsSectionId} className={`p-background p-default-flex-item p-size-62${!!className ? ` ${className}` : ''}`}>
            <div className='columns'>
                <div className='column'>
                    <div className='p-size-4 p-margin-right-7 p-margin-bottom-5'>#{invoiceDetails.number} informations</div>

                    <div className='p-margin-bottom-4'>
                        <InvoiceInfosCard {...invoiceDetails} />
                    </div>

                    { (canDeclareDirectRecuperation || canRequestFinancing) &&
                        <div className='level'>
                            { canDeclareDirectRecuperation &&
                                <DeclareDirectRecuperationButton className='level-item'
                                                                 action={actions.canDeclareBuyerPaymentInvoiceO!.action}
                                                                 invoiceId={invoiceDetails.id}
                                                                 invoiceAmountTTC={invoiceAmountTTC}
                                                                 partialAmountPaidByBuyer={payment.partialAmountPaidByBuyer}
                                                                 invoiceIssueDate={serverDateDeserialization(invoiceDetails.issueDate)}
                                                                 invoiceDueDate={serverDateDeserialization(invoiceDetails.dueDate)}
                                                                 invoiceStatus={status}
                                                                 minInvoiceAmount={actions.canDeclareBuyerPaymentInvoiceO!.minInvoiceAmount} />
                            }
                            { canRequestFinancing &&
                                <FinancingRequestButton<I, R> className='level-item'
                                                              invoice={invoiceDetails}
                                                              cannotFinanceReasonO={actions.canRequestFinancingO!.cannotFinanceReasonO}
                                                              action={actions.canRequestFinancingO!.action}
                                                              financingImpactExtractor={actions.canRequestFinancingO!.financingImpactExtractor}/>
                            }
                        </div>
                    }

                    <div className='p-margin-top-5'>
                        <div className='p-size-4 p-padding-bottom-5'>Historique</div>
                        <div className='p-padding-bottom-4'>
                            <InvoiceActivitesSection creationDateTime={invoiceDetails.creationDateTime}
                                                     invoiceStatus={status}
                                                     paymentStatus={payment}/>

                        </div>
                    </div>

                    { ((additionalDocuments.length > 0 || !!actions.canUploadAdditionalFileO) && (
                        <div>
                            <div className='p-horizontal-divider'/>
                            <div className='p-size-4 p-padding-top-5 p-padding-bottom-5'>
                                Documents associés
                            </div>
                            { additionalDocuments
                                .sort(comparingDate((f) => serverDateDeserialization(f.creationDateTime)))
                                .map((file, index) =>
                                    <div key={`index${index}`}>
                                        <div>Document justificatif n° {index + 1}</div>
                                        <div>
                                            <Upload id={`additionalFile-${index}`}
                                                    acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                                                    externalInitialErrors={null}
                                                    existingFileName={file.blobFile.fileName}
                                                    actionO={null} />
                                        </div>
                                    </div>
                                )
                            }
                            {  (!!actions.canUploadAdditionalFileO && (
                                additionalDocuments.length < 5
                                    ? <div>
                                        <div>Justificatif (bon de livraison, devis..)</div>
                                        <Upload id='uploadAdditionalFile'
                                                acceptedFileTypesSet={PDF_OR_JPEG_OR_PNG_SET}
                                                externalInitialErrors={!!additionalFileUploadError ? [additionalFileUploadError] : null}
                                                existingFileName={null}
                                                actionO={{
                                                    uploadFile: (file: File, onUploadProgress: (progressEvent: ProgressEvent) => void) =>
                                                        uploadAdditionalFileAsync(
                                                            (config: AxiosRequestConfig) => actions.canUploadAdditionalFileO!.action(file, config),
                                                            file,
                                                            onUploadProgress,
                                                            setAdditionalFileUploadError,
                                                            invoiceDetails.id,
                                                            queryClient,
                                                            factorContext.debug
                                                        ),
                                                    deleteFile: null,
                                                    errorHandler: () => {}
                                                }} />
                                    </div>
                                    : <div>Nombre maximum de documents atteint</div>
                            )) || null }
                        </div>
                    )) || null
                    }
                </div>
                <div className='column'>
                    <object data={invoiceDetails.file.url}
                            type={invoiceDetails.file.contentType}
                            className='p-full-container'>
                        Invoice Pdf
                    </object>
                </div>
            </div>
        </div>
    );
}

export default InvoiceDetailsPageLoaded;
