import { KIND_PRIMARY } from 'factor-lib/Buttons/Button';
import {IFinancingRequestAcceptedStatus, IFinancingRequestStatus} from "../../InvoiceStatus/IInvoiceStatus";
import { IDSInvoice } from "./FilteredInvoices";
import {
    FinancingRequestedMessage,
    FinancingRequestRejectedMessage,
    REQUEST_FINANCING_ACTION_TEXT
} from "../../InvoiceStatus/InvoiceStatusMessages";
import {PendingEligibilityMessage} from "../CommonMessages";
import { dateDiffDays, serverDateDeserialization } from 'factor-lib/utils/dateUtils';

export const DUE_DATE_MARGIN_DELAY = 10;

export const nbDaysRemainingToFinance = (dueDate: Date) =>
    dateDiffDays(dueDate, new Date()) - DUE_DATE_MARGIN_DELAY;

interface IMainActionButton {
    preId: string;
    text: string;
    kind: string;
    action: () => void;
    isLoading: boolean;
}

export interface IMainAction {
    // At most one
    button: IMainActionButton | null;
    labels: string[] | null; // Un par ligne
}

export interface IInvoiceDescAndAction {
    action: IMainAction;
    daysComment: string | null;
}

interface IDaysAndCommentNotif {
    days: string | null;
    comment: string | null;
}

const nbDaysNotif = (nbDays: number, notif: string, afterNotif: string | null): IDaysAndCommentNotif | null => {
    if (nbDays > 10) {
        return null;
    }

    // Ne devrait pas arriver, merger avec les conditions de filtre
    if (nbDays < 0) {
        return !!afterNotif ? ({
            days: null,
            comment: afterNotif
        }) : null;
    }

    return ({
        days: `${nbDays} jour${nbDays === 1 ? '' : 's'} ${notif}`,
        comment: null
    });
};

const nbDaysNotifOnly = (nbDays: number, notif: string): string | null => {
    const nbDaysNotifV = nbDaysNotif(nbDays, notif, null);
    return !!nbDaysNotifV ? nbDaysNotifV.days : null;
};

// const NoAction: IMainAction = { button: null, okText: null, okIcon: null };

const indemnNonHC = (/* financingAccepted: IFinancingRequestAcceptedStatus, */ invoice: IDSInvoice): IInvoiceDescAndAction => {
    /* const nbDaysNotifV = nbDaysNotif(
        nbDaysRemainingBeforeHardCollection(serverDateDeserialization(invoice.dueDate)),
        'avant recouvrement',
        'En attente de passage en recouvrement'
    ); */
    const nbDaysAfterDueDate = dateDiffDays(serverDateDeserialization(invoice.dueDate), new Date());
    return ({
        // comment: nbDaysAfterDueDate > 0
        //     ? (financingAccepted.reminderEmailsPaused
        //         ? 'Les relances amiables par email sont suspendues'
        //         : /* !!nbDaysNotifV && nbDaysNotifV.comment
        //             ? nbDaysNotifV.comment
        //             :  */ 'Facture en phase de relances amiables'
        //     ) : null,
        action: {
            button: null,
            // okText: 'Financée',
            labels: nbDaysAfterDueDate > 0 // en attente de confirmation de Paul dans le doc des status
                ? ['Financée', 'relances client en cours'] // en attente de confirmation de Paul dans le doc des status quid des emails paused
                : ['Financée']
        },
        daysComment: null // !!nbDaysNotifV ? nbDaysNotifV.days : null
  });
};

export const computeInvoiceDescAndActionFinancedInvoice = (
    invoice: IDSInvoice,
    f: IFinancingRequestStatus<IFinancingRequestAcceptedStatus>,
): IInvoiceDescAndAction =>
    !!f.accepted
        ? (
            !!f.accepted.settledDateTime
                ? ({
                    // comment: 'Clôturée',
                    action: {
                        button: null,
                        labels: ['Clôturée']
                    },
                    daysComment: null
                }) : /* !!coveredStatus.canceledDate
                    ? ({
                        onDemandPrice,
                        comment: 'La couverture a été annulée',
                        action: NoAction,
                        daysComment: null
                    }) : */ /* !!f.disputeStatus && !f.disputeStatus.closeDate
                        ? ({
                            onDemandPrice,
                            comment: 'Contactez notre support pour plus de détail',
                            action: {
                                button: null,
                                okText: DISPUTE_ONGOING_MESSAGE,
                                okIcon: null
                            },
                            daysComment: null
                        }) : */ /* !!invoice.payment.completePaidDate // isAgreement = false, otherwise settled
                            ? ({
                                // comment: 'Facture payée par votre client',
                                action: {
                                    button: null,
                                    okText: null,
                                    okIcon: 'Payée'
                                },
                                daysComment: null
                            }) : */ /* !!f.claimedStatus
                                ? */ /* !!f.claimedStatus.indemnification
                                    ?*/  /* f.accepted.hardCollectedStartedDate
                                        ? ({
                                            comment: 'Recouvrement en cours',
                                            action: {
                                                button: null,
                                                okText: 'Indemnité versée',
                                                okIcon: null
                                            },
                                            daysComment: null
                                        }) : */ indemnNonHC(/* f.accepted, */ invoice)
                                    /* : isSomethingToIndemnify(invoice)
                                        ? ({
                                            comment: 'Vous allez recevoir votre indemnité sous 2 jours ouvrés',
                                            action: {
                                                button: null,
                                                okText: PAYMENT_ONGOING_MESSAGE,
                                                okIcon: null
                                            },
                                            daysComment: null
                                        }) : ({
                                            comment: null,
                                            action: {
                                                button: null,
                                                okText: 'Relances en cours',
                                                okIcon: null
                                            },
                                            daysComment: null
                                        }) */
                                /* : ({
                                    comment: null,
                                    action: {
                                        button: null,
                                        okText: null,
                                        okIcon: COVERED_MESSAGE
                                    },
                                    daysComment: null
                                }) */
        ) : (
            !!f.rejectedDate ? ({
                action: {
                    button: null,
                    labels: [FinancingRequestRejectedMessage]
                },
                daysComment: null
            }): ({
                // comment: 'En attente de confirmation',
                action: {
                    button: null,
                    labels: [FinancingRequestedMessage]
                },
                daysComment: null
            })
        );

export interface ICanRequestFinance {
    action: () => void;
    isRequestingFinance: boolean;
    financingRequestError: string | null;
}

const computeInvoiceDescAndActionNonFinancedInvoice = (
    invoice: IDSInvoice,
    canRequestFinanceO: ICanRequestFinance | null,
): IInvoiceDescAndAction => {
    const { isEligible, notEligibleReason } = invoice.status.eligibility!;
    return isEligible === null
        ? ({
            // comment: invoice.status.eligibility.notEligibleReason,
            action: {
                button: null,
                labels: [PendingEligibilityMessage]
            },
            daysComment: null
        }) : isEligible
            ? ({
                // comment: null,
                action: {
                    button: !!canRequestFinanceO ? ({
                        preId: 'invoicesDashboardCover',
                        text: canRequestFinanceO.financingRequestError ?? REQUEST_FINANCING_ACTION_TEXT, // TODO : something better. This is still better than nothing
                        kind: KIND_PRIMARY,
                        action: canRequestFinanceO.action,
                        isLoading: canRequestFinanceO.isRequestingFinance
                    }) : null,
                    labels: null
                },
                daysComment: nbDaysNotifOnly(nbDaysRemainingToFinance(serverDateDeserialization(invoice.dueDate)), 'pour financer')
            }) : ({
                // comment: invoice.status.eligibility.notEligibleReason,
                action: {
                    button: null,
                    labels: [notEligibleReason!] /* Ne devrait pas etre nul, on veut toujours afficher une raison */
                },
                daysComment: null
            });
}

export const computeInvoiceDescAndAction = (
    invoice: IDSInvoice,
    canRequestFinanceO: ICanRequestFinance | null
): IInvoiceDescAndAction =>
    !!invoice.status.financingRequest
        ? computeInvoiceDescAndActionFinancedInvoice(
            invoice,
            invoice.status.financingRequest
        ) : computeInvoiceDescAndActionNonFinancedInvoice(
            invoice,
            canRequestFinanceO
        );
