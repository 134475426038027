import IAccessTokens from "../../Auth/IAccessTokens";
import {DashboardBuyersPageName, DashboardInvoicesPageName} from "./dashboardUrlsConsts";
import {ReactComponent as InvoicesIcon} from "./Invoices/Invoices.svg";
import {ReactComponent as BuyersIcon} from "./Buyers/Buyers.svg";
import {ReactComponent as DaysFinanced} from "./Metabase/daysFinanced.svg";
import BuyersTableDesktopSection from "./Buyers/BuyersTableDesktopSection";
import InvoicesTableDesktopSection from "./Invoices/InvoicesTableDesktopSection";
import ICustomerType from "../../ICustomerType";
import MetabaseNbJoursFinanced from "./Metabase/MetabaseNbJoursFinanced";

export interface IDashboardSection {
    routePageParam: string;
    isAccessible: (customerO: ICustomerType | null) => boolean;
    componentProvider: (accessTokenO: IAccessTokens | null) => JSX.Element;
    icon: JSX.Element;
    label: string;
}

export const DASHBOARD_INVOICES_SECTION: IDashboardSection = {
    routePageParam: DashboardInvoicesPageName,
    isAccessible: () => true,
    componentProvider: (accessTokenO) => <InvoicesTableDesktopSection accessTokenO={accessTokenO} />,
    icon: <InvoicesIcon />,
    label: 'Factures'
};

export const dashboardSections: IDashboardSection[] =
    [
        DASHBOARD_INVOICES_SECTION,
        ({
            routePageParam: DashboardBuyersPageName,
            isAccessible: (customerTypeO: ICustomerType | null) => customerTypeO === ICustomerType.DirectSeller,
            componentProvider: (accessTokenO) =>
                <BuyersTableDesktopSection accessTokenO={accessTokenO} />,
            icon: <BuyersIcon />,
            label: 'Clients'
        }),
        ({
            routePageParam: 'days-financed',
            isAccessible: () => true,
            componentProvider: (accessTokenO) =>
                <MetabaseNbJoursFinanced customerAxiosO={accessTokenO?.customerAxios || null} />,
            icon: <DaysFinanced />,
            label: 'Reporting'
        })
    ];